import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';  // For language parameter

// Import the marker images
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix for broken marker icon
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function MiniMap({ latitude, longitude }) {
  const position = [latitude, longitude]; // Use props for coordinates
  const { lang } = useParams();  // Get language parameter from URL
  const [translations, setTranslations] = useState({});

   // Load translations based on the language
   useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  const handleGetDirections = () => {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${position[0]},${position[1]}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div>
      <div style={{ borderRadius: '15px', overflow: 'hidden', height: '200px', width: '100%' }}>
        <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            
          </Marker>
        </MapContainer>
      </div>
      <div style={{ paddingBottom: '20px'}}>
      <Button 
        variant="contained" 
        onClick={handleGetDirections} 
        sx={{ backgroundColor: '#2c3e50', boxShadow: 'none', marginTop: '10px', fontFamily: 'Montserrat, sans-serif', width: '100%' }}
      >
        {translations.getDirections || 'Get Directions'}
      </Button>
      </div>
    </div>
  );
}

export default MiniMap;
