import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import ServiceCategoryChips from '../components/ServiceCategoryChips';
import ServiceForm from '../components/ServiceForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#1a2a3e',
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: '#1a2a3e',
            outline: 'none',
          },
        },
      },
    },
  },
});

const Services = () => {
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuth();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoriesAndServices = async () => {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      if (!user || !isAuthenticated) {
        setLoading(false);
        return;
      }

      try {
        // Fetch categories
        const categoriesResponse = await axios.get(`${baseURL}/api/servicecategories`, { withCredentials: true });
        setCategories(categoriesResponse.data);

        // Fetch services based on the logged-in user's user_id
        const servicesResponse = await axios.get(`${baseURL}/api/serviceSubCategories`, {
          params: { business_user_id: user.user_id },
          withCredentials: true
        });

        const fetchedServices = Array.isArray(servicesResponse.data) ? servicesResponse.data : [];
        setServicesData(fetchedServices);

        const activeCategoryIds = fetchedServices.length > 0
          ? [...new Set(fetchedServices.map(service => service.category_id))]
          : [];
        setSelectedCategories(activeCategoryIds);

        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch data:', err);
        setError('Failed to fetch data.');
        setLoading(false);
      }
    };

    fetchCategoriesAndServices();
  }, [user, isAuthenticated]);

  const handleChipClick = (category) => {
    setSelectedCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((chip) => chip !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handleDeleteChip = (category) => {
    setSelectedCategories((prev) => prev.filter((chip) => chip !== category));
  };

  const handleSaveServices = async () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    setError(null);
    try {
      const requestBody = {
        business_user_id: user.user_id, // Use the correct property for saving
        services: servicesData,
      };

      console.log('Save Request Payload:', JSON.stringify(requestBody, null, 2));

      const response = await fetch(`${baseURL}/api/serviceSubcategories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorMessage = `HTTP error! status: ${response.status}`;
        throw new Error(errorMessage);
      }

      const result = await response.json();
      alert('Services saved successfully');
      setServicesData(result.services);
    } catch (error) {
      console.error('Error saving services:', error);
      setError(`Error saving services: ${error.message}`);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography variant="h4" gutterBottom>
          {t('manageServices')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {selectedCategories.length > 0 ? (
              <ServiceForm
                selectedCategories={selectedCategories}
                businessUserId={user?.user_id || null} // Pass user.user_id
                servicesData={servicesData}
                onSave={(data) => setServicesData(data)}
              />
            ) : (
              <Box
                sx={{
                  boxShadow: 'none',
                  '&:before': { display: 'none' },
                  my: 1,
                }}
              >
                <Typography variant="body1" color="textSecondary">
                  {t('pleaseSelectCategory')}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <ServiceCategoryChips
              selectedChips={selectedCategories}
              handleChipClick={handleChipClick}
              handleDeleteChip={handleDeleteChip}
              categories={categories}
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Services;
