import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, FormControlLabel, Switch, Divider, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

const CheckoutForm = ({ handleSubmit, formValues, handleChange, emailError, setEmailError, isLoggedIn }) => {
  const [translations, setTranslations] = useState({});
  const [userData, setUserData] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [isLoginForm, setIsLoginForm] = useState(false); // Local state for form toggle
  const { lang } = useParams();

  // Load translations based on language
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };
    loadTranslations();
  }, [lang]);

  // Fetch user data if the user is logged in
  useEffect(() => {
    if (!isLoginForm && !isLoggedIn) {
      const fetchUserData = async () => {
        try {
          const response = await fetch('/user-data');
          const data = await response.json();

          if (response.ok) {
            setUserData(data);
          } else {
            console.error("Failed to fetch user data:", data.message);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [isLoginForm, isLoggedIn]);

  // Handle login form submission
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setLoginError(null);

    const response = await fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: formValues.email,
        password: formValues.password,
        userType: 'Client', // Adjust as needed
        fromCheckout: true // Indicate that the login is from the checkout page
      }),
    });

    const data = await response.json();

    if (response.ok && data.userData) {
      setEmailError('');  // Clear the error if login is successful
      setUserData(data.userData);
      setIsLoginForm(false);
      window.location.reload();
      } else {
        setLoginError(data.message || "Login failed. Please try again.");
      }
    
  };

  // Toggle switch handler
  const handleSwitchChange = (event) => {
    setIsLoginForm(event.target.checked);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={isLoginForm}
            onChange={handleSwitchChange}
            name="login"
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#2c3e50',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#2c3e50',
              },
            }}
          />
        }
        label={translations.login || 'Login'}
      />

      {isLoginForm ? (
        <Box component="form" onSubmit={handleLoginSubmit}>
          <Typography variant="h6" gutterBottom>
            {translations.login || 'Login'}
          </Typography>
          <TextField
            name="email"
            value={formValues.email}
            onChange={handleChange}
            label={translations.email || 'Email'}
            variant="standard"
            fullWidth
            margin="dense"
          />
          <TextField
            name="password"
            value={formValues.password}
            onChange={handleChange}
            label={translations.password || 'Password'}
            type="password"
            variant="standard"
            fullWidth
            margin="dense"
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disableRipple
            sx={{
              backgroundColor: '#2c3e50',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#283850',
                boxShadow: 'none',
              },
              '&:focus': {
                backgroundColor: '#283850',
                boxShadow: 'none',
              },
              mt: 5
            }}
          >
            {translations.logIn || 'Log In'}
          </Button>
          {loginError && <Typography color="error">{loginError}</Typography>}
          <Divider sx={{ my: 2 }} />
        </Box>
      ) : (
        <Box component="form" onSubmit={handleSubmit}>
          <Typography variant="h6" gutterBottom>
            {translations.clientBooking || 'Client booking'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight="550" sx={{ fontSize: '17px', fontFamily: 'Montserrat', paddingBottom: '50px' }}>
            {translations.contactInformation || 'My contact information'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" fontWeight="500" sx={{ fontSize: '12px', fontFamily: 'Montserrat' }}>
                {translations.firstName || 'First Name'}*
              </Typography>
              <TextField
                name="firstName"
                value={userData?.name || formValues.firstName}
                onChange={handleChange}
                label={translations.firstName || 'First Name'}
                variant="standard"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" fontWeight="500" sx={{ fontSize: '12px', fontFamily: 'Montserrat' }}>
                {translations.surname || 'Surname'}*
              </Typography>
              <TextField
                name="surname"
                value={userData?.surname || formValues.surname}
                onChange={handleChange}
                label={translations.surname || 'Surname'}
                variant="standard"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" fontWeight="500" sx={{ fontSize: '12px', fontFamily: 'Montserrat' }}>
                {translations.email || 'Email'}*
              </Typography>
              <TextField
                name="email"
                value={userData?.email || formValues.email}
                onChange={handleChange}
                label={translations.email || 'Email'}
                type="email"
                variant="standard"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" fontWeight="500" sx={{ fontSize: '12px', fontFamily: 'Montserrat' }}>
                {translations.phoneNumber || 'Phone Number'}*
              </Typography>
              <TextField
                name="phoneNumber"
                value={userData?.phoneNumber || formValues.phoneNumber}
                onChange={handleChange}
                label={translations.phoneNumber || 'Phone Number'}
                variant="standard"
                fullWidth
                margin="dense"
              />
              
              {emailError && (
  <Typography color="error" sx={{ marginTop: 1 }}>
    {translations.emailExistsError}
  </Typography>
)}
            </Grid>
          </Grid>
         
        </Box>
      )}
    </Box>
  );
};

export default CheckoutForm;
