import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { formatDistanceToNow } from 'date-fns';

function Reviews({ businessusersUserId, onReviewCountChange }) {
  const { lang } = useParams();
  const [reviews, setReviews] = useState([]);
  const [visibleCount, setVisibleCount] = useState(4);
  const [expandedReviewId, setExpandedReviewId] = useState(null);
  const [showViewMore, setShowViewMore] = useState({});
  const [error, setError] = useState(null);
  const [translations, setTranslations] = useState({});
  const [allReviewsVisible, setAllReviewsVisible] = useState(false);

  const reviewRefs = useRef([]);

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        try {
          const defaultResponse = await import(`../visitor-locales/en.json`);
          setTranslations(defaultResponse);
        } catch (defaultError) {
          console.error("Failed to load default translations:", defaultError);
          setTranslations({});
        }
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    async function fetchReviews() {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await fetch(`${apiBaseUrl}/api/reviews/${businessusersUserId}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();

        // Ensure data is an array
      const reviewsArray = Array.isArray(data) ? data : [data];

        const sortedData = reviewsArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setReviews(sortedData); // Set sorted reviews data
        if (onReviewCountChange) {
          onReviewCountChange(data.length); // Notify parent about the review count
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching reviews:', error);
      }
    }

    if (businessusersUserId) {
      fetchReviews();
    }
  }, [businessusersUserId]);

  useLayoutEffect(() => {
    const newShowViewMore = {};

    reviewRefs.current.forEach((ref, index) => {
      if (ref) {
        const contentElement = ref.querySelector('.review-content');
        if (contentElement) {
          const lineHeight = parseFloat(window.getComputedStyle(contentElement).lineHeight);
          const maxHeight = lineHeight * 2;  // Two lines height
          const contentHeight = contentElement.scrollHeight;

          if (contentHeight > maxHeight) {
            newShowViewMore[reviews[index].review_id] = true;
          }
        }
      }
    });

    setShowViewMore(newShowViewMore);
  }, [reviews]);

  const handleViewMore = (id) => {
    setExpandedReviewId(prevId => (prevId === id ? null : id));
  };

  const handleLoadMore = () => {
    setVisibleCount(prevCount => {
      const newCount = prevCount + 4;
      if (newCount >= reviews.length) {
        setAllReviewsVisible(true);
      }
      return newCount;
    });
  };

  const handleViewLess = () => {
    setVisibleCount(4);
    setAllReviewsVisible(false);
  };

  const renderStars = (rating) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {Array.from({ length: rating }, (_, index) => (
          <StarIcon key={index} sx={{ color: '#2c3d50' }} />
        ))}
      </Box>
    );
  };

  const getRatingLabel = (rating) => {
    if (rating > 4) return translations.veryGood || 'Very Good';
    if (rating >= 3) return translations.good || 'Good';
    return translations.bad || 'Bad';
  };

  const totalReviews = reviews.length;
  const averageRating = totalReviews > 0 ? (reviews.reduce((sum, review) => sum + review.rating, 0) / totalReviews).toFixed(1) : null;

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto', fontFamily: 'Montserrat, sans-serif' }}>
      <Box sx={{ textAlign: 'left', mb: 3, color: '#2c3d50' }}>
        <Typography variant="h5" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
          {translations.ratingsAndReviews || 'Ratings and Reviews'}
        </Typography>
        {totalReviews > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant="h6" sx={{ marginBottom: '-15px', flex: 1, color: '#2c3d50', fontSize: 60, fontWeight: 600, fontFamily: 'Montserrat, sans-serif' }}>
                {averageRating}
              </Typography>
              <Box sx={{ flex: 1, textAlign: 'right', fontSize: '15px' }}>
                <Typography variant="h6" sx={{ fontSize: '15px', color: '#506172', mt: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  {totalReviews} {translations.reviews || 'Reviews'}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-start' }}>
              {renderStars(Number(averageRating))}
            </Box>
            <Typography variant="h6" sx={{ paddingTop: '10px', letterSpacing: '1px', textAlign: 'left', color: '#2c3d50', fontSize: '16px', fontWeight: 600, fontFamily: 'Montserrat, sans-serif' }}>
              {getRatingLabel(Number(averageRating))}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ marginTop: 2, color: '#2c3d50', fontSize: '20px', fontWeight: 600, fontFamily: 'Montserrat, sans-serif' }}>
            {translations.no_reviews || 'No reviews yet'}
          </Typography>
        )}
      </Box>
      <Grid container spacing={4}>
        {reviews.slice(0, visibleCount).map((review, index) => (
          <Grid item xs={12} sm={12} md={6} key={review.review_id}>
            <Box
              sx={{
                borderRadius: 5,
                border: '2px solid #f5f5f5',
                backgroundColor: 'white',
                textAlign: 'left',
                padding: '1.5rem',
                position: 'relative',
                overflow: 'hidden',
                minHeight: '170px',
                display: 'flex',
                flexDirection: 'column',
                fontFamily: 'Montserrat, sans-serif', // Ensure font family is applied here
              }}
              ref={el => reviewRefs.current[index] = el}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>
                {review.user_name.split(' ')[0]}
              </Typography>
              <Typography variant="body2" sx={{ color: 'gray', fontFamily: 'Montserrat, sans-serif' }}>
                {formatDistanceToNow(new Date(review.created_at), { addSuffix: true })}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                {renderStars(review.rating)}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  position: 'relative',
                  marginBottom: '40px',
                }}
              >
                <Typography
                  variant="body1"
                  className="review-content"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: expandedReviewId === review.review_id ? 'none' : 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '1.5em',
                    fontFamily: 'Montserrat, sans-serif', // Apply font here as well
                  }}
                >
                  {review.review_text}
                </Typography>
              </Box>
              {showViewMore[review.review_id] && (
                <Button
                  sx={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '10px',
                    color: 'gray',
                    backgroundColor: 'transparent',
                    zIndex: 1,
                    fontFamily: 'Montserrat, sans-serif', // Button font
                  }}
                  onClick={() => handleViewMore(review.review_id)}
                >
                  {expandedReviewId === review.review_id
                    ? translations.viewLess || 'View Less'
                    : translations.viewMore || 'View More'}
                </Button>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      {totalReviews > 0 && visibleCount < totalReviews && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            sx={{ textTransform: 'none', backgroundColor: '#2c3d50',  fontFamily: 'Montserrat, sans-serif',
            '&:hover': {
              backgroundColor: '#2c3d50', // Maintain background color on hover
              boxShadow: 'none', // Remove shadow on hover
            },
            '&:focus': {
              outline: 'none', // Remove outline on focus
              boxShadow: 'none', // Remove shadow on focus
            }
          }}
          disableRipple // Disable ripple effect
            onClick={handleLoadMore}
          >
            {translations.viewMoreReviews || 'View More Reviews'}
          </Button>
        </Box>
      )}
      {allReviewsVisible && visibleCount > 4 && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button
            variant="contained"
            sx={{ textTransform: 'none', backgroundColor: '#2c3d50',  fontFamily: 'Montserrat, sans-serif',
            '&:hover': {
              backgroundColor: '#2c3d50', // Maintain background color on hover
              boxShadow: 'none', // Remove shadow on hover
            },
            '&:focus': {
              outline: 'none', // Remove outline on focus
              boxShadow: 'none', // Remove shadow on focus
            }
          }}
          disableRipple // Disable ripple effect
            onClick={handleViewLess}
          >
            {translations.viewLessReviews || 'View Less Reviews'}
          </Button>
        </Box>
      )}
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default Reviews;
