import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Divider,
} from '@mui/material';
import MiniMap from '../../components/MiniMap';

// Utility function to dynamically import translations based on language
const loadTranslations = async (language) => {
  try {
    const response = await import(`../customer-locales/${language}.json`);
    return response;
  } catch (error) {
    console.error('Failed to load translations:', error);
    const defaultResponse = await import(`../customer-locales/en.json`);
    return defaultResponse;
  }
};

function MyAppointments({ setPastAppointments }) {
  const [user, setUser] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [translations, setTranslations] = useState({});
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { lang = 'en' } = useParams(); // Extract language from URL params

  useEffect(() => {
    const fetchUserAndAppointments = async () => {
      if (isAuthenticated) {
        try {
          const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
          const userResponse = await fetch(`${apiBaseUrl}/api/user`, {
            method: 'GET',
            credentials: 'include',
          });

          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUser(userData);

            const appointmentsResponse = await fetch(`${apiBaseUrl}/api/appointments?client_id=${userData.user_id}`, {
              method: 'GET',
              credentials: 'include',
            });

            if (appointmentsResponse.ok) {
              const appointmentsData = await appointmentsResponse.json();
              setAppointments(appointmentsData);

              // Get current date and time
              const now = new Date();
              const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              const endOfToday = new Date(startOfToday);
              endOfToday.setHours(23, 59, 59, 999);

              // Filter future appointments (including today)
              const futureAppointments = appointmentsData.filter(appointment =>
                new Date(appointment.date) >= startOfToday
              );

              // Filter past appointments
              const pastAppointments = appointmentsData.filter(appointment =>
                new Date(appointment.date) < startOfToday
              );

              setPastAppointments(pastAppointments); // Send pastAppointments to CustomerPortal
              setFutureAppointments(futureAppointments); // Set futureAppointments locally
            } else {
              console.error('Failed to fetch appointments data');
            }
          } else {
            console.error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('An error occurred while fetching data:', error);
        }
      }
    };

    const loadTranslationsData = async () => {
      const translationsData = await loadTranslations(lang);
      setTranslations(translationsData);
    };

    fetchUserAndAppointments();
    loadTranslationsData();
  }, [isAuthenticated, setPastAppointments, lang]);

  if (!isAuthenticated) {
    return <Container>{translations.loginPrompt || 'Please log in to view your appointments.'}</Container>;
  }

  return (
    <Container>
      {user ? (
        <Box>
          <Typography variant="h4" gutterBottom>
            {translations.helloUser ? translations.helloUser.replace('{{userName}}', user.name) : `Hello, ${user.name}`}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {translations.yourAppointments || 'Your Appointments'}
          </Typography>
          {futureAppointments.length > 0 ? (
            <Grid container spacing={4}>
              {futureAppointments.map((appointment) => (
                <Grid item xs={12} sm={6} md={4} key={appointment.id}>
                  <Card>
                    <CardHeader
                      title={appointment.company_name}
                      subheader={`${appointment.service} - ${appointment.service_type}`}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        <strong>{translations.employee || 'Employee'}:</strong> {appointment.employee_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        <strong>{translations.date || 'Date'}:</strong> {new Date(appointment.date).toLocaleDateString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        <strong>{translations.time || 'Time'}:</strong> {appointment.time}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        <strong>{translations.price || 'Price'}:</strong> ${appointment.price}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        <strong>{translations.serviceTime || 'Service Time'}:</strong> {appointment.service_time} mins
                      </Typography>
                      <Box mt={2}>
                        <Typography variant="subtitle1">
                          {translations.companyInformation || 'Company Information'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <strong>{translations.name || 'Name'}:</strong> {appointment.company_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <strong>{translations.address || 'Address'}:</strong> {appointment.company_address}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          <strong>{translations.phone || 'Phone'}:</strong> {appointment.company_phone}
                        </Typography>
                        <Divider />
                        <Box mt={2}>
                          <MiniMap
                            latitude={appointment.company_latitude}
                            longitude={appointment.company_longitude}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>{translations.noUpcomingAppointments || 'No upcoming appointments found.'}</Typography>
          )}
        </Box>
      ) : (
        <Typography>{translations.loading || 'Loading...'}</Typography>
      )}
    </Container>
  );
}

export default MyAppointments;
