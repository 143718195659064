import React, { useState, useEffect } from 'react';
import './BookingCalendarMobile.css'; // For styling
import { eachDayOfInterval, startOfMonth, endOfMonth, isSameDay, startOfWeek, endOfWeek, addMonths } from 'date-fns';
import { fetchEmployees } from '../services/employeeService';
import { useAuth } from '../../context/AuthContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';

// Function to get the days of the week surrounding a given date
const getWeekDays = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 0 }); // Week starts on Sunday
  const end = endOfWeek(date, { weekStartsOn: 0 });
  return eachDayOfInterval({ start, end });
};

function BookingCalendarMobile() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [view, setView] = useState('month'); // 'month' or 'day'
  const [events, setEvents] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedAppointment, setExpandedAppointment] = useState(null);
  const { user, userType } = useAuth();
  const { t } = useTranslation(); // useTranslation hook

  const fetchBlockedDates = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiBaseUrl}/api/blocked-dates`, { credentials: 'include' });
      if (!response.ok) throw new Error('Error fetching blocked dates');
      const data = await response.json();
      setBlockedDates(data);
    } catch (error) {
      console.error('Error fetching blocked dates:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (user === undefined || userType === undefined) {
      console.log('Waiting for user or userType to be defined...');
      return;
    }

    if (!user || !userType) {
      setError('User data or userType is missing');
      setLoading(false);
      return;
    }

    if (userType !== 'Business') {
      setError('User is not authorized');
      setLoading(false);
      return;
    }

    const fetchAppointmentsAndEmployees = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const appointmentsResponse = await fetch(`${apiBaseUrl}/api/business/appointments`, { credentials: 'include' });
        if (!appointmentsResponse.ok) throw new Error('Error fetching appointments');
        const appointments = await appointmentsResponse.json();

        const companyId = user.user_id;
        if (!companyId) throw new Error('Company ID is missing');

        const employeesResponse = await fetchEmployees(companyId);
        const employeeMap = employeesResponse.reduce((map, employee) => {
          map[employee.id] = employee.name;
          return map;
        }, {});

        const filteredAppointments = appointments.filter(appt => appt.status !== 'Canceled');

        const calendarEvents = filteredAppointments.map(appt => {
          const [year, month, day] = appt.date.split('-').map(num => parseInt(num, 10));
          const [hour, minute] = appt.time.split(':').map(num => parseInt(num, 10));
          const startDateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
          const startDate = new Date(startDateStr);
          // Adjust startDate to ensure it's in the local timezone
          const localStartDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
          const serviceTimeMinutes = parseInt(appt.service_time, 10);
          const serviceTimeMs = serviceTimeMinutes * 60000;
          const endDate = new Date(localStartDate.getTime() + serviceTimeMs);

          return {
            id: appt.id,
            title: appt.service,
            start: localStartDate.toISOString(),
            end: endDate.toISOString(),
            resourceId: String(appt.employee_id),
            extendedProps: {
              subtitle: employeeMap[appt.employee_id] || 'Unknown',
              appointment: appt,
            }
          };
        });

        setEvents(calendarEvents);
        setEmployees(employeesResponse.map(emp => ({ id: String(emp.id), title: emp.name })));

        await fetchBlockedDates();
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointmentsAndEmployees();
  }, [user, userType]);

  const handleDateClick = (day) => {
    const newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
    setSelectedDate(newDate);
    setView('day'); // Navigate to the day view on date click
  };

  const getDaysWithEvents = () => {
    const daysWithEvents = new Set();
    events.forEach(event => {
      const eventDate = new Date(event.start).toISOString().split('T')[0];
      daysWithEvents.add(eventDate);
    });
    return daysWithEvents;
  };

  const handleAppointmentClick = (appointmentId) => {
    setExpandedAppointment(prevId => prevId === appointmentId ? null : appointmentId);
  };

  const renderMonthGrid = () => {
    const monthCount = 12; // Display 12 months
    const months = Array.from({ length: monthCount }, (_, i) => addMonths(startOfMonth(new Date()), i));

    return (
      <div className="month-scroll-container">
        {months.map((monthDate, index) => {
          const year = monthDate.getFullYear();
          const month = monthDate.getMonth();
          const days = eachDayOfInterval({ start: startOfMonth(monthDate), end: endOfMonth(monthDate) });
          const firstDayOfMonth = new Date(year, month, 1).getDay();
          const daysWithEvents = getDaysWithEvents();

          // Add empty days at the beginning of the month to align correctly with week days
          const startDays = Array((firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1) + 1).fill(null);

          return (
            <div key={index} className="month-grid">
              <div className="month-header">
                <span className="month-name">{t(`monthNames.${month}`)} {year}</span>
              </div>
              <div className="day-names">
                {t('daysOfWeek', { returnObjects: true }).map((name, index) => (
                  <div key={index} className="day-name">{name}</div>
                ))}
              </div>
              <div className="days">
                {startDays.map((_, index) => (
                  <div key={`empty-${index}`} className="day-cell empty-cell"></div>
                ))}
                {days.map(day => (
                  <div
                    key={day.toISOString()}
                    className={`day-cell ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
                    onClick={() => handleDateClick(day.getDate())}
                  >
                    {day.getDate()}
                    {daysWithEvents.has(day.toISOString().split('T')[0]) && <div className="event-dot"></div>}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderDayView = () => {
    const weekDays = getWeekDays(selectedDate);
    const day = selectedDate.getDate();
    const month = t(`monthNames.${selectedDate.getMonth()}`);
    const year = selectedDate.getFullYear();

    const localSelectedDate = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000);

    const dayEvents = events.filter(event => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      return eventStart.toISOString().split('T')[0] === localSelectedDate.toISOString().split('T')[0];
    });

    const daysWithEvents = getDaysWithEvents();

    return (
      <div className="day-view">
        <button onClick={() => setView('month')} className="nav-button">
          <ArrowBackIosIcon /> {t('backButton')}
        </button>
        <div className="week-grid">
          <div className="day-names">
            {t('daysOfWeek', { returnObjects: true }).map((name, index) => (
              <div key={index} className="day-name">{name}</div>
            ))}
          </div>
          <div className="days">
            {weekDays.map(dayItem => (
              <div
                key={dayItem.toISOString()}
                className={`day-cell ${isSameDay(dayItem, selectedDate) ? 'selected' : ''}`}
                onClick={() => handleDateClick(dayItem.getDate())}
              >
                {dayItem.getDate()}
                {daysWithEvents.has(dayItem.toISOString().split('T')[0]) && <div className="event-dot"></div>}
              </div>
            ))}
          </div>
        </div>
        <h2>{`${localSelectedDate.toLocaleDateString(t('lng'), { weekday: 'long' })} ${day} ${month} ${year}`}</h2>
        <div className="time-grid">
          {dayEvents.length > 0 ? (
            dayEvents.map(event => (
              <div key={event.id} className="time-slot">
                <div onClick={() => handleAppointmentClick(event.id)} className="appointment-header">
                  <strong>{event.title}</strong>
                  <div>{event.extendedProps.subtitle}</div>
                  <div>{new Date(event.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(event.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                </div>
                {expandedAppointment === event.id && (
                  <div className="appointment-details">
                    <div><span>{t('service')}:</span> {event.extendedProps.appointment.service_type}</div>
                    <div><span>{t('price')}:</span> {event.extendedProps.appointment.price}</div>
                    <div><span>{t('phoneNumber')}:</span> {event.extendedProps.appointment.phoneNumber}</div>
                    <div><span>{t('email')}:</span> {event.extendedProps.appointment.email}</div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div>{t('noAppointments')}</div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="calendar-container">
      {view === 'month' ? renderMonthGrid() : renderDayView()}
    </div>
  );
}

export default BookingCalendarMobile;
