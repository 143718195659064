import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Divider, Button, Box, Grid, TextField, CircularProgress, MenuItem } from '@mui/material';
import AddCardIcon from '@mui/icons-material/AddCard';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import EditIcon from '@mui/icons-material/DoNotDisturb';
import DownloadIcon from '@mui/icons-material/Download';
import Invoices from '../components/Invoices'; // Adjust the path according to your file structure
import EuroIcon from '@mui/icons-material/Euro'; // Import currency icons
import PoundIcon from '@mui/icons-material/CurrencyPound';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InformationBar from '../components/InformationBar';
import { useTranslation } from 'react-i18next'; // Ensure correct import

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuth } from '../../context/AuthContext';


// Set price ID based on selected currency
const priceIdMapping = {
  eur: 'price_1Pwm6YDit9Wj3YzQgftVJBbe',
  usd: 'price_1Pwm8oDit9Wj3YzQUecNzkVD',
  gbp: 'price_1PwmAQDit9Wj3YzQ6gg6Uvhg'
};

const taxIdTypes = [
  { label: 'Spain (CIF)', value: 'es_cif' },
  { label: 'United Kingdom (VAT)', value: 'gb_vat' },
  { label: 'United States (EIN)', value: 'us_ein' },
  // Add more tax ID types as needed
];

const countries = [
  { label: 'Spain', value: 'ES' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'USA', value: 'US' },
];

const Billing = () => {
  const { t } = useTranslation(); 
  const { user, isAuthenticated } = useAuth();
  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    companyAddress: '',
    vatId: '',
    Email: '',
    companyCity: '',
    companyCountry: '',
    taxIdType: ''
  });
  const [cardholderName, setCardholderName] = useState('');
  const [priceId, setPriceId] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(''); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [cardLast4, setCardLast4] = useState('');
  const [nextRenewalDate, setNextRenewalDate] = useState('');
  const [planName, setPlanName] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [isInTrial, setIsInTrial] = useState(false); // Track if the user is in a trial period
  const [trialEndDate, setTrialEndDate] = useState('');
  const [cancellationEndDate, setCancellationEndDate] = useState(''); 
  const [isCanceled, setIsCanceled] = useState(false);
  const [prices, setPrices] = useState({});
  

  const stripe = useStripe();
  const elements = useElements();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchPrice = async (priceId) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const response = await axios.get(`${baseURL}/api/stripe/price/${priceId}`);
    return response.data;
  };

  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency);
    setPriceId(priceIdMapping[currency]);
  };
    
  
  

  useEffect(() => {
    const loadPrices = async () => {
      const eurPrice = await fetchPrice('price_1Pwm6YDit9Wj3YzQgftVJBbe');
      const usdPrice = await fetchPrice('price_1Pwm8oDit9Wj3YzQUecNzkVD');
      const gbpPrice = await fetchPrice('price_1PwmAQDit9Wj3YzQ6gg6Uvhg');

      setPrices({
        eur: eurPrice,
        usd: usdPrice,
        gbp: gbpPrice
      });
      setLoading(false);
    };

    loadPrices();
  }, [priceId]);

  useEffect(() => {
    if (!isAuthenticated || !user || !user.user_id) return;

    const fetchBillingInfo = async () => {
      setLoading(true); // Set loading to true when starting fetch
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseURL}/api/billing-info/${user.user_id}`);
        if (response.ok) {
          const billingData = await response.json();
          console.log('Billing data:', billingData);
    
          setCompanyInfo({
            companyName: billingData.company_name || '',
            companyAddress: billingData.company_address || '',
            vatId: billingData.vat_id || '',
            Email: billingData.email || '',
            taxIdType: billingData.tax_ids && billingData.tax_ids.length > 0 ? billingData.tax_ids[0].type : '', // Set the first tax ID type
          companyCity: billingData.company_city || '',  // Set city from response
          companyCountry: billingData.company_country || '',  
          });
          setCardholderName(billingData.card_holder_name || '');
          setIsSubscribed(billingData.is_subscribed || false); // Set subscription status
          setPlanName(billingData.plan_name || ''); // Set the plan name
          setCardLast4(billingData.card_last4 || ''); // Set last 4 digits of the card
          setNextRenewalDate(billingData.next_renewal_date || ''); // Handle the next renewal date if needed
          
          
          // Handle trial information
          setIsInTrial(billingData.is_in_trial || false); // Set trial status
          setTrialEndDate(billingData.trial_end_date || ''); // Set trial end date
          setCancellationEndDate(billingData.cancellation_end_date || ''); 
          setIsCanceled(billingData.is_canceled || false);
        } else {
          console.error('Failed to fetch billing information');
        }
      } catch (error) {
        console.error('Error fetching billing information:', error);
      } finally {
        setLoading(false); // Set loading to false after fetch
      }
    };
    
    fetchBillingInfo();
  }, [isAuthenticated, user]);

  const preferred_locales = companyInfo.companyCountry === 'ES' ? ['es-ES'] : [];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleCardholderNameChange = (e) => setCardholderName(e.target.value);

  const handleSubmitCompanyInfo = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseURL}/api/update-billing-info/${user.user_id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          companyName: companyInfo.companyName,
          companyAddress: companyInfo.companyAddress,
          vatId: companyInfo.vatId,
          taxIdType: companyInfo.taxIdType, 
          Email: companyInfo.Email,
          cardholderName: cardholderName,
          companyCountry: companyInfo.companyCountry, // Add country (use ISO 3166 alpha-2 format, like 'US', 'GB')
          companyCity: companyInfo.companyCity, 
          preferred_locales: preferred_locales 
        })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error?.message || 'Failed to update billing information.');
      }

      console.log('Billing information updated:', data);
      alert('Billing information updated successfully!');
    } catch (error) {
      console.error('Error:', error.message);
      alert(`Error updating billing information: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitSubscription = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
        console.error('Stripe or Elements is not initialized.');
        alert('Unable to process payment. Please try again.');
        setIsSubmitting(false);
        return;
    }

    try {
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            console.error('CardElement is not found');
            return;
        }

        const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: cardholderName,
            },
        });

        if (paymentMethodError) {
            console.error('Error creating payment method:', paymentMethodError.message);
            alert(`Payment error: ${paymentMethodError.message}`);
            return;
        }

        
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const endpoint = isSubscribed 
        
          ? `${baseURL}/api/update-payment-method/${user.user_id}` 
          : `${baseURL}/api/create-subscription`;
        const method = isSubscribed ? 'PUT' : 'POST';
        const body = isSubscribed
            ? JSON.stringify({
                userId: user.user_id,
                paymentMethodId: paymentMethod.id,
                cardholderName: cardholderName
            })
            : JSON.stringify({
                userId: user.user_id,
                paymentMethodId: paymentMethod.id,
                priceId: priceId,
                companyName: companyInfo.companyName,
                companyAddress: companyInfo.companyAddress,
                vatId: companyInfo.vatId,
                taxIdType: companyInfo.taxIdType, 
                contactEmail: companyInfo.Email,
                currency: selectedCurrency,
                cardholderName: cardholderName,
                compnayCountry: companyInfo.companyCountry, // Add country (use ISO 3166 alpha-2 format, like 'US', 'GB')
                companyCity: companyInfo.companyCity, 
                preferred_locales: preferred_locales 
            });

        const response = await fetch(endpoint, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: body
        });

        if (!response.ok) {
            const textResponse = await response.text();
            console.error('Error response:', textResponse);
            alert(`Error: ${textResponse}`);
            return;
        }

        const data = await response.json();
        console.log(isSubscribed ? 'Payment method updated:' : 'Subscription created:', data);
        alert(isSubscribed ? 'Payment method updated successfully!' : 'Subscription successful!');
        if (!isSubscribed) {
            setIsSubscribed(true); // Update subscription status
            setCardLast4(paymentMethod.card.last4); // Update card last 4 digits
        }
    } catch (error) {
        console.error('Error:', error.message);
        alert(`Error: ${error.message}`);
    } finally {
        setIsSubmitting(false);
    }
  };

  const handleCancelSubscription = async () => {
    setIsSubmitting(true); // Set loading state
  
    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseURL}/api/cancel-subscription/${user.user_id}`, {
        method: 'POST', // Assuming cancellation is a POST request
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!response.ok) {
        const textResponse = await response.text();
        throw new Error(textResponse || 'Failed to cancel subscription.');
      }
  
      const data = await response.json();
      console.log('Subscription cancelled:', data);
      alert('Your subscription has been successfully cancelled.');
      setIsSubscribed(false); // Update the subscription status
      setIsCanceled(true); // Update cancellation status
    } catch (error) {
      console.error('Error:', error.message);
      alert(`Error cancelling subscription: ${error.message}`);
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  const handleResumeSubscription = async () => {
    setIsSubmitting(true); // Set loading state
  
    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseURL}/api/resume-subscription/${user.user_id}`, {
        method: 'POST', // Assuming resumption is a POST request
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!response.ok) {
        const textResponse = await response.text();
        throw new Error(textResponse || 'Failed to resume subscription.');
      }
  
      const data = await response.json();
      console.log('Subscription resumed:', data);
      alert('Your subscription has been successfully resumed.');
      setIsCanceled(false); // Update cancellation status
      setIsSubscribed(true); // Update subscription status
    } catch (error) {
      console.error('Error:', error.message);
      alert(`Error resuming subscription: ${error.message}`);
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  if (!isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ padding: 4, minHeight: '100vh', backgroundColor: 'transparent' }}>
        <Typography variant="h4" align="center" sx={{ marginTop: 4, color: '#2c3e50', fontWeight: 600 }}>
          Please log in to manage your billing information.
        </Typography>
      </Container>
    );
  }

  if (loading) { // Show loading spinner while data is being fetched
    return (
      <Container maxWidth="lg" sx={{ padding: 4, minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
    <InformationBar id={user?.user_id} />
    <Container maxWidth="lg" sx={{ padding: 4, minHeight: '100vh', backgroundColor: 'transparent' }}>
      <Typography variant="h3" align="left" sx={{ marginBottom: 4, color: '#2c3e50', fontWeight: 600, fontSize: 30 }}>
      {t('billingInformation')}
      </Typography>

      <Box sx={{ padding: 4, marginBottom: 4, borderRadius: 2, backgroundColor: 'transparent' }}>
        <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500 }}>
        {t('companyBillingInformation')}
        </Typography>
        <Typography variant="body1" sx={{ color: '#7f8c8d', marginBottom: 3 }}>
        {t('provideCompanyInfo')} {/* Please provide your company information to include on your invoice. */}
        </Typography>
        <Box component="form" onSubmit={handleSubmitCompanyInfo} noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label={t('companyName')}
            name="companyName"
            value={companyInfo.companyName}
            onChange={handleInputChange}
            fullWidth
            required
            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
    label={t('city')}
    name="companyCity"
    value={companyInfo.companyCity} 
    onChange={handleInputChange}
    fullWidth
    required
    sx={{ backgroundColor: '#fff', borderRadius: 2 }}
  />
  <TextField
  select
  label={t('country')}
  name="companyCountry"
  value={companyInfo.companyCountry} 
  onChange={handleInputChange}
  fullWidth
  required
  sx={{ backgroundColor: '#fff', borderRadius: 2 }}
>
  {countries.map((country) => (
    <MenuItem key={country.value} value={country.value}>
      {t(`countries.${country.value}`)} 
    </MenuItem>
  ))}
</TextField></Box>
          <TextField
            label={t('companyAddress')}
            name="companyAddress"
            value={companyInfo.companyAddress}
            onChange={handleInputChange}
            fullWidth
            required
            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
          /><Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            select
            fullWidth
            label={t('taxIdType')}
            name="taxIdType"
            value={companyInfo.taxIdType}
            onChange={handleInputChange}
            sx={{ marginBottom: 0, maxWidth: 350 }}
          >
            {taxIdTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(`taxIdTypes.${option.value}`)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={t('tin')}
            name="vatId"
            value={companyInfo.vatId}
            onChange={handleInputChange}
            fullWidth
            required
            sx={{ backgroundColor: '#fff', borderRadius: 2, flexGrow: 3}}
          />
        </Box>
          <TextField
            label={t('email')}
            name="Email"
            value={companyInfo.Email}
            onChange={handleInputChange}
            fullWidth
            required
            type="email"
            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
          />
          {isSubscribed && (
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#2c3e50', '&:hover': { backgroundColor: '#1a242f' }, borderRadius: 2, padding: '12px 24px', fontWeight: 600 }}
            >
             {t('updateInvoice')} {/* Update invoice information */}
            </Button>
          )}
        </Box>
      </Box>

      <Divider sx={{ marginY: 4, borderColor: '#e0e0e0' }} />

      {isSubscribed ? (
        <Grid container spacing={2} sx={{ paddingLeft: 4 }}>
          {/* Left Section: Current Subscription */}
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: 4, marginBottom: 4, borderRadius: 2, backgroundColor: 'transparent' }}>
              <Grid container spacing={1}>
                <Grid item>
                  <StorefrontIcon sx={{ color: '#2c3e50', marginRight: 1 }} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500 }}>
                  {t('currentSubscription')} {/* Current Subscription */}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#7f8c8d' }}>
                  {t('viewSubscription')} {/* View your subscription */}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 2, padding: 2, backgroundColor: '#fff' }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {t('plan')}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    <strong>{isInTrial ? t('freeTrial') : (planName || t('noPlan'))}</strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 2, padding: 2, backgroundColor: '#fff' }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {isCanceled ? t('planEnds') : t('nextRenewal')} {/* Plan ends / Next Renewal */}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    <strong>{isCanceled ? cancellationEndDate : (isInTrial ? trialEndDate : nextRenewalDate)}</strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={isCanceled ? <PlayArrowIcon /> : <EditIcon />}
                    variant="contained"
                    onClick={isCanceled ? handleResumeSubscription : handleCancelSubscription}
                    sx={{ 
                      marginTop: 2, 
                      backgroundColor: isCanceled ? '#28a745' : '#d02828', '&:hover': { backgroundColor: isCanceled ? '#218838' : '#c82333' },
                      borderRadius: 2, padding: '8px 12px', 
                      fontWeight: 600, 
                      fontSize: 12 }}
                    disabled={isSubmitting}
                  >
                    {isCanceled ? t('resume') : t('cancelSubscription')} {/* Resume / Cancel Subscription */}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Right Section: Payment Card */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item>
                <AddCardIcon sx={{ color: '#2c3e50', marginRight: 1 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500 }}>
                {t('paymentCard')} {/* Payment Card */}
                </Typography>
                <Typography variant="body1" sx={{ color: '#7f8c8d' }}>
                {t('addOrChangeBillingMethod')} {/* Add or change your billing method */}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ padding: 4, marginBottom: 4, borderRadius: 2, backgroundColor: '#fff', border: '2px solid #e9f3f9' }}>
              <Box component="form" onSubmit={handleSubmitSubscription} noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                <TextField
                  label={t('cardholderName')}
                  value={cardholderName}
                  onChange={handleCardholderNameChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff', borderRadius: 2, marginBottom: 2 }}
                />
                <Box sx={{ position: 'relative' }}>
                  <CardElement options={{ style: { base: { color: '#2c3e50', fontSize: '16px', '::placeholder': { color: '#bdc3c7' } } } }} />
                  {isSubscribed && cardLast4 && (
                    <Typography
                      sx={{
                        position: 'relative',
                        right: 0,
                        top: 10,
                        color: '#7f8c8d',
                        fontWeight: 500,
                        pointerEvents: 'none', // Background to make sure it's readable
                        padding: '0', // Padding to create some spacing
                      }}
                    >
                     **** **** **** {cardLast4}
                    </Typography>
                  )}
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{
                    marginTop: 2,
                    backgroundColor: isSubmitting ? '#bdc3c7' : '#2c3e50',
                    '&:hover': { backgroundColor: isSubmitting ? '#bdc3c7' : '#1a242f' },
                    borderRadius: 2,
                    padding: '12px 24px',
                    fontWeight: 600,
                  }}
                >
                  {isSubmitting ? t('processing') : (isSubscribed ? t('changeCard') : t('subscribe'))} {/* Processing... / Change Card / Subscribe */}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ paddingLeft: 4 }}>
  {/* Only show this section if the user is not subscribed */}
  
  {/* Left side */}
  <Grid item xs={12} md={6}>
  <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500, marginBottom: 3 }}>
  {t('selectCurrency')} {/* Select your payment currency */}
  </Typography>
  <Grid container spacing={2} sx={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
    {/* EUR Grid */}
    <Grid item xs={12} sm={4} sx={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: selectedCurrency === 'eur' ? '#e0e0e0' : '#fff', // Highlight selected currency
          boxShadow: 1,
          textAlign: 'center',
          width: '100%',
          maxWidth: 'none',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onClick={() => handleCurrencyClick('eur')}
      >
        <EuroIcon sx={{ fontSize: 40, color: '#2c3e50' }} />
        <Typography variant="body1" sx={{ marginTop: 1, color: '#2c3e50', fontWeight: 700 }}>
          {prices.eur ? `${prices.eur.unit_amount / 100} ${prices.eur.currency.toUpperCase()}` : 'Loading...'}
        </Typography>
      </Box>
    </Grid>

    {/* GBP Grid */}
    <Grid item xs={12} sm={4} sx={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: selectedCurrency === 'gbp' ? '#e0e0e0' : '#fff', // Highlight selected currency
          boxShadow: 1,
          textAlign: 'center',
          width: '100%',
          maxWidth: 'none',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onClick={() => handleCurrencyClick('gbp')}
      >
        <PoundIcon sx={{ fontSize: 40, color: '#2c3e50' }} />
        <Typography variant="body1" sx={{ marginTop: 1, color: '#2c3e50', fontWeight: 700 }}>
          {prices.gbp ? `${prices.gbp.unit_amount / 100} ${prices.gbp.currency.toUpperCase()}` : 'Loading...'}
        </Typography>
      </Box>
    </Grid>

    {/* USD Grid */}
    <Grid item xs={12} sm={4} sx={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: selectedCurrency === 'usd' ? '#e0e0e0' : '#fff', // Highlight selected currency
          boxShadow: 1,
          textAlign: 'center',
          width: '100%',
          maxWidth: 'none',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onClick={() => handleCurrencyClick('usd')}
      >
        <AttachMoneyIcon sx={{ fontSize: 40, color: '#2c3e50' }} />
        <Typography variant="body1" sx={{ marginTop: 1, color: '#2c3e50', fontWeight: 700 }}>
          {prices.usd ? `${prices.usd.unit_amount / 100} ${prices.usd.currency.toUpperCase()}` : 'Loading...'}
        </Typography>
      </Box>
    </Grid>
  </Grid>
</Grid>

      {/* Right side */}
      <Grid item xs={12} md={6}>
        <Box sx={{ padding: 4, marginBottom: 4, borderRadius: 2, backgroundColor: '#f9f9f9' }}>
          <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500 }}>
          {t('startFreeTrial')} {/* Start your free trial 14 days */}
          </Typography>
          <Typography variant="body1" sx={{ color: '#7f8c8d', marginBottom: 3 }}>
          {t('cancelAnytime')} {/* Cancel at any time */}
          </Typography>
          <Box component="form" onSubmit={handleSubmitSubscription} noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
            <TextField
              label="Cardholder Name"
              value={cardholderName}
              onChange={handleCardholderNameChange}
              fullWidth
              required
              sx={{ backgroundColor: '#fff', borderRadius: 2, marginBottom: 2 }}
            />
            <CardElement options={{ style: { base: { color: '#2c3e50', fontSize: '16px', '::placeholder': { color: '#bdc3c7' } } } }} />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting || !selectedCurrency} // Disable if no currency is selected
              sx={{
                marginTop: 2,
                backgroundColor: isSubmitting ? '#bdc3c7' : '#2c3e50',
                '&:hover': { backgroundColor: isSubmitting ? '#bdc3c7' : '#1a242f' },
                borderRadius: 2,
                padding: '12px 24px',
                fontWeight: 600,
              }}
            >
              {isSubmitting ? t('processing') : t('startFreeTrialButton')} {/* Processing... / Start Free Trial */}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
      )}

      <Divider sx={{ marginY: 4, borderColor: '#e0e0e0' }} />

      <Box sx={{ padding: 0, borderRadius: 2, backgroundColor: 'transparent' }}>
        <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500 }}>
        {t('invoices')} {/* Invoices */}
        </Typography>
        <Invoices /> {/* Add the Invoices component */}
      </Box>
    </Container>
    </>
  );
};

export default Billing;
