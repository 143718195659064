import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Button, Typography, useMediaQuery } from '@mui/material';
import CheckoutForm from '../components/CheckoutForm';
import CheckoutFormMobile from '../components/CheckoutFormMobile';
import CheckoutSummary from '../components/CheckoutSummary';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';

const Checkout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    surname: '',
    email: '',
    phoneNumber: '',
    username: '',
    password: ''
  });
  const [bookingDetails, setBookingDetails] = useState(null);
  const [userData, setUserData] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [showBookNow, setShowBookNow] = useState(false); // State to control Book Now button visibility
  const navigate = useNavigate();
  const bookNowRef = useRef(null);
  const checkoutFormRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { lang } = useParams();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${apiBaseUrl}/api/user-data`);
        const data = await response.json();

        if (response.ok && data) {
          setUserData(data);
          setIsLoggedIn(true);
          setFormValues((prevValues) => ({
            ...prevValues,
            firstName: data.name || prevValues.firstName,
            surname: data.surname || prevValues.surname,
            email: data.email || prevValues.email,
            phoneNumber: data.phoneNumber || prevValues.phoneNumber
          }));
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoggedIn(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLoginChange = () => {
    setIsLoggedIn((prev) => !prev);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setEmailError('');
    handleBooking();
  };

  const handleBooking = async () => {
    setEmailError('');
    const {
      email, firstName, surname, phoneNumber
    } = formValues;

    const bookingDetails = JSON.parse(sessionStorage.getItem('bookingDetails'));

    if (!email || !firstName || !surname || !phoneNumber || !bookingDetails) {
      console.error("Incomplete booking details.");
      return;
    }

    const {
      time, date, service: service_type, specialist: employee_id,
      price: rawPrice, duration: service_time, serviceCategory: service,
      companyName, businessuserUserId: businessusers_users_id, currency
    } = bookingDetails;

    const numericPrice = parseFloat(rawPrice.replace(/[^0-9.]/g, ''));

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(`${apiBaseUrl}/api/${lang}/register`, {
        email, firstName, surname, phoneNumber, verification_token: '',
        timestamp: new Date().toISOString(), service, date, time,
        price: numericPrice, service_type, service_time, employee_id,
        companyName, businessusers_users_id, currency
      });

      setEmailError('');

      if (response.status === 200 || response.status === 201) {
        const responseData = response.data;

        if (responseData.status === 'success') {
          if (isLoggedIn) {
            navigate(`/${lang}/customerportal`);
          } else {
            setEmailError(responseData.message || 'Login required');
          }
        } else if (responseData.status === 'login') {
          if (responseData.bookingDetails) {
            navigate(`/${lang}/confirmation/${responseData.bookingDetails.token}`);
          } else {
            console.error("Email already exists. Please log in.");
            setEmailError("emailExistsError");
          }
        } else {
          console.error("Unexpected response data:", responseData);
          setEmailError("Unexpected response format.");
        }
      } else {
        console.error("Unexpected response status:", response.status);
        setEmailError("Unexpected response status. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred during booking:", error);
      if (error.response && error.response.data && error.response.data.message) {
        setEmailError(error.response.data.message);
      } else {
        setEmailError("An unexpected error occurred. Please try again.");
      }
    }
  };

  const { bookNow, bookNowConfirmation, generalConditions } = translations;

  const renderBookNowButton = () => (
    <Box sx={{
                display: 'block',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: 1,
                backgroundColor: '#fff',
                p: 2,
                mt: 2
    }}
      
      ref={bookNowRef}
    >
      <Button
        variant="contained"
        onClick={handleBooking}
        sx={{
          backgroundColor: '#2c3e50',
          '&:hover': {
            backgroundColor: '#283850',
            boxShadow: 'none'
          },
          '&:focus': {
            backgroundColor: '#283850'
          },
          width: '100%',
          fontSize: '16px',
          fontFamily: 'Montserrat',
          fontWeight: '600',
          py: 1.5,
          boxShadow: 'none',
          textTransform: 'none'
        }}
      >
        {bookNow || 'Book now'}
      </Button>
      <Typography sx={{ mt: 2, fontSize: '12px', color: '#506172', fontFamily: 'Montserrat', textAlign: 'center' }}>
        {bookNowConfirmation || "By clicking 'Book Now' you confirm that you are purchasing this service from the service provider and accept the Citasonic terms and conditions."}
      </Typography>
      <Typography sx={{ mt: 1, fontSize: '12px', fontFamily: 'Montserrat', textAlign: 'center' }}>
      <Link 
      to={`/${lang}/termsofcondition`}
      style={{ textDecoration: 'none', color: '#1976d2' }}
      >
          {generalConditions || 'General conditions for the use of the booking service'}
        </Link>
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, p: { xs: 0, sm: 3, md: 4 } }}>
      <Grid container spacing={2}>
        {/* Mobile Layout */}
        {isMobile ? (
          <>
            <Grid item xs={12} sx={{ p: 0 }}>
              <Box
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: 1,
                  backgroundColor: '#fff',
                  mb: 2,
                  p: 2
                }}
              >
                <CheckoutSummary />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ p: 0 }}>
              <Box
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: 1,
                  backgroundColor: '#fff',
                  mb: 2,
                  p: 2
                }}
                ref={checkoutFormRef}
              >
                <CheckoutFormMobile
                  isLoggedIn={isLoggedIn}
                  handleLoginChange={handleLoginChange}
                  handleFormChange={handleFormChange}
                  handleFormSubmit={handleFormSubmit}
                  formValues={formValues}
                  setEmailError={setEmailError}
                  emailError={emailError}
                  setShowBookNow={setShowBookNow}
                  setBookingDetails={setBookingDetails}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ p: 0, mb: 3, mt: -2 }}>
              {showBookNow && renderBookNowButton()}
            </Grid>
          </>
        ) : (
          // Desktop Layout
          <>
            <Grid item xs={12} md={8} sx={{ p: 0 }}>
              <Box
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: 1,
                  backgroundColor: '#fff',
                  p: { xs: 2, sm: 3, md: 4 }
                }}
                ref={checkoutFormRef}
              >
                <CheckoutForm
                  isLoggedIn={isLoggedIn}
                  handleLoginChange={handleLoginChange}
                  handleSubmit={handleFormSubmit}
                  handleChange={handleFormChange}
                  formValues={formValues}
                  setEmailError={setEmailError}
                  emailError={emailError}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 0 }}>
              <Box
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: 1,
                  backgroundColor: '#fff',
                  p: { xs: 2, sm: 3 }
                }}
              >
                <CheckoutSummary />
              </Box>
              <Box
              >
                {renderBookNowButton()}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Checkout;
