import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SearchBarFront from '../components/SearchBarFront'; // Import the SearchBar component
import beautypic from '../beautypic.webp'; // Import the header image
import { useParams } from 'react-router-dom';

const Header = styled('div')(() => ({
  backgroundImage: `url(${beautypic})`, // Use the imported image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '60vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
}));

const Home = () => {
  const { lang = 'en' } = useParams(); // Extract the language parameter from URL
  const [translations, setTranslations] = React.useState({});
  const [backgroundImage, setBackgroundImage] = useState(); 

    // Preload the high-resolution image
    useEffect(() => {
      const preloadImage = new Image();
      preloadImage.src = beautypic;
      preloadImage.onload = () => {
        setBackgroundImage(beautypic); // Update the state once the image is loaded
      };
    }, []);

  // Load the appropriate translation file based on the language
  React.useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  const handleSearch = (searchParams) => {
    console.log('Search parameters:', searchParams);
    // Add your search handling logic here
  };

  return (
    <Box>
      <Header>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif' }}>
            {translations.find_appointment || "Find Your Appointment"}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <SearchBarFront onSearch={handleSearch} />
          </Box>
        </Container>
      </Header>
      
    </Box>
  );
};

export default Home;
