import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; // Ensure correct import

// Styled components
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(1),
  fontSize: theme.typography.body1.fontSize,
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
  '.react-datepicker__input-container': {
    display: 'flex',
  },
  '.react-datepicker__input': {
    width: '100%',
    padding: theme.spacing(1),
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.body1.fontSize,
    boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.1)`,
    transition: 'border-color 0.2s ease',
    boxSizing: 'border-box',
  },
  '.react-datepicker__input--open': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  width: '100%',
  boxSizing: 'border-box',
  '& .MuiInputBase-input': {
    fontSize: '16px',
    padding: theme.spacing(1),
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: theme.shape.borderRadius,
  '& .MuiSelect-select': {
    padding: theme.spacing(1),
    fontSize: '16px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const AppointmentModal = ({ open, onClose, appointment, onSave }) => {
  const { t } = useTranslation(); // Use the translation hook
  const [status, setStatus] = useState('');
  const [date, setDate] = useState(null);
  const [time, setTime] = useState('');
  const [price, setPrice] = useState('');
  const [service, setService] = useState('');
  const [serviceType, setServiceType] = useState('');

  useEffect(() => {
    if (appointment) {
      setStatus(appointment.status || '');
      setDate(appointment.date ? new Date(appointment.date) : null);
      setTime(appointment.time || '');
      setPrice(appointment.price || '');
      setService(appointment.service || '');
      setServiceType(appointment.service_type || '');
    }
  }, [appointment]);

  const handleSave = () => {
    if (appointment) {
      onSave({
        id: appointment.id,
        status,
        date: date ? format(date, 'yyyy-MM-dd') : '',
        time,
        price,
        service,
        service_type: serviceType,
      });
    }
  };

  if (!appointment) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'common.white', padding: 2 }}>
      {t('appointmentdetails')}
      </DialogTitle>
      <DialogContent sx={{ padding: 3 }}>
        <Typography variant="h6" gutterBottom>
        {t('appointmentinformation')}
        </Typography>
        <Typography variant="body1"><strong>{t('name')}:</strong> {appointment.name}</Typography>
        <Typography variant="body1"><strong>{t('phone')}:</strong> {appointment.phoneNumber}</Typography>
        <Typography variant="body1"><strong>{t('email')}:</strong> {appointment.email}</Typography>
        
        <StyledTextField
          label={t('service')}
          value={service}
          onChange={(e) => setService(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <StyledTextField
          label={t('servicetype')}
          value={serviceType}
          onChange={(e) => setServiceType(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel>{t('status')}</InputLabel>
          <StyledSelect
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label="Status"
          >
            <MenuItem value="Booked">{t('booked')}</MenuItem>
            <MenuItem value="Canceled">{t('canceled')}</MenuItem>
          </StyledSelect>
        </FormControl>

        <FormControl fullWidth sx={{ my: 2 }}>
          <StyledDatePicker
            selected={date}
            onChange={(newDate) => setDate(newDate)}
            dateFormat="yyyy-MM-dd"
            placeholderText={t('selectdate')}
            isClearable
          />
        </FormControl>

        <StyledTextField
          label={t('time')}
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />

        <StyledTextField
          label={t('price')}
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onClose} sx={{ color: 'text.primary' }}>
        {t('close')}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'primary.main', color: 'common.white', '&:hover': { backgroundColor: 'primary.dark' } }}
          onClick={handleSave}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentModal;
