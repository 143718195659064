import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MyAppointments from './MyAppointments';
import RecentAppointments from './RecentAppointments';
import CustomerSettings from './CustomerSettings';
import { useAuth } from '../../context/AuthContext';

function CustomerPortal() {
  const [activeTab, setActiveTab] = useState('My Appointments');
  const [pastAppointments, setPastAppointments] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [translations, setTranslations] = useState({});
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const loadTranslations = async () => {
      const pathLang = location.pathname.split('/')[1];
      const lang = ['en', 'es'].includes(pathLang) ? pathLang : 'en';

      try {
        const response = await import(`../customer-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../customer-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [location.pathname]);

  const t = (key, options) => {
    let translation = translations[key] || key;
    if (options) {
      Object.keys(options).forEach(optionKey => {
        translation = translation.replace(new RegExp(`\\{\\{${optionKey}}}`, 'g'), options[optionKey]);
      });
    }
    return translation;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated) {
        try {
          const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
          const userResponse = await fetch(`${apiBaseUrl}/api/user`, {
            method: 'GET',
            credentials: 'include',
          });

          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUserId(userData.user_id);
            setUserName(userData.name);
          } else {
            console.error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('An error occurred while fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated]);

  const renderContent = () => {
    switch (activeTab) {
      case 'My Appointments':
        return (
          <MyAppointments 
            setPastAppointments={setPastAppointments}
            translations={translations}
          />
        );
      case 'Recent Appointments':
        return (
          <RecentAppointments
            pastAppointments={pastAppointments}
            userId={userId}
            userName={userName}
            translations={translations}
          />
        );
      case 'settings':
      return <CustomerSettings />; // Display CustomerSettings component
    default:
      return null;
  }
};

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        textColor="secondary"
        indicatorColor="secondary"
        TabIndicatorProps={{
          sx: { backgroundColor: '#2c3d50' },
        }}
        sx={{
          '& .MuiTab-root': {
            minWidth: { xs: 'auto', sm: 120 }, // Allow dynamic width on mobile
            fontSize: { xs: '0.8rem', sm: '1rem' }, // Smaller font on mobile
            padding: { xs: '8px', sm: '12px 16px' }, // Adjust padding for mobile
          },
          '& .MuiTabs-flexContainer': {
            justifyContent: { xs: 'center', sm: 'center' }, // Left align on mobile
          },
        }}
      >
        <Tab
          label={t('myAppointments')}
          value="My Appointments"
          disableRipple
          sx={{
            color: activeTab === 'My Appointments' ? '#2c3d50' : 'gray',
            '&.Mui-selected': { color: '#2c3d50' },
          }}
        />
        <Tab
          label={t('recentAppointments')}
          value="Recent Appointments"
          disableRipple
          sx={{
            color: activeTab === 'Recent Appointments' ? '#2c3d50' : 'gray',
            '&.Mui-selected': { color: '#2c3d50' },
          }}
        />
        <Tab
          label={t('settings')}
          value="settings"
          disableRipple
          sx={{
            color: activeTab === 'settings' ? '#2c3d50' : 'gray',
            '&.Mui-selected': { color: '#2c3d50' },
          }}
        />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {renderContent()}
      </Box>
    </Box>
  );
}

export default CustomerPortal;
