import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Button, Autocomplete } from '@mui/material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

const SearchBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState(location.pathname.split('/')[1] || 'en');

  useEffect(() => {
    // Update the language based on the URL when the location changes
    const newLanguage = location.pathname.split('/')[1] || 'en';
    if (newLanguage !== language) {
      setLanguage(newLanguage);
    }
  }, [location.pathname, language]);

  const [searchParams, setSearchParams] = useState({
    services: '',
    city: '',
    date: null, // Initialize with null for DatePicker
    serviceId: null,
  });

  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceMap, setServiceMap] = useState({});

  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/servicecategories`);
        if (response.headers['content-type'].includes('application/json')) {
          const services = response.data;
          // Ensure services is an array
          if (Array.isArray(services)) {
            const serviceNames = services.map(item =>
              language === 'es' ? item.ES_Name : item.name
            );
    
            setServiceOptions(serviceNames);
    
            setServiceMap(services.reduce((map, item) => {
              const serviceName = language === 'es' ? item.ES_Name : item.name;
              map[serviceName] = item.id;
              return map;
            }, {}));
          } else {
            console.error('Expected an array but received:', services);
            setServiceOptions([]);
            setServiceMap({});
          }
        } else {
          console.error('Expected JSON response but received:', response.headers['content-type']);
        }
      } catch (error) {
        console.error('Error fetching service categories:', error.message);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    };
  
    fetchServiceCategories();
  }, [language]); // Re-run when the language changes

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${language}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Error loading translations:', error);
        setTranslations((await import(`../visitor-locales/en.json`)));
      }
    };

    loadTranslations();
  }, [language]);

  const handleInputChange = (e, newValue, field) => {
    setSearchParams(prevState => ({
      ...prevState,
      [field]: newValue,
      ...(field === 'services' ? { serviceId: serviceMap[newValue] || null } : {}),
    }));
  };

  const handleDateChange = (date) => {
    setSearchParams(prevState => ({
      ...prevState,
      date: date ? format(date, 'yyyy-MM-dd') : '', // Format date to 'yyyy-MM-dd'
    }));
  };

  const handleSearch = useCallback(async () => {
    const { services, city, date } = searchParams;
    const displayCity = city || '';
    const displayService = services || '';

    if (displayService || displayCity || date) {
      try {
        const response = await axios.get('/api/search', {
          params: {
            categoryName: displayService,
            city: displayCity,
            date,
          },
        });

         // Fetch city coordinates
      let cityCoordinates = null;
      if (displayCity) {
        const cityResponse = await axios.get(`https://nominatim.openstreetmap.org/search?city=${displayCity}&format=json&limit=1`);
        if (cityResponse.data && cityResponse.data.length > 0) {
          const { lat, lon } = cityResponse.data[0];
          cityCoordinates = { lat: parseFloat(lat), lng: parseFloat(lon) };

          // Log the latitude and longitude to the console
          console.log(`City: ${displayCity}, Latitude: ${lat}, Longitude: ${lon}`);
        }
      }

        navigate(`/${language}/search-results`, { state: { results: response.data, searchParams: { displayCity, displayService, date, cityCoordinates } } });
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }
  }, [navigate, searchParams, language]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        mt: 4,
        width: '100%',
      }}
    >
      <Autocomplete
        freeSolo
        options={serviceOptions}
        value={searchParams.services}
        onChange={(event, newValue) => handleInputChange(event, newValue, 'services')}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={translations.services || "Services"}
            sx={{
              width: '150px',
              height: '40px',
              backgroundColor: 'white',
              borderRadius: '25px 0 0 25px',
              borderRight: 'none',
              '& .MuiOutlinedInput-root': {
                height: '100%',
                borderRadius: '25px 0 0 25px',
                '& input': {
                  height: '100%',
                  boxSizing: 'border-box',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '& input:-webkit-autofill': {
                  backgroundColor: 'white !important',
                  color: 'black !important',
                  '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
                  boxShadow: '0 0 0px 1000px white inset !important',
                  '-webkit-text-fill-color': 'black !important',
                },
                '& input:-moz-autofill': {
                  backgroundColor: 'white !important',
                  color: 'black !important',
                  boxShadow: '0 0 0px 1000px white inset !important',
                },
              },
            }}
          />
        )}
      />
      <TextField
        variant="outlined"
        placeholder={translations.city || "City"}
        name="city"
        value={searchParams.city}
        onChange={(e) => handleInputChange(e, e.target.value, 'city')}
        sx={{
          width: '150px',
          height: '40px',
          backgroundColor: 'white',
          borderRadius: 0,
          borderLeft: 'none',
          borderRight: 'none',
          '& .MuiOutlinedInput-root': {
            height: '100%',
            borderRadius: 0,
            '& input': {
              height: '100%',
              padding: '10px 14px',
              boxSizing: 'border-box',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '& input:-webkit-autofill': {
              backgroundColor: 'white !important',
              color: 'black !important',
              '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
              boxShadow: '0 0 0px 1000px white inset !important',
              '-webkit-text-fill-color': 'black !important',
            },
            '& input:-moz-autofill': {
              backgroundColor: 'white !important',
              color: 'black !important',
              boxShadow: '0 0 0px 1000px white inset !important',
            },
          },
        }}
      />
      <DatePicker
        selected={searchParams.date ? new Date(searchParams.date) : null}
        onChange={handleDateChange}
        autoComplete="off"
        autoCorrect="off"
        dateFormat="yyyy-MM-dd"
        placeholderText={translations.date || "Date"}
        customInput={
          <TextField
            variant="outlined"
            sx={{
              width: '150px',
              height: '40px',
              backgroundColor: 'white',
              borderRadius: 0,
              '& .MuiOutlinedInput-root': {
                height: '100%',
                borderRadius: 0,
                '& input': {
                  height: '100%',
                  padding: '10px 14px',
                  boxSizing: 'border-box',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '& input:-webkit-autofill': {
                  backgroundColor: 'white !important',
                  color: 'black !important',
                  '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
                  boxShadow: '0 0 0px 1000px white inset !important',
                  '-webkit-text-fill-color': 'black !important',
                },
                '& input:-moz-autofill': {
                  backgroundColor: 'white !important',
                  color: 'black !important',
                  boxShadow: '0 0 0px 1000px white inset !important',
                },
              },
            }}
          />
        }
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        sx={{
          ml: 0,
          width: '100px',
          height: '40px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: '25px',
          borderBottomRightRadius: '25px',
          boxShadow: 'none',
          backgroundColor: '#2c3e50',
          '&:hover': {
            backgroundColor: '#2c3e50',
            boxShadow: 'none',
          },
          '&:focus': {
            backgroundColor: '#2c3e50',
            boxShadow: 'none',
          },
        }}
      >
        {translations.search || "Search"}
      </Button>
    </Box>
  );
};

export default SearchBar;
