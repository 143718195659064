// PrivateRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Adjust the path as needed

const PrivateRoute = ({ element, requiredUserType }) => {
  const { isAuthenticated, userType } = useAuth();
  const location = useLocation();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); 
  const [loading, setLoading] = useState(true); // Track loading state
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { pathname } = location;
  const pathParts = pathname.split('/');

    // List of valid language codes
    const validLangCodes = ['en', 'es']; // Add more language codes as needed

    // Determine if the first part of the URL is a valid language code
    const lang = validLangCodes.includes(pathParts[1]) ? pathParts[1] : null;

    console.log('PrivateRoute Debug:', {
      isAuthenticated, 
      userType, 
      requiredUserType, 
      lang, 
      pathname,
    });

   // Fetch subscription status
   useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        console.log('API Base URL:', apiBaseUrl); // Log API Base URL for debugging
        console.log('Fetching subscription status...');
        const response = await axios.get(`${apiBaseUrl}/api/check-business-user-status`, { withCredentials: true });
        console.log('Subscription status response:', response.data);
        const status = response.data.subscription_status;
        setSubscriptionStatus(status);
      } catch (error) {
        setSubscriptionStatus('inactive');
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && userType === 'Business') {
      fetchSubscriptionStatus();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, userType]);

  useEffect(() => {
    if (!loading && userType === 'Business' && ['no_payment', 'canceled', 'past_due'].includes(subscriptionStatus)) {
      setShouldRedirect(true);
    }
  }, [loading, subscriptionStatus, userType]);

  if (shouldRedirect) {
    // Redirect to billing but still render the current route content
    return (
      <>
        {element}
        <Navigate to="/business/billing" replace />
      </>
    );
  }

  if (loading) {
    return <div>Loading...</div>; // Loading indicator
  }

  if (!isAuthenticated) {
    console.log('User is not authenticated'); // Debug log
    return <Navigate to={lang ? `/${lang}/login` : '/login'} state={{ from: pathname }} replace />;
  }

  if (requiredUserType && userType !== requiredUserType) {
    console.log('User type mismatch'); // Debug log
    return <Navigate to={lang ? `/${lang}/login` : '/login'} state={{ from: pathname }} replace />;
  }


  return element;
};

export default PrivateRoute;
