import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext'; // Assuming you have an Auth context

const ServiceForm = ({ selectedCategories, businessUserId, servicesData = [], onSave }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth(); // Get user info from AuthContext
  const [services, setServices] = useState({});
  const [categories, setCategories] = useState({});
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletedServices, setDeletedServices] = useState({});
  const [currency, setCurrency] = useState('EUR €'); // New state for currency

  const isSpanish = i18n.language === 'es';

  useEffect(() => {
    const fetchData = async () => {
      const baseURL = process.env.REACT_APP_API_BASE_URL;

      if (!user) { // Adjusted to check if user is authenticated
        setError('User is not authenticated');
        setLoading(false);
        return;
      }

      try {
        // Fetch current currency
        const currencyResponse = await axios.get(`${baseURL}/api/currency`, {
          withCredentials: true, // Ensure cookies are sent
        });
        const currencyCode = currencyResponse.data.currency || 'EUR'; // Default to EUR if currency is not set
        setCurrency(`${currencyCode} ${currencyCode === 'EUR' ? '€' : currencyCode === 'USD' ? '$' : '£'}`);

        // Fetch categories
        const categoriesResponse = await axios.get(`${baseURL}/api/servicecategories`, {
          withCredentials: true, // Ensure cookies are sent
        });
        const categoriesData = categoriesResponse.data.reduce((acc, category) => {
          acc[category.id] = {
            en: category.name,
            es: category.ES_Name
          };
          return acc;
        }, {});
        setCategories(categoriesData);
       

        // Initialize services based on fetched data
        const initialServices = {};
        selectedCategories.forEach((categoryId) => {
          initialServices[categoryId] = servicesData
            .filter((service) => service.category_id === categoryId)
            .map((service) => ({
              id: service.id || null,
              name: service.name || '',
              duration: service.duration || '15',
              price: service.price || '0.00',
            })) || [];

          if (initialServices[categoryId].length === 0) {
            // If no services exist for this category, initialize with an empty service
            initialServices[categoryId] = [{ name: '', price: '0.00', duration: '15' }];
          }
        });

        setServices(initialServices);
        setExpandedPanels(selectedCategories.map((_, index) => index === 0)); // Expand the first panel
      } catch (err) {
        console.error('Failed to fetch data:', err);
        setError('Failed to fetch data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCategories.length > 0) {
      fetchData();
    } else {
      setLoading(false);
      setServices({});
      setCategories({});
    }
  }, [selectedCategories, user, i18n.language]); // Removed token dependency

  const handleChange = (index) => (event, isExpanded) => {
    setExpandedPanels((prev) => {
      const newExpandedPanels = [...prev];
      newExpandedPanels[index] = isExpanded;
      return newExpandedPanels;
    });
  };

  const handleServiceChange = (category, index, field, value) => {
    const updatedServices = { ...services };
    updatedServices[category][index][field] = value;
    setServices(updatedServices);
  };

  const addNewService = (category) => {
    const updatedServices = { ...services };
    updatedServices[category] = [...updatedServices[category], { name: '', price: '0.00', duration: '15' }];
    setServices(updatedServices);
  };

  const deleteService = (category, index) => {
    const updatedServices = { ...services };
    const serviceToDelete = updatedServices[category][index];

    // Add the service ID to the list of services to delete
    if (serviceToDelete.id) {
      setDeletedServices((prev) => ({
        ...prev,
        [category]: [...(prev[category] || []), serviceToDelete.id],
      }));
    }

    // Remove the service from the list
    updatedServices[category] = updatedServices[category].filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const handleSaveAll = async () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    setError(null); // Reset error before making the request
    try {
      const servicesToSave = Object.keys(services).flatMap((category) =>
        services[category].map((service) => ({
          ...service,
          category_id: parseInt(category),
          currency: currency.split(' ')[0], // Include currency code in the payload
        }))
      );

      const deletedServiceIds = Object.keys(deletedServices).flatMap(
        (category) => deletedServices[category]
      );

      console.log('Save Request Payload:', JSON.stringify({
        business_user_id: businessUserId,
        services: servicesToSave,
        deleted_services: deletedServiceIds,
      }, null, 2)); // Log payload for debugging

      // Save services
      const saveResponse = await fetch(`${baseURL}/api/serviceSubcategories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          business_user_id: businessUserId,
          services: servicesToSave,
          deleted_services: deletedServiceIds,
        }),
        credentials: 'include', // Ensure cookies are sent
      });

      if (!saveResponse.ok) {
        const errorMessage = `HTTP error! status: ${saveResponse.status}`;
        throw new Error(errorMessage);
      }

      const result = await saveResponse.json();
      console.log('Save Response:', result); // Log response for debugging

      // Delete services
      if (deletedServiceIds.length > 0) {
        const deleteResponse = await fetch(`${baseURL}/api/serviceSubcategories`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ service_ids: deletedServiceIds }),
          credentials: 'include', // Ensure cookies are sent
        });

        if (!deleteResponse.ok) {
          const errorMessage = `HTTP error! status: ${deleteResponse.status}`;
          throw new Error(errorMessage);
        }

        const deleteResult = await deleteResponse.json();
        console.log('Delete Response:', deleteResult); // Log response for debugging
      }

      alert('Services saved successfully');

      // Call onSave callback to update parent component
      onSave(result);

      // Clear local state if necessary
      setDeletedServices({});
    } catch (error) {
      console.error('Error saving services:', error);
      setError(`Error saving services: ${error.message}`); // Include detailed error message
    }
  };

  const handleCurrencyChange = async (event) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const newCurrency = event.target.value;
  
    const confirmation = window.confirm(
      `Are you sure you want to change the currency to ${newCurrency}? This will update the currency for all your prices.`
    );
  
    if (confirmation) {
      try {
        const currencyResponse = await fetch(`${baseURL}/api/currency`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ currency: newCurrency.split(' ')[0] }), // Send only the currency code
          credentials: 'include', // Include credentials (cookies) if necessary
        });
  
        if (!currencyResponse.ok) {
          const errorMessage = `HTTP error! status: ${currencyResponse.status}`;
          throw new Error(errorMessage);
        }
  
        const result = await currencyResponse.json();
        console.log('Currency Update Response:', result); // Log response for debugging
  
        setCurrency(newCurrency);
        alert('Currency updated successfully');
      } catch (error) {
        console.error('Error updating currency:', error);
        setError(`Error updating currency: ${error.message}`); // Include detailed error message
      }
    }
  };
  if (loading) return <div>Loading...</div>;

  // Generate duration options in 15-minute intervals up to 240 minutes (4 hours)
  const durationOptions = Array.from({ length: 16 }, (_, i) => (i + 1) * 15);

  return (
    <div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <Box mb={3} display="flex" alignItems="center"> {/* Adjust layout */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAll}
          sx={{
            boxShadow: 'none',
            '&:hover': { boxShadow: 'none' },
            '&:focus': { boxShadow: 'none' },
            bgcolor: '#2c3e50',
            color: 'white',
            '&:hover': { bgcolor: '#1a2a3e' },
          }}
        >
          {t('save')}
        </Button>
        <FormControl
          variant="outlined"
          sx={{
            marginLeft: '16px', // Space between button and dropdown
            flex: 1,
            maxWidth: '150px', // Limit width of dropdown
          }}
        >
          <InputLabel>{t('currency')}</InputLabel>
          <Select
            value={currency}
            onChange={handleCurrencyChange} // Use the new handler here
            label="Currency"
          >
            <MenuItem value="EUR €">EUR €</MenuItem>
            <MenuItem value="USD $">USD $</MenuItem>
            <MenuItem value="GBP £">GBP £</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {selectedCategories.map((category, index) => (
        <Accordion
          key={category}
          expanded={expandedPanels[index]}
          onChange={handleChange(index)}
          sx={{
            boxShadow: 'none',
            '&:before': { display: 'none' },
            my: 1, // Adds a small gap (margin) between each accordion
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              bgcolor: '#2c3e50',
              color: 'white',
              minHeight: '48px', // Set minimum height
              height: '48px', // Ensure height stays the same
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
              '&.Mui-expanded': {
                minHeight: '48px', // Ensure height stays the same when expanded
              },
              '& .MuiIconButton-root': {
                padding: 0, // Remove padding from the expand icon button
              },
            }}
          >
            <Typography variant="h6">
    {categories[category]?.[i18n.language] || categories[category]?.en || category}
  </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              p: 2, // Add padding to separate the content
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => addNewService(category)}
              startIcon={<AddIcon />}
              sx={{
                marginBottom: '15px',
                bgcolor: '#2c3e50',
                color: 'white',
                height: '30px',
                '&:focus': { bgcolor: '#2c3e50' },
                '&:hover': { bgcolor: '#2c3e50' },
              }}
            >
              {t('addMoreFields')}
            </Button>
            {services[category]?.map((service, idx) => (
              <Box
                key={service.id || idx}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  border: '1px solid #ededed',
                  borderRadius: '5px',
                  padding: '0px',
                  marginBottom: '8px',
                }}
              >
                <TextField
                  label={t('name')}
                  placeholder={t('servicePlaceholder')}
                  value={service.name}
                  onChange={(e) => handleServiceChange(category, idx, 'name', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: { position: 'static', fontSize: '0.875rem' },
                  }}
                  sx={{
                    flex: 2,
                    marginRight: '8px',
                    '& .MuiInputBase-input': {
                      height: '5px',
                      padding: '4px',
                      fontSize: '0.875rem',
                      borderRadius: '15px',
                      border: '1px solid transparent',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
                <FormControl
                  variant="outlined"
                  sx={{
                    flex: 1,
                    marginRight: '8px',
                    '& .MuiInputBase-input': {
                      height: '15px',
                      padding: '4px',
                      fontSize: '0.875rem',
                      borderRadius: '15px',
                      border: '1px solid transparent',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                >
                  <InputLabel>{t('minutes')}</InputLabel>
                  <Select
                    value={service.duration}
                    onChange={(e) => handleServiceChange(category, idx, 'duration', e.target.value)}
                    label="Minutes"
                  >
                    {durationOptions.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label={`${t('price')} (${currency.split(' ')[0]})`} // Dynamic currency symbol
                  placeholder={`e.g., 20${currency.split(' ')[0]}`}
                  value={service.price}
                  onChange={(e) => handleServiceChange(category, idx, 'price', e.target.value)}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  InputLabelProps={{
                    shrink: true,
                    style: { position: 'static', fontSize: '0.875rem' },
                  }}
                  sx={{
                    flex: 1,
                    marginRight: '8px',
                    '& .MuiInputBase-input': {
                      height: '15px',
                      padding: '4px',
                      fontSize: '0.875rem',
                      borderRadius: '20px',
                      border: '1px solid transparent',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
                <IconButton
                  color="error"
                  onClick={() => deleteService(category, idx)}
                  sx={{
                    height: '100%',
                    borderRadius: '0 5px 5px 0',
                    '&:hover': {
                      bgcolor: '#ffdddd',
                    },
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ServiceForm;
