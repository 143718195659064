import React, { useState, useEffect, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Grid, CardMedia, Button, Dialog, DialogTitle, DialogContent, Container, List, ListItem, Divider } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import ServiceList from '../components/ServiceList';
import Reviews from '../components/Reviews';
import shopSalonImage from '../photos/shopsalon.jpg'; // Default image, if needed
import MiniMap from '../components/MiniMap'; 
import 'slick-carousel/slick/slick.css'; // Import the slick carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick carousel theme
import { IconButton } from '@mui/material';
import './places.css';

// Lazy load the Slider component
const Slider = React.lazy(() => import('react-slick'));

const Places = () => {
  const { slug, lang } = useParams(); // Get the ID or slug from the URL
  const navigate = useNavigate(); 
  const [companyData, setCompanyData] = useState(null);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [actualBusinessUserId, setActualBusinessUserId] = useState(null); // Store the actual business ID
  const [translations, setTranslations] = useState({}); // State to hold translations
  const [reviewCount, setReviewCount] = useState(0);

  const imageToPreload = companyData?.photos[0] || shopSalonImage;

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    async function fetchCompanyData(slugOrId) {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await fetch(`${apiBaseUrl}/api/company_info/${slugOrId}`); // Adjust API endpoint as needed
       
    console.log('Response status:', response.status); // Log status
    console.log('Response headers:', response.headers.get('Content-Type')); // Log content-type

    if (!response.ok) throw new Error('Network response was not ok');

    // Read response text and parse it as JSON
    const text = await response.text(); // Get response as text for debugging
    console.log('Response text:', text); // Log the raw response text

    if (!text.trim()) throw new Error('Empty response body');

    const data = JSON.parse(text); // Parse the response text to JSON
    console.log('Parsed company data:', data);
       
       

         // Check subscription status and redirect if necessary
         const allowedStatuses = ['trialing', 'active', 'past_due'];
         if (!allowedStatuses.includes(data.subscription_status)) {
           return navigate('/search-results'); // Redirect to search-results if status is not allowed
         }

         const photoResponse = await fetch(`${apiBaseUrl}/api/photos?userId=${data.businessusers_id}`);
      if (!photoResponse.ok) throw new Error('Network response was not ok');
      const photoData = await photoResponse.json();

        const openingHours = {
          Monday: { open: data.monday_start, startTime: data.monday_start, endTime: data.monday_end },
          Tuesday: { open: data.tuesday_start, startTime: data.tuesday_start, endTime: data.tuesday_end },
          Wednesday: { open: data.wednesday_start, startTime: data.wednesday_start, endTime: data.wednesday_end },
          Thursday: { open: data.thursday_start, startTime: data.thursday_start, endTime: data.thursday_end },
          Friday: { open: data.friday_start, startTime: data.friday_start, endTime: data.friday_end },
          Saturday: { open: data.saturday_start, startTime: data.saturday_start, endTime: data.saturday_end },
          Sunday: { open: data.sunday_start, startTime: data.sunday_start, endTime: data.sunday_end },
        };

        setCompanyData({
          name: data.name,
          description: data.description,
          address: data.address,
          city: data.city, 
          phone: data.phone,
          instagram: data.instagram,
          latitude: data.latitude,
          longitude: data.longitude,
          openingHours,
          photos: photoData.photos // set fetched photos here
        });

        setActualBusinessUserId(data.businessusers_id); // Store the resolved business ID
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    }

    fetchCompanyData(slug);
  }, [slug]);

    // Handle review count change
    const handleReviewCountChange = (count) => {
      setReviewCount(count);
    };

      // Preload image
      useEffect(() => {
        const preloadImage = () => {
          if (imageToPreload) {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.href = imageToPreload;
            link.as = 'image';
            document.head.appendChild(link);
            return () => {
              document.head.removeChild(link);
            };
          }
        };
      
        preloadImage();
      }, [imageToPreload]); 

    if (!companyData || !translations) return <p>{translations.loading || 'Loading...'}</p>;


  const { name, description, address, city, phone, instagram, openingHours, photos, latitude, longitude } = companyData;
  const { coverPhoto, reviews, viewMore, viewLess, openingHours: openingHoursLabel, viewAllPhotos, closed, allPhotos, photo } = translations;



   const sliderSettings = {
    dots: true,             // Show dots below the carousel
    infinite: true,         // Enable infinite looping
    speed: 500,             // Transition speed in ms
    slidesToShow: 1,        // Show one image at a time
    slidesToScroll: 1,      // Scroll one image at a time
    adaptiveHeight: true,    // Automatically adjust height to fit the image
    
  };


  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const truncatedDescription = expanded ? description : `${description.slice(0, 220)}${description.length > 220 ? '...' : ''}`;

  const formatTime = (time) => {
    return time.slice(0, 5); // Extract the HH:MM part of the time string
  };

  return (
    <Container maxWidth="lg">
      {/* Cover Photo for Mobile */}
      <div style={{ display: 'none', position: 'relative' }} className="cover-photo" onClick={() => setOpen(true)}>
        <CardMedia
          component="img"
          sx={{ height: 300, width: '100%', objectFit: 'cover' }}
          image={photos[0] || shopSalonImage} // Fallback to default image if no photos 
          alt="Cover photo"
        />
      </div>

      <Grid container spacing={4} sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif' }}>
            {name}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif' }}>
            {address}, {city}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif' }}>
          {translations.reviews || 'Reviews:'} {reviewCount}
          </Typography>
          <hr style={{ margin: '20px 0' }} />
          <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif' }}>
            {truncatedDescription}
            {(description.length > 220) && (
              <Button onClick={toggleExpanded} disableRipple  sx={{ color: '#2c3e50', fontFamily: 'Montserrat, sans-serif' }}>
                 {expanded ? translations.viewLess : translations.viewMore}
              </Button>
            )}
          </Typography>
          <hr style={{ margin: '20px 0' }} />
          <ServiceList businessIdentifier={actualBusinessUserId} companyName={name} />
          <Reviews businessusersUserId={actualBusinessUserId} onReviewCountChange={handleReviewCountChange}  />
        </Grid>
        
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <div style={{ position: 'relative', overflow: 'hidden', width: 300 }}>
            <div sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia
                component="img"
                sx={{ height: 300, width: '100%', objectFit: 'cover', borderRadius: 10, 
                display: { xs: 'none', sm: 'block' } 
               }}
                image={photos[0]}
                alt={translations.officePhoto || 'Office photo'}
              />
              <div style={{ position: 'absolute', bottom: 16, left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                <Button
                  variant="contained"
                  onClick={() => setOpen(true)}
                  disableRipple
                  sx={{
                    fontSize: '11px',
                    backgroundColor: '#f8f9fae3',
                    color: 'black',
                    boxShadow: 'none',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  {viewAllPhotos}
                </Button>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 16, width: '100%' }}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif' }}>
            {openingHoursLabel}
            </Typography>
            <List>
              {Object.keys(openingHours).map((day) => (
                <ListItem key={day} sx={{ padding: '8px 0', fontFamily: 'Montserrat, sans-serif' }}>
                  <Typography variant="body2" component="span" sx={{ width: '100px', display: 'inline-block' }}>
                  {translations[day] || day}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {openingHours[day].open ? `${formatTime(openingHours[day].startTime)} - ${formatTime(openingHours[day].endTime)}` : closed }
                  </Typography>
                </ListItem>
              ))}
            </List>

            <Divider sx={{ my: 1 }} />
            <List>
              <ListItem sx={{ padding: '8px 0', fontFamily: 'Montserrat, sans-serif' }}>
                <PlaceIcon sx={{ marginRight: 1 }} />
                <Typography variant="body2" component="span">
                  {address}, {city}
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: '8px 0', fontFamily: 'Montserrat, sans-serif' }}>
                <PhoneIcon sx={{ marginRight: 1 }} />
                <Typography variant="body2" component="span">
                  {phone}
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: '8px 0', fontFamily: 'Montserrat, sans-serif' }}>
                <InstagramIcon sx={{ marginRight: 1 }} />
                <Typography variant="body2" component="span">
                  {instagram}
                </Typography>
              </ListItem>
            </List>
            <MiniMap latitude={latitude} longitude={longitude} />
          </div>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md"
      PaperProps={{
        sx: {
          height: '80vh', // Set the height to 80% of the viewport height
          maxHeight: '80vh', // Ensure it doesn't exceed this height
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        }
      }}>
        <DialogTitle sx={{ fontFamily: 'Montserrat, sans-serif' }}>{allPhotos}</DialogTitle>
        <DialogContent
        sx={{
          p: 0, // Remove padding
          overflow: 'hidden', // Hide overflow to prevent scrolling
          width: '100%', // Ensure the content fits within the dialog
        }}
      >
       <Suspense fallback={<div>Loading photos...</div>}>
        <Slider {...sliderSettings}>
            {photos.map((photo, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <CardMedia
                  component="img"
                  sx={{ height: 500, width: '100%', objectFit: 'contain' }}
                  image={photo}
                  alt={`Photo ${index + 1}`}
                />
              </Grid>
            ))}
          </Slider>
          </Suspense>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Places;
