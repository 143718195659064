import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

function Cookies() {
  const { lang } = useParams(); // Extract lang from the URL
  const [translations, setTranslations] = useState({});

  // Load translations based on lang param
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Failed to load translations:', error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  return (
    <Box
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        padding: '20px',
        maxWidth: '800px',
        margin: 'auto',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.cookiePolicyTitle || 'Cookie Policy'}
      </Typography>

      {/* Statement about accepting cookies by browsing the site */}
      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.acceptCookiesMessage || 'By browsing this website, you consent to the use of necessary cookies for its proper functioning.'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.cookiePolicyDescription || 'This website uses cookies to improve your experience, provide personalized content, and analyze our traffic.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.whatAreCookies || 'What are Cookies?'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.whatAreCookiesDescription || 'Cookies are small text files that are stored on your device when you visit a website. They help us understand how our website is being used and allow us to improve your experience.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.typesOfCookies || 'Types of Cookies We Use'}
      </Typography>

      <ul sx={{ mb: 3, pl: 3 }}>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>{translations.necessaryCookies || 'Necessary Cookies'}:</strong>{' '}
            {translations.necessaryCookiesDescription || 'These are essential for the website to function properly, such as logging in and filling out forms.'}
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>{translations.performanceCookies || 'Performance Cookies'}:</strong>{' '}
            {translations.performanceCookiesDescription || 'These cookies help us analyze how visitors use the website to improve performance and user experience.'}
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>{translations.functionalCookies || 'Functional Cookies'}:</strong>{' '}
            {translations.functionalCookiesDescription || 'These cookies remember your preferences and provide enhanced, personalized features.'}
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>{translations.advertisingCookies || 'Advertising Cookies'}:</strong>{' '}
            {translations.advertisingCookiesDescription || 'These cookies are used to deliver relevant advertisements and track the effectiveness of ad campaigns.'}
          </Typography>
        </li>
      </ul>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.managingCookies || 'Managing Cookies'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.managingCookiesDescription || 'As these cookies are essential for the website to function, you cannot decline them. You can control other cookie settings through your browser settings, but this may affect your experience on our site.'}
      </Typography>
    </Box>
  );
}

export default Cookies;
