import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; 
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  MenuItem,
  Divider,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Select,
  FormControl,
  Menu
} from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddAppointment from './AddAppointment';
import esFlag from './../icons/es-flag.png';
import ukFlag from './../icons/uk-flag.png';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next'; // Ensure correct import

const drawerWidth = 240;

const menuItems = [
  { text: 'dashboard', icon: <SpaceDashboardIcon />, path: '/business/dashboard' },
  { text: 'appointments', icon: <SettingsIcon />, path: '/business/appointments' },
  { text: 'calendar', icon: <CalendarTodayIcon />, path: '/business/calendar' },
];

const secondaryMenuItems = [
  { text: 'profile', icon: <ReceiptIcon />, path: '/business/profile' },
  { text: 'services', icon: <SpaceDashboardIcon />, path: '/business/services' },
  { text: 'banking', icon: <ReceiptIcon />, path: '/business/billing' },
];

const settings = ['profile', 'account', 'logout'];

const Sidebar = ({ onDrawerToggle, open }) => {
  const { t, i18n } = useTranslation(); // Ensure useTranslation hook is used correctly
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [language, setLanguage] = useState('en'); // Default language selection
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    // Get language from cookies on component mount
    const storedLanguage = Cookies.get('language') || 'en';
    setLanguage(storedLanguage);
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const handleDrawerToggle = () => {
    onDrawerToggle(!open);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Ensure changeLanguage is called correctly
    Cookies.set('language', newLanguage, { expires: 60 }); // Set cookie (days exipres) 
  };

  const activeStyles = {
    backgroundColor: '#2c3e50',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '10px',
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderBottom: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1
        }}
      >
        {isMobile && (
          <IconButton onClick={handleDrawerToggle}
          sx={{ position: 'absolute', left: 16, zIndex: 1000 }} // Position the hamburger menu on top-left
          >
            <MenuIcon />
          </IconButton>
        )}
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{ mt: 0 }}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={setting === 'logout' ? handleLogout : handleCloseUserMenu}
                  disableRipple
                >
                  {t(setting)}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#ebeff4',
            boxShadow: '5px 0 15px -5px rgba(0,0,0,0.1)',
            fontFamily: 'Montserrat, sans-serif',
          },
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={isMobile ? open : true}
        onClose={handleDrawerToggle}
      >
        <Toolbar />
        <List sx={{ width: '100%' }}>
          {menuItems.map((item) => (
            <ListItem
              key={item.text}
              button
              component={NavLink}
              to={item.path}
              disableRipple
              sx={{
                color: '#2c3e50',
                margin: '8px auto',
                width: '200px',
                borderRadius: '10px',
                fontFamily: 'Montserrat, sans-serif',
                '& .MuiButton-startIcon': {
                  marginRight: '8px',
                },
                '& .MuiListItemIcon-root': {
                  color: '#2c3e50',
                },
                '&:hover': {
                  backgroundColor: '#2c3e50',
                  color: 'white',
                  borderRadius: '10px',
                  '& .MuiListItemIcon-root': {
                    color: 'white',
                  },
                },
                '&.active': activeStyles,
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.text)} />
            </ListItem>
          ))}
        </List>
        <Divider sx={{ my: 0, backgroundColor: '#dfe9f1', borderColor: '#dfe9f1' }} />
        <List sx={{ width: '100%' }}>
          {secondaryMenuItems.map((item) => (
            <ListItem
              key={item.text}
              button
              component={NavLink}
              to={item.path}
              disableRipple
              sx={{
                color: '#2c3e50',
                margin: '8px auto',
                width: '200px',
                borderRadius: '10px',
                fontFamily: 'Montserrat, sans-serif',
                '& .MuiButton-startIcon': {
                  marginRight: '8px',
                },
                '& .MuiListItemIcon-root': {
                  color: '#2c3e50',
                },
                '&:hover': {
                  backgroundColor: '#2c3e50',
                  color: 'white',
                  borderRadius: '10px',
                  '& .MuiListItemIcon-root': {
                    color: 'white',
                  },
                },
                '&.active': activeStyles,
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.text)} />
            </ListItem>
          ))}
        </List>
        <Box>
          <AddAppointment />
        </Box>
        
        <Box sx={{ flexGrow: 1 }} />
        
        <Box sx={{ p: 2, px: 3, borderBottom: '1px solid #dfe9f1'}}>
          <Box sx={{ border: '1px solid #dfe9f1', borderRadius: '25px', maxHeight:  '40px', display: 'flex', backgroundColor: '#fff6'}}>
            <FormControl fullWidth sx={{ justifyContent: 'center', }}>
              <Select
                value={language}
                onChange={handleLanguageChange}
                label={t('language')}
                sx={{ 
                  
                  fontFamily: 'Montserrat, sans-serif',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#dfe9f1', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe9f1', // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe9f1', // Border color when focused
                    },
                  },
                  '& .MuiSelect-icon': {
                    display: 'flex' // Optionally hide the dropdown icon
                  },
                  '& .MuiOutlinedInput-input': {
                    display: 'flex', // Make the input area a flex container
                    alignItems: 'center', // Center items vertically
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none' // Ensure no border is shown
                  }
                }}
              >
                <MenuItem value="en">
                <img src={ukFlag} alt="English" style={{ width: 20, height: 20, marginRight: 8 }} />
                  {t('English')}</MenuItem>
                <MenuItem value="es">
                <img src={esFlag} alt="Spanish" style={{ width: 20, height: 20, marginRight: 8 }} />
                  {t('Spanish')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        
        <List sx={{ width: '100%' }}>
          <ListItem
            button
            onClick={handleLogout}
            disableRipple
            sx={{
              color: '#2c3e50',
              textDecoration: 'none',
              position: 'relative',
              padding: '10px',
              borderRadius: '10px',
              width: '200px',
              margin: '8px auto',
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Montserrat, sans-serif',
              '& .MuiListItemIcon-root': {
                color: '#2c3e50',
              },
              '&:hover': {
                backgroundColor: '#2c3e50',
                color: 'white',
                borderRadius: '10px',
                '& .MuiListItemIcon-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              },
              '&.active': activeStyles,
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: '#2c3e50' }} />
            </ListItemIcon>
            <ListItemText primary={t('logout')} />
          </ListItem>
        </List>
        
      </Drawer>
    </>
  );
};

export default Sidebar;
