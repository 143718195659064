import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, Avatar, Divider } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const EmployeeScore = ({ employees = [], period, startDate, endDate, businessusersUserId }) => {
  const { t } = useTranslation();
  const [updatedEmployees, setUpdatedEmployees] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Ensure employees is an array
    const validEmployees = Array.isArray(employees) ? employees : [employees];

    const fetchAppointmentCounts = async () => {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      try {
        // Determine the query params based on the period or custom date range
        const params = period === 'Custom' ? { startDate, endDate } : { period };
        const response = await axios.get(`${baseURL}/api/employee-appointment-count/${businessusersUserId}`, { params });

        console.log('Response data:', response.data);

        // Ensure response is an array
        const appointmentCountsArray = Array.isArray(response.data) ? response.data : [response.data];

        // Build an object mapping employee IDs to appointment counts
        const appointmentCounts = appointmentCountsArray.reduce((acc, { id, appointmentCount }) => {
          acc[id] = appointmentCount;
          return acc;
        }, {});

        console.log('Appointment counts:', appointmentCounts);

        // Merge appointment counts into employees
        const employeesWithCounts = validEmployees.map(employee => ({
          ...employee,
          appointmentCount: appointmentCounts[employee.id] || 0
        }));

        console.log('Employees with counts:', employeesWithCounts);

        setUpdatedEmployees(employeesWithCounts);
        setError(null);
      } catch (error) {
        console.error('Error fetching appointment counts:', error);
        setError('Failed to fetch appointment counts. Details: ' + error.message);
      }
    };

    // Fetch the appointment counts if the business user ID is available
    if (businessusersUserId) {
      fetchAppointmentCounts();
    }
  }, [businessusersUserId, period, startDate, endDate, employees]);

  console.log('Employees prop:', employees); // Verify employees prop

  return (
    <Paper
      elevation={3}
      style={{
        borderRadius: '15px',
        padding: '10px',
        marginBottom: '10px',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 4px 0px rgb(0 0 0 / 0%), 0px 1px 4px 1px rgba(0, 0, 0, 0.12)',
        fontFamily: 'Montserrat, sans-serif',
        height: '385px',  // Fixed height
        overflowY: 'auto', // Scrollable content
      }}
    >
      <Typography 
        variant="h6" 
        align="left" 
        gutterBottom 
        sx={{ 
          fontSize: '13px', 
          fontWeight: 600, 
          marginBottom: '20px', 
          fontFamily: 'Montserrat, sans-serif',
          color: '#2c3e50'
        }}
      >
        {t('employeeappointments')}
      </Typography>
      {Array.isArray(updatedEmployees) && updatedEmployees.length > 0 ? (
      updatedEmployees.map((employee, index) => (
        <React.Fragment key={index}>
          <Grid container alignItems="center" spacing={2} style={{ marginBottom: '8px' }}>
            <Grid item>
              <Avatar src={employee.avatar} sx={{ width: 25, height: 25 }} />
            </Grid>
            <Grid item xs>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontSize: '14px', 
                  fontFamily: 'Montserrat, sans-serif' 
                }}
              >
                {employee.name}
              </Typography>
            </Grid>
            <Grid item xs style={{ textAlign: 'right' }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontFamily: 'Montserrat, sans-serif' 
                }}
              >
                {employee.appointmentCount}
              </Typography>
            </Grid>
          </Grid>
          {index < updatedEmployees.length - 1 && <Divider style={{ marginBottom: '8px' }} />}
        </React.Fragment>
      ))
      ) : (
        <Typography variant="body1" align="center">{t('noEmployees')}</Typography>
      )}
    </Paper>
  );
};

export default EmployeeScore;