import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Chip, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ReviewsIcon from '@mui/icons-material/Reviews';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // Assuming you have an Auth context
import { useTranslation } from 'react-i18next'; // Ensure correct import
import i18next from 'i18next';

const currencySymbols = {
  EUR: '€',
  USD: '$',
  GBP: '£',
};

const currencyIcons = {
  EUR: <EuroIcon />,
  USD: <AttachMoneyIcon />,
  GBP: <CurrencyPoundIcon />,
};

const MetricsGrid = ({ period, businessusersUserId, startDate, endDate }) => {
  const { t } = useTranslation(); // Use the translation hook
  const { token } = useAuth(); // Get token from AuthContext
  const [data, setData] = useState({
    current: [
      { title: t('appointments'), value: 0, icon: <PersonIcon /> },
      { title: t('revenue'), value: 0, icon: currencyIcons['EUR'] }, // Default to EuroIcon
      { title: t('cancelations'), value: 0, icon: <NotInterestedIcon /> },
    ],
    previous: [
      { title: t('appointments'), value: 0, icon: <PersonIcon /> },
      { title: t('revenue'), value: 0, icon: currencyIcons['EUR'] }, // Default to EuroIcon
      { title: t('cancelations'), value: 0, icon: <NotInterestedIcon /> },
    ],
  });
  const [reviewData, setReviewData] = useState({ averageRating: 0, totalRatings: 0 });
  const [currency, setCurrency] = useState('€'); // Default currency symbol
  const [currencyIcon, setCurrencyIcon] = useState(<EuroIcon />); // Default currency icon

  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${baseURL}/api/currency`, {
          withCredentials: true
        });
        const currencyCode = response.data.currency || 'EUR'; // Default to EUR if currency is not set
        setCurrency(currencySymbols[currencyCode] || '€');
        setCurrencyIcon(currencyIcons[currencyCode] || <EuroIcon />);
      } catch (error) {
        console.error('Error fetching currency data:', error);
        setCurrency('€'); // Default to EUR if there's an error
        setCurrencyIcon(<EuroIcon />); // Default to EuroIcon if there's an error
      }
    };

    fetchCurrencyData();
  }, [token]);

  useEffect(() => {
    const fetchMetricsData = async () => {
      if (!businessusersUserId) {
        console.error('User ID is missing.');
        return;
      }

      const params = {
        businessusersUserId,
        ...(period === 'Custom' && {
          startDate: startDate ? startDate.toISOString().split('T')[0] : undefined,
          endDate: endDate ? endDate.toISOString().split('T')[0] : undefined,
        }),
        ...(period !== 'Custom' && { period }),
      };

      console.log('Request Parameters:', params);

      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${baseURL}/api/metrics`, {
          params,
          headers: { 'Authorization': `Bearer ${token}` } // Include token for authorization
        });

        setData({
          current: [
            { title: t('appointments'), value: response.data.Appointments || 0, icon: <PersonIcon /> },
            { title: t('revenue'), value: `${currency}${response.data.Revenue || '0'}`, icon: currencyIcon },
            { title: t('cancelations'), value: response.data.Cancellations || 0, icon: <NotInterestedIcon /> },
          ],
          previous: [
            { title: t('appointments'), value: response.data.Compare_Appointments || 0, icon: <PersonIcon /> },
            { title: t('revenue'), value: `${currency}${response.data.Compare_Revenue || '0'}`, icon: currencyIcon },
            { title: t('cancelations'), value: response.data.Compare_Cancellations || 0, icon: <NotInterestedIcon /> },
          ],
        });
      } catch (error) {
        console.error('Error fetching metrics data:', error);
        setData({
          current: [
            { title: t('appointments'), value: 0, icon: <PersonIcon /> },
            { title: t('revenue'), value: `${currency}0`, icon: currencyIcon },
            { title: t('cancelations'), value: 0, icon: <NotInterestedIcon /> },
          ],
          previous: [
            { title: t('appointments'), value: 0, icon: <PersonIcon /> },
            { title: t('revenue'), value: `${currency}0`, icon: currencyIcon },
            { title: t('cancelations'), value: 0, icon: <NotInterestedIcon /> },
          ],
        });
      }
    };

    const fetchReviewData = async () => {
      if (!businessusersUserId) {
        console.error('User ID is missing.');
        setReviewData({ averageRating: 0, totalRatings: 0 });
        return;
      }

      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${baseURL}/api/reviews/ratings/${businessusersUserId}`);
        setReviewData({
          averageRating: response.data.averageRating || 0,
          totalRatings: response.data.totalRatings || 0,
        });
      } catch (error) {
        console.error('Error fetching review data:', error);
        setReviewData({ averageRating: 0, totalRatings: 0 });
      }
    };

    fetchMetricsData();
    fetchReviewData();

    const handleLanguageChange = () => {
      fetchMetricsData();
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };


  }, [period, businessusersUserId, startDate, endDate, currency, currencyIcon, token]);




  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const getTrendStyle = (percentageChange, title) => {
    let icon, backgroundColor, borderColor;

    if (title === 'cancelations') {
      if (percentageChange > 0) {
        icon = <TrendingUpIcon style={{ color: 'red' }} />;
        backgroundColor = '#ffe7e7';
        borderColor = '#efbdbd';
      } else if (percentageChange < 0) {
        icon = <TrendingDownIcon style={{ color: 'green' }} />;
        backgroundColor = '#e7ffe7';
        borderColor = '#bdefbd';
      } else {
        icon = <TrendingFlatIcon style={{ color: 'gray' }} />;
        backgroundColor = '#eeeeee';
        borderColor = '#dedede';
      }
    } else {
      if (percentageChange > 0) {
        icon = <TrendingUpIcon style={{ color: 'green' }} />;
        backgroundColor = '#e7ffe7';
        borderColor = '#bdefbd';
      } else if (percentageChange < 0) {
        icon = <TrendingDownIcon style={{ color: 'red' }} />;
        backgroundColor = '#ffe7e7';
        borderColor = '#efbdbd';
      } else {
        icon = <TrendingFlatIcon style={{ color: 'gray' }} />;
        backgroundColor = '#eeeeee';
        borderColor = '#dedede';
      }
    }

    return { icon, backgroundColor, borderColor };
  };

  const formatStars = (stars) => {
    const reviewDescriptions = [t('bad'), t('good'), t('verygood'), t('fantastic')];
    let descriptionIndex = 0;

    if (stars >= 4.5) {
      descriptionIndex = 3;
    } else if (stars >= 3.5) {
      descriptionIndex = 2;
    } else if (stars >= 2) {
      descriptionIndex = 1;
    }

    const description = reviewDescriptions[descriptionIndex];

    if (typeof stars === 'number') {
      const fullStars = Math.floor(stars);
      const hasHalfStar = stars % 1 >= 0.5;

      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {Array(fullStars).fill().map((_, index) => (
            <StarIcon key={index} color="primary" />
          ))}
          {hasHalfStar && <StarHalfIcon color="primary" />}
          {Array(5 - Math.ceil(stars)).fill().map((_, index) => (
            <StarIcon key={index + fullStars + 1} sx={{ color: 'transparent' }} />
          ))}
          <Typography variant="body2" sx={{ marginLeft: 1, fontFamily: 'Montserrat' }}>{description}</Typography>
        </Box>
      );
    } else {
      return <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Montserrat' }}>No reviews</Typography>;
    }
  };

  return (
    <Grid container spacing={2} padding={2} justifyContent="center">
      {data.current.map((item, index) => {
        const previousItem = data.previous.find(prevItem => prevItem.title === item.title) || { value: 0 };

        const currentValue = typeof item.value === 'string'
          ? parseFloat(item.value.replace(currency, '').replace(',', '')) || 0
          : item.value;
        const previousValue = typeof previousItem.value === 'string'
          ? parseFloat(previousItem.value.replace(currency, '').replace(',', '')) || 0
          : previousItem.value || 0;

        const percentageChange = calculatePercentageChange(currentValue, previousValue);
        const trendStyle = getTrendStyle(percentageChange, item.title);

        return (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={3} sx={{ height: '100px', padding: '10px', borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 4px 0px rgb(0 0 0 / 0%), 0px 1px 4px 1px rgba(0, 0, 0, 0.12)', position: 'relative' }}>
              <Box display="flex" alignItems="center" sx={{ color: '#4a6686' }}>
                {item.icon}
                <Typography variant="h6" sx={{ letterSpacing: '1px', fontSize: '12px', color: '#2c3e50', marginLeft: '10px', fontFamily: 'Montserrat' }}>
                  {item.title}
                </Typography>
              </Box>
              <Typography variant="h3" sx={{ letterSpacing: '1px', fontSize: '30px', color: '#2c3e50', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'center', marginTop: '10px' }}>
                {item.value}
              </Typography>
              <Tooltip
      title={
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', }}>
          {t('comparedtothepreviousperiod')}
        </Typography>
      }
    >
                <Box sx={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                  <Chip
                    icon={trendStyle.icon}
                    label={<span style={{ fontFamily: 'Montserrat' }}><b>{isNaN(percentageChange) ? '0' : Math.abs(percentageChange.toFixed(2))}%</b></span>}
                    sx={{
                      height: '24px',
                      backgroundColor: trendStyle.backgroundColor,
                      color: '#4a6686',
                      fontWeight: '600',
                      fontSize: '12px',
                      border: '1px solid',
                      borderColor: trendStyle.borderColor,
                      fontFamily: 'Montserrat',
                    }}
                  />
                </Box>
              </Tooltip>
            </Paper>
          </Grid>
        );
      })}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} sx={{ height: '100px', padding: '10px', borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 3px 0px rgb(0 0 0 / 0%), 0px 1px 4px 1px rgba(0, 0, 0, 0.12)', position: 'relative' }}>
          <Box display="flex" alignItems="center" sx={{ color: '#4a6686' }}>
            <ReviewsIcon />
            <Typography variant="h6" sx={{ letterSpacing: '1px', fontSize: '12px', color: '#2c3e50', marginLeft: '10px', fontFamily: 'Montserrat' }}>
            {t('reviews')}
            </Typography>
          </Box>
          <Typography variant="h3" sx={{ letterSpacing: '1px', fontSize: '30px', color: '#2c3e50', fontWeight: '600', fontFamily: 'Montserrat', textAlign: 'center', marginTop: '10px' }}>
            {reviewData.averageRating.toFixed(1)}
          </Typography>
          <Box sx={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {formatStars(reviewData.averageRating)}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MetricsGrid;
