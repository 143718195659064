import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Box, Typography } from '@mui/material';
import { Delete, AddCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'; // Ensure correct import

const ServiceCategoryChips = ({ selectedChips, handleChipClick, handleDeleteChip, categories = [] }) => {
  const isChipSelected = (id) => selectedChips.includes(id);
  const { t, i18n } = useTranslation(); // Use the translation hook

  const isSpanish = i18n.language === 'es';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        border: 'none',
        borderRadius: '10px',
        background: 'linear-gradient(209deg, #e5f8ff 0%, #fafbff 100%)',
        padding: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: '16px',
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ marginBottom: '8px', width: '100%' }}>
      {t('selectYourServices')}
      </Typography>
      {categories.map((category) => (
        <Chip
          key={category.id}
          label={isSpanish ? category.ES_Name : category.name}
          clickable
          onClick={() => handleChipClick(category.id)}
          sx={{
            marginBottom: '8px',
            marginRight: '8px',
            backgroundColor: isChipSelected(category.id) ? '#2c3e50' : 'default',
            color: isChipSelected(category.id) ? 'white' : 'black',
            '& .MuiChip-deleteIcon': {
              color: 'white',
            },
            '&:hover': {
              backgroundColor: isChipSelected(category.id) ? '#2c3e50' : '#f0f0f0',
            },
          }}
          variant={isChipSelected(category.id) ? 'filled' : 'outlined'}
          onDelete={isChipSelected(category.id) ? () => handleDeleteChip(category.id) : undefined}
          deleteIcon={isChipSelected(category.id) ? <Delete /> : undefined}
          icon={!isChipSelected(category.id) ? <AddCircleOutline /> : undefined}
        />
      ))}
    </Box>
  );
};

ServiceCategoryChips.propTypes = {
  selectedChips: PropTypes.array.isRequired,
  handleChipClick: PropTypes.func.isRequired,
  handleDeleteChip: PropTypes.func.isRequired,
  categories: PropTypes.array
};

export default ServiceCategoryChips;
