import React, { useEffect, useState } from 'react';
import logo from '../logo.webp';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import esFlag from '../icons/es-flag.png';
import ukFlag from '../icons/uk-flag.png';
import { useMediaQuery } from '@mui/material';

import SearchBar from './SearchBar';
import NavBarChipMobile from './NavBarChipMobile';
import ToggleMapButton from '../components/ToggleMapButton';
import { useAuth } from '../context/AuthContext';

const NavbarSearch = ({ isMapVisible, onToggleMapVisibility }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();

  const pathParts = location.pathname.split('/');
  const currentLanguage = pathParts[1] || 'en';
  const [language, setLanguage] = useState(currentLanguage);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${language}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Failed to load translations:', error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [language]);

  useEffect(() => {
    setLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleLogin = () => {
    const { host, pathname } = window.location;
    const subdomain = 'business';
    const pathParts = pathname.split('/');
    const lang = pathParts[1] || 'en';

    if (host.startsWith(`${subdomain}.`)) {
      const mainDomainUrl = `${window.location.protocol}//${host.replace(`${subdomain}.`, '')}/${lang}/login`;
      window.location.href = mainDomainUrl;
    } else {
      navigate(`/${lang}/login`);
    }
  };

  const handleLogout = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${apiBaseUrl}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        logout();
        navigate(`/${language}/`);
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleBusinessConnect = () => {
    const { host, pathname } = window.location;
    const subdomain = 'business';
    const pathParts = pathname.split('/');
    const lang = pathParts[1] || 'en';

      // Check if the host starts with 'www.' and replace it
  const newHost = host.startsWith('www.') ? host.replace('www.', '') : host;

    if (!host.startsWith(`${subdomain}.`)) {
      const businessUrl = `${window.location.protocol}//${subdomain}.${newHost}/${lang}`;
      window.location.href = businessUrl;
    } else {
      window.location.reload();
    }
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    const pathWithoutLang = window.location.pathname.replace(/^\/(en|es)/, '');
    navigate(`/${selectedLanguage}${pathWithoutLang}`);
  };

  const handleBookingsClick = () => {
    const { host } = window.location;
    const subdomain = 'business';

    if (host.startsWith(`${subdomain}.`)) {
      const mainDomainUrl = `${window.location.protocol}//${host.replace(`${subdomain}.`, '')}/${language}/customerportal`;
      window.location.href = mainDomainUrl;
    } else {
      if (isAuthenticated) {
        navigate(`/${language}/customerportal`);
      } else {
        navigate(`/${language}/login`);
      }
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMapVisible = (visible) => {
    onToggleMapVisibility(visible);
  };

  // Select input component, only showing flag in the input display
  const renderValue = (selected) => {
    if (selected === 'en') {
      return <img src={ukFlag} alt="English" style={{ width: 20, height: 20 }} />;
    } else if (selected === 'es') {
      return <img src={esFlag} alt="Spanish" style={{ width: 20, height: 20 }} />;
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        backgroundColor: 'white',
        borderBottom: '1px solid #ddd',
        padding: '10px 20px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        height: '140px',
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}
      >
        <Grid item>
        <Link to={`/${language}`}>
          <img src={logo} alt="Logo" style={{ height: 30, objectFit: 'contain' }} />
          </Link>
        </Grid>

        <Grid item sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
            <Box sx={{ p: 1, borderBottom: 'none', display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  border: { 
                    xs: 'none', md: '1px solid #dfe9f1'},
                  borderRadius: '15px',
                  maxHeight: '27px',
                  display: 'flex',
                  backgroundColor: '#fff6',
                }}
              >
                <FormControl fullWidth sx={{ justifyContent: 'center' }}>
                  <Select
                    value={language}
                    onChange={handleLanguageChange}
                    renderValue={renderValue}
                    sx={{
                      fontSize: '14px',
                      fontFamily: 'Montserrat, sans-serif',
                      '& .MuiOutlinedInput-root fieldset': { borderColor: '#dfe9f1' },
                      '&:hover .MuiOutlinedInput-root fieldset': { borderColor: '#dfe9f1' },
                      '&.Mui-focused .MuiOutlinedInput-root fieldset': { borderColor: '#dfe9f1' },
                      '& .MuiSelect-icon': { display: 'flex' },
                      '& .MuiOutlinedInput-input': { display: 'flex', alignItems: 'center' },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    }}
                  >
                    <MenuItem value="en">
                      <img src={ukFlag} alt="English" style={{ width: 17, height: 17, marginRight: 8 }} />
                      {translations.language_english || 'English'}
                    </MenuItem>
                    <MenuItem value="es">
                      <img src={esFlag} alt="Spanish" style={{ width: 17, height: 17, marginRight: 8 }} />
                      {translations.language_spanish || 'Spanish'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {isMobile ? (
              <Button
                startIcon={<LoginOutlinedIcon />}
                onClick={handleLogin}
                sx={{
                  fontSize: '11px',
                  color: 'gray',
                  textTransform: 'none',
                }}
              >
                {translations.login || 'Login'}
              </Button>
            ) : (
              <>
                <Button
                  startIcon={<CalendarTodayOutlinedIcon />}
                  onClick={handleBookingsClick}
                  sx={{
                    fontSize: '11px',
                    color: 'gray',
                    textTransform: 'none',
                    marginLeft: 2,
                  }}
                >
                  {translations.your_bookings || 'Your Bookings'}
                </Button>

                <Button
                  startIcon={<AddBusinessOutlinedIcon />}
                  onClick={handleBusinessConnect}
                  sx={{
                    fontSize: '11px',
                    color: 'gray',
                    textTransform: 'none',
                    marginLeft: 2,
                  }}
                >
                  {translations.connect_business || 'Connect Business'}
                </Button>

                {isAuthenticated ? (
                  <Button
                    onClick={handleLogout}
                    sx={{
                      fontSize: '11px',
                      color: 'gray',
                      textTransform: 'none',
                      marginLeft: 2,
                    }}
                  >
                    {translations.logout || 'Logout'}
                  </Button>
                ) : (
                  <Button
                    startIcon={<LoginOutlinedIcon />}
                    onClick={handleLogin}
                    sx={{
                      fontSize: '11px',
                      color: 'gray',
                      textTransform: 'none',
                      marginLeft: 2,
                    }}
                  >
                    {translations.login || 'Login'}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 0 }}>
        {isMobile ? (
          <>
            <Grid item xs={12} sx={{ mb: 1 }}>
            <NavBarChipMobile isMapVisible={isMapVisible} onToggleMapVisibility={handleMapVisible} />
            </Grid>
            <Grid item xs={12}>
              
              <ToggleMapButton onToggleMapVisibility={onToggleMapVisibility} isMapVisible={isMapVisible} />
            </Grid>
          </>
        ) : (
          <SearchBar />
        )}
      </Grid>
    </Box>
  );
};

export default NavbarSearch;
