import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Divider, Grid } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const CheckoutFormMobile = ({
  isLoggedIn,
  handleFormChange,
  handleFormSubmit,
  formValues,
  setEmailError,
  emailError,
  setShowBookNow,  // Function from the parent to control "Book Now" button visibility
  setBookingDetails
}) => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const [translations, setTranslations] = useState({});
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [loginError, setLoginError] = useState('');

  // Phases: 'ENTER_EMAIL', 'LOGIN', 'ENTER_INFORMATION'
  const [currentPhase, setCurrentPhase] = useState('ENTER_EMAIL');

  useEffect(() => {
    // Load translations
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUserData = async () => {
        try {
          const response = await fetch('/user-data');
          const data = await response.json();

          if (response.ok) {
            handleFormChange({ target: { name: 'email', value: data.email } });
            handleFormChange({ target: { name: 'firstName', value: data.name } });
            handleFormChange({ target: { name: 'surname', value: data.surname } });
            handleFormChange({ target: { name: 'phoneNumber', value: data.phoneNumber } });
            setEmailChecked(true);
            setCurrentPhase('ENTER_INFORMATION');
            setShowBookNow(true);  // Show "Book Now" for logged-in users
          } else {
            console.error("Failed to fetch user data:", data.message);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [isLoggedIn, handleFormChange, setShowBookNow]);

  useEffect(() => {
    if (emailChecked && currentPhase === 'ENTER_INFORMATION') {
      setShowBookNow(true);
      setBookingDetails(formValues);
    } else {
      setShowBookNow(false);
    }
  }, [emailChecked, currentPhase, formValues, setShowBookNow, setBookingDetails]);

  const handleContinue = async () => {
    if (!formValues.email || !/\S+@\S+\.\S+/.test(formValues.email)) {
      setEmailError(translations['emailError'] || 'Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post('/check-email', { email: formValues.email });
      setEmailChecked(true);
      setIsExistingUser(response.data.exists);

      if (response.data.exists) {
        setEmailError('');
        setInfoMessage('');
        setCurrentPhase('LOGIN');
        setShowBookNow(false);  // Hide "Book Now" during login
      } else {
        setCurrentPhase('ENTER_INFORMATION');
        setInfoMessage(translations['infoMessage'] || 'Please fill in the form to complete your booking.');
        setShowBookNow(true);  // Show "Book Now" for new users
      }
    } catch (error) {
      setEmailError(translations['apiError'] || 'An error occurred while checking the email. Please try again.');
      console.error(error);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginError('');

    try {
      const response = await axios.post('/login', {
        username: formValues.email,
        password: formValues.password,
        userType: 'Client',
        fromCheckout: true
      });

      if (response.data.userData) {
        setEmailError('');
        setIsExistingUser(false);
        setInfoMessage('Logged in successfully!');
        setCurrentPhase('ENTER_INFORMATION');
        setShowBookNow(true);  // Show "Book Now" after successful login
        window.location.reload();
      } else {
        setLoginError(response.data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      setLoginError('An error occurred while logging in. Please try again.');
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isExistingUser) {
      handleLoginSubmit(e);
    } else {
      handleFormSubmit(e);
    }
  };

  return (
    <Box>
      {currentPhase === 'ENTER_EMAIL' && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {translations['enterEmail'] || 'Enter your Email:'}
          </Typography>
          <TextField
            name="email"
            label={translations['emailLabel'] || 'Email'}
            type="email"
            variant="standard"
            fullWidth
            margin="dense"
            value={formValues.email}
            onChange={(e) => handleFormChange(e)}
            required
            error={!!emailError}
            helperText={emailError}
            disabled={isLoggedIn}
          />
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleContinue}
                sx={{
                  backgroundColor: '#2c3e50',
                  '&:hover': {
                    backgroundColor: '#283850',
                    boxShadow: 'none',
                  },
                  '&:focus': {
                    backgroundColor: '#283850',
                  },
                  width: '100%',
                  fontSize: '16px',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  py: 1.5,
                  boxShadow: 'none',
                  textTransform: 'none',
                }}
              >
                {translations['continueButton'] || 'Continue'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {currentPhase === 'LOGIN' && (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ArrowBackIosNewIcon
              onClick={() => setCurrentPhase('ENTER_EMAIL')}
              sx={{ cursor: 'pointer', mr: 1 }}
            />
            <Typography variant="h6">{translations['backToEmail'] || 'Back to Email Entry'}</Typography>
          </Box>
          <Box component="form" onSubmit={handleLoginSubmit} mt={2}>
            <Typography variant="h6" gutterBottom>
              {translations['loginPrompt'] || 'Log in to continue:'}
            </Typography>
            {loginError && (
              <Typography sx={{ mt: 2, fontSize: '12px', color: 'red', fontFamily: 'Montserrat', textAlign: 'center' }}>
                {loginError}
              </Typography>
            )}
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
            <Grid item xs={12}>
        <TextField
          name="email"
          label={translations['emailLabel'] || 'Email'}
          type="email"
          variant="standard"
          fullWidth
          margin="dense"
          value={formValues.email}
          onChange={(e) => handleFormChange(e)}
          required
          error={!!emailError}
          helperText={emailError}
          disabled={isLoggedIn || emailChecked}  // Disable email field after it's validated or if logged in
        />
      </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  label={translations['password'] || 'Password'}
                  type="password"
                  variant="standard"
                  fullWidth
                  margin="dense"
                  value={formValues.password}
                  onChange={(e) => handleFormChange(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: '#2c3e50',
                    '&:hover': {
                      backgroundColor: '#283850',
                      boxShadow: 'none',
                    },
                    '&:focus': {
                      backgroundColor: '#283850',
                    },
                    width: '100%',
                    fontSize: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: '600',
                    py: 1.5,
                    boxShadow: 'none',
                    textTransform: 'none',
                  }}
                >
                  {translations['loginButton'] || 'Log In'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {currentPhase === 'ENTER_INFORMATION' && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {translations['enterInfo'] || 'Enter your Information:'}
          </Typography>
          <Grid container spacing={2}>
          <Grid item xs={12}>
        <TextField
          name="email"
          label={translations['emailLabel'] || 'Email'}
          type="email"
          variant="standard"
          fullWidth
          margin="dense"
          value={formValues.email}
          onChange={(e) => handleFormChange(e)}
          required
          error={!!emailError}
          helperText={emailError}
          disabled={isLoggedIn || emailChecked}  // Disable email field after it's validated or if logged in
        />
      </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstName"
                label={translations['firstName'] || 'First Name'}
                variant="standard"
                fullWidth
                margin="dense"
                value={formValues.firstName}
                onChange={(e) => handleFormChange(e)}
                required
                disabled={isLoggedIn}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="surname"
                label={translations['surname'] || 'Surname'}
                variant="standard"
                fullWidth
                margin="dense"
                value={formValues.surname}
                onChange={(e) => handleFormChange(e)}
                required
                disabled={isLoggedIn}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="phoneNumber"
                label={translations['phoneNumber'] || 'Phone Number'}
                type="tel"
                variant="standard"
                fullWidth
                margin="dense"
                value={formValues.phoneNumber}
                onChange={(e) => handleFormChange(e)}
                required
                disabled={isLoggedIn}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CheckoutFormMobile;
