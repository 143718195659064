// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Box, Chip, Button } from '@mui/material';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MetricsGrid from '../components/MetricsGrid';
import Chart from '../components/Chart';
import EmployeeScore from '../components/EmployeeScore';
import AppointmentsList from '../components/AppointmentsList';
import InformationBar from '../components/InformationBar';
import { useTranslation } from 'react-i18next'; // Ensure correct import

const Dashboard = () => {
  const [period, setPeriod] = useState('Week'); // Default to Week
  const [startDate, setStartDate] = useState(new Date()); // Default to current date
  const [endDate, setEndDate] = useState(new Date()); // Default to current date
  const [pendingStartDate, setPendingStartDate] = useState(null);
  const [pendingEndDate, setPendingEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [businessusersUserId, setBusinessusersUserId] = useState(null);
  const [employees, setEmployees] = useState([]); // State for employees data
  const [loading, setLoading] = useState(true); // Loading state for data fetching
  const { t } = useTranslation(); // Use the translation hook

  useEffect(() => {
    const today = new Date();
    if (!pendingStartDate && !pendingEndDate) {
      switch (period) {
        case 'Today':
          setStartDate(today);
          setEndDate(today);
          break;
        case 'Week':
          const weekAgo = new Date(today);
          weekAgo.setDate(today.getDate() - 6); // Start from 7 days ago
          setStartDate(weekAgo);
          setEndDate(today);
          break;
        case 'Month':
          const monthAgo = new Date(today);
          monthAgo.setDate(today.getDate() - 29); // Start from 30 days ago
          setStartDate(monthAgo);
          setEndDate(today);
          break;
        default:
          break;
      }
    }
  }, [period, pendingStartDate, pendingEndDate]);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const fetchBusinessUserId = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/businessuser`, { withCredentials: true });
        console.log('API Response:', response.data);
        setBusinessusersUserId(response.data.user_id); // Adjust according to your API response
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching business user ID:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchBusinessUserId();
  }, []);

  useEffect(() => {
    if (businessusersUserId) {
      const fetchEmployees = async () => {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.get(`${baseURL}/api/employees/${businessusersUserId}`);
          console.log('Fetched Employees Data:', response.data);
          setEmployees(response.data);
        } catch (error) {
          console.error('Error fetching employees:', error);
        }
      };

      fetchEmployees();
    }
  }, [businessusersUserId]);

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
    setPendingStartDate(null);
    setPendingEndDate(null);

    // Update the startDate and endDate based on the selected period
    const today = new Date();
    switch (newPeriod) {
      case 'Today':
        setStartDate(today);
        setEndDate(today);
        break;
      case 'Week':
        const weekAgo = new Date(today);
        weekAgo.setDate(today.getDate() - 6);
        setStartDate(weekAgo);
        setEndDate(today);
        break;
      case 'Month':
        const monthAgo = new Date(today);
        monthAgo.setDate(today.getDate() - 29);
        setStartDate(monthAgo);
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setPendingStartDate(start);
    setPendingEndDate(end);
  };

  const confirmDateRange = () => {
    if (pendingStartDate && pendingEndDate) {
      setStartDate(pendingStartDate);
      setEndDate(pendingEndDate);
      setPeriod('Custom'); // Set period to Custom
    }
    setIsOpen(false);
  };

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return <Typography variant="h6" align="center">Loading...</Typography>;
  }

  return (
    <>
    <InformationBar id={businessusersUserId} />
    <Container sx={{ marginTop: '60px'}}>
      <Typography variant="h2" align="center" gutterBottom>
      {t('dashboard')} {/* Use translation key */}
      </Typography>
      <Box display="flex" justifyContent="center" marginBottom={2} gap={1}>
        {['Today', 'Week', 'Month'].map((option) => (
          <Chip
            key={option}
            label={t(`period.${option}`)}
            variant={period === option ? 'filled' : 'outlined'}
            onClick={() => handlePeriodChange(option)}
            clickable
            sx={{ color: '#2c3e50', fontSize: '1rem', height: '40px', borderRadius: '15px' }}
          />
        ))}
        <div style={{ position: 'relative' }}>
          <Chip
            label={
              period === 'Custom' && startDate && endDate
                ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                : t('period.selectDate')
            }
            variant="outlined"
            onClick={toggleCalendar}
            sx={{
              color: '#2c3e50',
              fontSize: '1rem',
              height: '40px',
              borderRadius: '15px',
              cursor: 'pointer',
            }}
          />
          {isOpen && (
            <div style={{ position: 'absolute', top: '50px', zIndex: 1000, background: '#fff', border: '1px solid #ddd', borderRadius: '4px', padding: '10px' }}>
              <DatePicker
                selected={pendingStartDate}
                onChange={handleDateChange}
                startDate={pendingStartDate}
                endDate={pendingEndDate}
                selectsRange
                inline
              />
              <Button 
                variant="contained" 
                onClick={confirmDateRange} 
                sx={{ position: 'absolute', bottom: '10px', right: '10px' }}
              >
                OK
              </Button>
            </div>
          )}
        </div>
      </Box>

      {businessusersUserId && (
        <MetricsGrid 
          period={period} 
          businessusersUserId={businessusersUserId} 
          startDate={startDate} 
          endDate={endDate} 
        />
      )}

      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} sm={9}>
          <Paper elevation={0} sx={{ borderRadius: '15px', backgroundColor: '#fff', boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 3px 0px rgb(0 0 0 / 0%), 0px 1px 4px 1px rgba(0, 0, 0, 0.12)' }}>
            <Typography variant="h6" sx={{ color: '#2c3e50', fontWeight: 'bold', fontSize: '1rem' }} align="left" padding={2}>
            {t('appointments')}
            </Typography>
            <Box padding={2}>
              <Chart 
                period={period} 
                startDate={startDate} 
                endDate={endDate} 
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={3} >
          <EmployeeScore 
            employees={employees} 
            period={period}
            startDate={startDate} 
            endDate={endDate} 
            businessusersUserId={businessusersUserId} 
          />
        </Grid>

        <Grid item xs={12}>
          <AppointmentsList
            businessusersUserId={businessusersUserId} 
          />
        </Grid>
      </Grid>
    </Container>
    </>
  );
};

export default Dashboard;
