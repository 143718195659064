// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Get the base URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8081';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(Cookies.get('isAuthenticated') === 'true');
  const [userType, setUserType] = useState(Cookies.get('userType'));
  const [user, setUser] = useState(null);

  // Navigation function that can be used inside components
  const useAuthNavigate = () => {
    const navigate = useNavigate();
    return (path) => {
      navigate(path);
    };
  };

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/protected`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(true);
        setUserType(data.userType);
        Cookies.set('isAuthenticated', 'true', { expires: 7 });
        Cookies.set('userType', data.userType, { expires: 7 });
        fetchUser();
      } else {
        console.warn('Session expired or unauthorized');
        setIsAuthenticated(false);
        setUserType(null);
        Cookies.remove('isAuthenticated');
        Cookies.remove('userType');
      }
    } catch (error) {
      console.warn('Failed to check auth status');
      setIsAuthenticated(false);
      setUserType(null);
      Cookies.remove('isAuthenticated');
      Cookies.remove('userType');
    }
  };

  const fetchUser = async () => {
    try {
      console.log('Fetching user data from:', `${API_BASE_URL}/api/businessuser`);
  
      const response = await fetch(`${API_BASE_URL}/api/businessuser`, {
        method: 'GET',
        credentials: 'include',
      });
  
      console.log('Response Status:', response.status);
      console.log('Response Headers:', response.headers.entries());
  
      const text = await response.text();
      console.log('Raw response text:', text);
  
      // Check if the response is empty
      if (!text.trim()) {
        throw new Error('Empty response');
      }
  
      // Try parsing the JSON and handle errors
      try {
        const data = JSON.parse(text);
        console.log('Parsed user data:', data);
        setUser(data);
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError.message);
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user:', error.message);
      setUser(null);
    }
  };
  
  const login = (userType) => {
    setIsAuthenticated(true);
    setUserType(userType);
    Cookies.set('isAuthenticated', 'true', { expires: 7 });
    Cookies.set('userType', userType, { expires: 7 });
    fetchUser();
  };
  


  const logout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setIsAuthenticated(false);
        setUserType(null);
        Cookies.remove('isAuthenticated');
        Cookies.remove('userType');
        setUser(null);
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userType, user, login, logout, checkAuthStatus, useAuthNavigate }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
