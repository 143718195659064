import React, { useState, useEffect } from 'react';
import { Typography, FormGroup, FormControlLabel, Checkbox, List, ListItem, ListItemText, Grid, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getHours, getMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next'; // Ensure correct import
import { enUS } from 'date-fns/locale';

// Custom CSS for DatePicker
const datePickerStyles = `
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__input-container input {
    width: 100%;
    box-sizing: border-box; /* Ensures the width calculation includes padding and border */
  }
`;

const OpeningHours = React.memo(({ openingHours, editing, onUpdateOpeningHours }) => {
  const [localOpeningHours, setLocalOpeningHours] = useState({});
  const { t } = useTranslation(); // Use the translation hook
  useEffect(() => {
    setLocalOpeningHours(openingHours);
  }, [openingHours]);

  const handleCheckboxChange = (day) => (event) => {
    const { checked } = event.target;
    const updatedOpeningHours = {
      ...localOpeningHours,
      [day]: {
        ...localOpeningHours[day],
        open: !!checked,
        startTime: checked ? localOpeningHours[day]?.startTime : null,
        endTime: checked ? localOpeningHours[day]?.endTime : null,
      },
    };
    setLocalOpeningHours(updatedOpeningHours);
    onUpdateOpeningHours(updatedOpeningHours);
  };

  const handleTimeChange = (day, timeType) => (date) => {
    if (!date) return;

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const updatedOpeningHours = {
      ...localOpeningHours,
      [day]: {
        ...localOpeningHours[day],
        [timeType]: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`,
      },
    };
    setLocalOpeningHours(updatedOpeningHours);
    onUpdateOpeningHours(updatedOpeningHours);
  };

  // Ensure the time is formatted as HH:MM
  const formatTime = (time) => {
    if (!time) return '00:00';
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const convertToDate = (time) => {
    if (!time) return null;
    const [hours, minutes] = time.split(':').map(Number);
    return new Date(1970, 0, 1, hours, minutes);
  };

  const translateDay = (day) => {
    const dayTranslations = {
      Monday: t('monday'),
      Tuesday: t('tuesday'),
      Wednesday: t('wednesday'),
      Thursday: t('thursday'),
      Friday: t('friday'),
      Saturday: t('saturday'),
      Sunday: t('sunday'),
    };
    return dayTranslations[day] || day;
  };

  return (
    <div style={{ marginTop: 16, width: '100%' }}>
      <Typography variant="h6" gutterBottom>
      {t('openinghours')}
      </Typography>
      <style>{datePickerStyles}</style>
      {editing ? (
        <FormGroup>
          {Object.keys(localOpeningHours).map((day) => (
            <Grid container key={day} alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!localOpeningHours[day]?.open}
                      onChange={handleCheckboxChange(day)}
                      color="primary"
                    />
                  }
                  label={translateDay(day)}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box sx={{ width: '100%', maxWidth: 150 }}>
                  <DatePicker
                    selected={convertToDate(localOpeningHours[day]?.startTime)}
                    onChange={handleTimeChange(day, 'startTime')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={t('starttime')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    locale={enUS}
                    wrapperClassName="datepicker-wrapper"
                    disabled={!localOpeningHours[day]?.open}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box sx={{ width: '100%', maxWidth: 150 }}>
                  <DatePicker
                    selected={convertToDate(localOpeningHours[day]?.endTime)}
                    onChange={handleTimeChange(day, 'endTime')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={t('endtime')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    locale={enUS}
                    wrapperClassName="datepicker-wrapper"
                    disabled={!localOpeningHours[day]?.open}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </FormGroup>
      ) : (
        <List>
          {Object.keys(localOpeningHours).map((day) => (
            <ListItem
              key={day}
              sx={{ padding: '4px 16px' }} // Adjust the padding here to reduce gap
            >
              <ListItemText
                primary={
                  localOpeningHours[day]?.open
                    ? `${translateDay(day)}: ${formatTime(localOpeningHours[day].startTime)} - ${formatTime(localOpeningHours[day].endTime)}`
                    : `${translateDay(day)}: ${t('closed')}`
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
});

export default OpeningHours;
