import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Rating
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// Custom Button with MUI styling
const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2c3e50',
  color: '#fff',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#1a252f',
    boxShadow: 'none',
  },
  '&:focus': {
    backgroundColor: '#1a252f',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: '#1a252f',
    boxShadow: 'none',
  },
}));

const loadTranslations = async (language) => {
  try {
    const response = await import(`../customer-locales/${language}.json`);
    return response;
  } catch (error) {
    console.error('Failed to load translations:', error);
    const defaultResponse = await import(`../customer-locales/en.json`);
    return defaultResponse;
  }
};

function RecentAppointments({ pastAppointments, userId, userName }) {
  const [open, setOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [rating, setRating] = useState(null);
  const [review, setReview] = useState('');
  const [hover, setHover] = useState(-1);
  const [reviewedAppointments, setReviewedAppointments] = useState([]);
  const [translations, setTranslations] = useState({});
  const { lang = 'en' } = useParams();

  useEffect(() => {
    const fetchReviewedAppointments = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/api/reviews?user_id=${userId}`, { withCredentials: true });
        const reviewedIds = response.data.map(review => review.appointment_id);
        setReviewedAppointments(reviewedIds);
      } catch (error) {
        console.error('Error fetching reviewed appointments:', error);
      }
    };

    const loadTranslationsData = async () => {
      const translationsData = await loadTranslations(lang);
      setTranslations(translationsData);
    };

    if (userId) {
      fetchReviewedAppointments();
    }
    loadTranslationsData();
  }, [userId, lang]);

  const handleLeaveReview = (appointmentId) => {
    if (reviewedAppointments.includes(appointmentId)) {
      alert(translations.reviewedAlready || 'You have already reviewed this appointment.');
      return;
    }
    setSelectedAppointmentId(appointmentId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRating(null);
    setReview('');
  };

  const handleSubmit = async () => {
    if (selectedAppointmentId == null || rating == null || review.trim() === '' || !userId || !userName) {
      alert(translations.pleaseFillAllFields || 'Please fill in all required fields.');
      return;
    }

    const reviewData = {
      appointment_id: selectedAppointmentId,
      user_id: userId,
      user_name: userName,
      rating,
      review_text: review,
    };

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(`${apiBaseUrl}/api/reviews`, reviewData, { withCredentials: true });
      console.log('Review added successfully:', response.data);

      setReviewedAppointments([...reviewedAppointments, selectedAppointmentId]);

      handleClose();
    } catch (error) {
      console.error('Error adding review:', error);
      alert(translations.errorAddingReview || 'Error adding review. Please try again.');
    }
  };

  // Sort appointments by date in descending order
  const sortedAppointments = [...pastAppointments].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {translations.recentAppointments || 'Recent Appointments'}
      </Typography>
      {sortedAppointments.length > 0 ? (
        <Grid container spacing={3}>
          {sortedAppointments.map((appointment) => (
            <Grid item xs={12} sm={6} md={4} key={appointment.id}>
              <Card sx={{ maxWidth: 345, boxShadow: 'none' }}>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" fontSize="20px">
                    <strong>{appointment.company_name}</strong>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {appointment.service_type}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {appointment.service_time} minutes
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                  <strong>{translations.time || 'Time'}:</strong> {appointment.time.slice(0, 5)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{translations.date || 'Date'}:</strong> {new Date(appointment.date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{translations.price || 'Price'}:</strong> ${appointment.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Appointment ID:</strong> {appointment.id}
                  </Typography>
                  <CustomButton
                    variant="contained"
                    onClick={() => handleLeaveReview(appointment.id)}
                    sx={{ marginTop: '10px' }}
                    disableRipple
                    disabled={reviewedAppointments.includes(appointment.id)}
                  >
                    {reviewedAppointments.includes(appointment.id) ? translations.reviewed || 'Reviewed' : translations.leaveReview || 'Leave a Review'}
                  </CustomButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>{translations.noPastAppointments || 'You have no past appointments'}</Typography>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{translations.rateAppointment || 'Rate Appointment'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Typography variant="h6">{translations.rateYourExperience || 'Rate Your Experience'}</Typography>
            <Rating
              name="hover-feedback"
              value={rating}
              precision={1}
              getLabelText={(value) => `${value} ${translations.stars || 'Stars'}`}
              onChange={(event, newValue) => setRating(newValue)}
              onChangeActive={(event, newHover) => setHover(newHover)}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {rating !== null && (
              <Box sx={{ ml: 2 }}>
                {hover !== -1 ? `${hover} ${translations.stars || 'Stars'}` : `${rating} ${translations.stars || 'Stars'}`}
              </Box>
            )}
            <TextField
              label={translations.comment || 'Comment'}
              multiline
              rows={4}
              value={review}
              onChange={(e) => setReview(e.target.value)}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  overflowX: 'auto',
                },
                '& .MuiOutlinedInput-root': {
                  maxWidth: '100%',
                },
                boxSizing: 'border-box',
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} disableRipple>
            {translations.cancel || 'Cancel'}
          </CustomButton>
          <CustomButton onClick={handleSubmit} disableRipple>
            {translations.submit || 'Submit'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RecentAppointments;
