import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Ensure correct import

function AddAppointment() {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook

  const handleButtonClick = () => {
    navigate('/business/AddApnt'); // Navigate to the AddApnt page
  };

  return (
    <Grid 
      container 
      justifyContent="center" 
      alignItems="center"
    >
      <Grid item>
        <Paper elevation={0} style={{ 
          backgroundColor: '#eef5fc', 
          border: '2px solid #e6ecf2', 
          borderRadius: '15px', 
          padding: '20px', 
          textAlign: 'center', 
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center',
          width: '160px',
        }}>
          <Typography 
            variant="body1" 
            gutterBottom
            style={{ fontFamily: 'Montserrat, sans-serif', paddingBottom: '10px' }} // Apply Montserrat font
          >
            {t('addAppointment')} {/* Use translation key */}
          </Typography>
          <Button
            variant="contained"
            style={{
              width: '100%',
              height: '30px',
              borderRadius: '8px',
              backgroundColor: '#2b3c4f',
              minWidth: '100%',
              minHeight: '30px',
              padding: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
            }}
            onClick={handleButtonClick} // Add the onClick handler
          >
            <AddIcon />
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AddAppointment;
