import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

const SearchBarFront = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState({
    services: '',
    city: '',
    date: null,
    serviceId: null,
  });
  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceMap, setServiceMap] = useState({});
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState(location.pathname.split('/')[1] || 'en');

  useEffect(() => {
    // Update the language based on the URL when the location changes
    const newLanguage = location.pathname.split('/')[1] || 'en';
    if (newLanguage !== language) {
      setLanguage(newLanguage);
    }
  }, [location.pathname, language]);

  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const response = await axios.get('/api/servicecategories');
        const services = response.data;
        setServiceOptions(services.map(item => item.name));
        setServiceMap(services.reduce((map, item) => {
          map[item.name] = item.id;
          return map;
        }, {}));
      } catch (error) {
        console.error('Error fetching service categories:', error);
      }
    };

    fetchServiceCategories();
  }, []);

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${language}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Error loading translations:', error);
        setTranslations((await import(`../visitor-locales/en.json`)));
      }
    };

    loadTranslations();
  }, [language]);

  const handleInputChange = (e, newValue, field) => {
    setSearchParams(prevState => ({
      ...prevState,
      [field]: newValue,
      ...(field === 'services' ? { serviceId: serviceMap[newValue] || null } : {}),
    }));
  };

  const handleDateChange = (date) => {
    setSearchParams(prevState => ({
      ...prevState,
      date: date ? format(date, 'yyyy-MM-dd') : null,
    }));
  };

  const handleSearch = useCallback(async () => {
    const { services, city, date } = searchParams;
    const displayCity = city || '';
    const displayService = services || '';

    if (displayService || displayCity || date) {
      try {
        const response = await axios.get('/api/search', {
          params: {
            categoryName: displayService,
            city: displayCity,
            date: date || new Date().toISOString().split('T')[0], // Use today's date if no date is selected
          },
        });

        const searchResultsPath = `/${language}/search-results`;

        navigate(searchResultsPath, { state: { results: response.data, searchParams: { displayCity, displayService, date } } });
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }
  }, [searchParams, navigate, language]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 4,
        flexDirection: { xs: 'row', sm: 'row' },
        alignItems: 'center',
        height: '45px',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
      }}
    >
      <TextField
        variant="outlined"
        placeholder={translations.city || "City"}
        name="city"
        value={searchParams.city}
        onChange={(e) => handleInputChange(e, e.target.value, 'city')}
        autoComplete="on"
        autoCorrect="on"
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: 'white',
          borderRadius: '0',
          borderLeft: 'none',
          borderRight: 'none',
          borderTopLeftRadius: '25px',
          borderBottomLeftRadius: '25px',
          '& .MuiOutlinedInput-root': {
            height: '100%',
            borderRadius: '0',
            borderTopLeftRadius: '25px',
            borderBottomLeftRadius: '25px',
            '& fieldset': {
              borderColor: 'transparent !important',
              borderHeight: '1px',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '& input': {
              height: '100%',
              padding: '10px 14px',
              boxSizing: 'border-box',
              // Autofill styles
              '&:-webkit-autofill': {
                backgroundColor: 'white !important',
                color: 'black !important',
                '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
                boxShadow: '0 0 0px 1000px white inset !important',
                '-webkit-text-fill-color': 'black !important',
              },
              '&:-moz-autofill': {
                backgroundColor: 'white !important',
                color: 'black !important',
                boxShadow: '0 0 0px 1000px white inset !important',
              },
            },
          },
        }}
      />
      
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        disableRipple
        sx={{
          minWidth: '120px',
          height: '100%',
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          borderTopRightRadius: '25px',
          borderBottomRightRadius: '25px',
          backgroundColor: '#2c3e50',
          boxShadow: 'none',
          fontFamily: 'Montserrat, sans-serif',
          fontStyle: 'normal',
          '&:hover': {
            backgroundColor: '#2c3e50',
            boxShadow: 'none',
          },
          '&:focus': {
            backgroundColor: '#2c3e50',
            boxShadow: 'none',
            outline: 'none',
          },
        }}
      >
        {translations.search || "Search"}
      </Button>
    </Box>
  );
};

export default SearchBarFront;
