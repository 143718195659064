import React, { useState, useRef, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './BookingCalendar.css';
import { useAuth } from '../../context/AuthContext';
import { fetchEmployees } from '../services/employeeService';
import AppointmentModal from './AppointmentModal';
import CustomHeader from './CustomHeader';
import { startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, addDays } from 'date-fns';
import { useTranslation } from 'react-i18next'; // Ensure correct import
import i18next from 'i18next';

function BookingCalendar() {
  const { t, i18n } = useTranslation(); // Use the translation hook
  const [selectedEmployee, setSelectedEmployee] = useState('All');
  const [events, setEvents] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalEvent, setModalEvent] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [viewType, setViewType] = useState('timeGridWeek');
  const [dateColumns, setDateColumns] = useState([]);
  const calendarRef = useRef(null);
  const { user, userType } = useAuth();

   // Function to ensure data is always in array format
   const ensureArray = (data) => {
    if (Array.isArray(data)) {
      return data;
    } else if (typeof data === 'object' && data !== null) {
      return [data];
    }
    return [];
  };

  const fetchBlockedDates = async () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${baseURL}/api/blocked-dates`, { credentials: 'include' });
      if (!response.ok) throw new Error('Error fetching blocked dates');
      const data = await response.json();
  
      // Ensure that data is always an array
      if (Array.isArray(data)) {
        setBlockedDates(data);
      } else if (typeof data === 'object' && data !== null) {
        setBlockedDates([data]); // Wrap single object in an array
      } else {
        console.error('Blocked dates response is not an array or object:', data);
        setBlockedDates([]);
      }
    } catch (error) {
      console.error('Error fetching blocked dates:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
  
    if (user === undefined || userType === undefined) {
      console.log('Waiting for user or userType to be defined...');
      return;
    }
  
    if (!user || !userType) {
      setError('User data or userType is missing');
      setLoading(false);
      return;
    }
  
    if (userType !== 'Business') {
      setError('User is not authorized');
      setLoading(false);
      return;
    }
  
    const fetchAppointmentsAndEmployees = async () => {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
    
      try {
        const appointmentsResponse = await fetch(`${baseURL}/api/business/appointments`, { credentials: 'include' });
    
        if (!appointmentsResponse.ok) throw new Error('Error fetching appointments');
    
        let appointments = await appointmentsResponse.json();
        appointments = ensureArray(appointments);
    
        const companyId = user.user_id;
        if (!companyId) throw new Error('Company ID is missing');
    
        const employeesResponse = await fetchEmployees(companyId);
        const employeesArray = ensureArray(employeesResponse);
    
        console.log('Appointments:', appointments);
        console.log('Employees:', employeesArray);
    
        if (!Array.isArray(appointments)) {
          throw new Error('Appointments is not an array');
        }
    
        if (!Array.isArray(employeesArray)) {
          throw new Error('Employees is not an array');
        }
    
        const employeeMap = employeesArray.reduce((map, employee) => {
          map[employee.id] = employee.name;
          return map;
        }, {});
    
        const filteredAppointments = appointments.filter(appt => appt.status !== 'Canceled');
    
        const calendarEvents = filteredAppointments.map(appt => {
          const [year, month, day] = appt.date.split('-').map(num => parseInt(num, 10));
          const [hour, minute] = appt.time.split(':').map(num => parseInt(num, 10));
          const startDateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
          const startDate = new Date(startDateStr);
          const serviceTimeMinutes = parseInt(appt.service_time, 10);
          const serviceTimeMs = serviceTimeMinutes * 60000;
          const endDate = new Date(startDate.getTime() + serviceTimeMs);
    
          const adjustedStartDate = addDays(startDate, 1);
          const adjustedEndDate = addDays(endDate, 1);
    
          return {
            id: appt.id,
            title: appt.service,
            start: adjustedStartDate.toISOString(),
            end: adjustedEndDate.toISOString(),
            resourceId: String(appt.employee_id),
            extendedProps: {
              subtitle: employeeMap[appt.employee_id] || 'Unknown',
              appointment: appt,
            }
          };
        });
    
        setEvents(calendarEvents);
        setEmployees(employeesArray.map(emp => ({ id: String(emp.id), title: emp.name })));
    
        await fetchBlockedDates();
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchAppointmentsAndEmployees();
  }, [user, userType]);

  const handleBlockedDateAdd = async (date) => {
    if (blockedDates.some(blockedDate => isSameDay(new Date(date), new Date(blockedDate.date)))) {
      alert('A blocked date already exists for this day.');
      return;
    }

    const reason = prompt(t('openblockedalert'));
    if (!reason) return;
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${baseURL}/api/blocked-dates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ date, reason }),
      });

      if (!response.ok) {
        throw new Error('Error creating blocked date');
      }

      const newBlockedDate = await response.json();
      setBlockedDates(prevBlockedDates => [...prevBlockedDates, newBlockedDate]);
    } catch (error) {
      console.error('Error creating blocked date:', error);
      setError(error.message);
    }
  };

  const handleEventAdd = async (info) => {
    if (info.allDay) {
      handleBlockedDateAdd(info.startStr);
    } else {
      let title = prompt('Enter Event Title:');
      let calendarApi = info.view.calendar;

      calendarApi.unselect(); // Clear selection

      if (title && info.resource) {
        const newEvent = {
          id: events.length + 1,
          title,
          start: info.startStr,
          end: info.endStr,
          resourceId: String(info.resource.id),
          extendedProps: {
            subtitle: String(info.resource.id)
          }
        };

        if (Array.isArray(events)) {
        console.log('Adding new event:', newEvent);
        setEvents(prevEvents => [...prevEvents, newEvent]);
        } else {
          console.error('Events is not an array:', events);
        }
      }
    }
  };

  const handleEventClick = async (info) => {
    if (info.event.id.startsWith('blocked-')) {
      const confirmed = window.confirm(`${t('deleteblockedalert')} '${info.event.title}'?`);
      if (confirmed) {
        await handleEventDelete(info);
      }
    } else {
      console.log('Appointment details:', info.event.extendedProps.appointment);
      setAppointmentDetails(info.event.extendedProps.appointment);
      setModalEvent(info.event);
    }
  };

  const handleEventDelete = async (info) => {
    if (info.event.id.startsWith('blocked-')) {
      try {
        const blockedDateId = info.event.id.replace('blocked-', '');

        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseURL}/api/blocked-dates/${blockedDateId}`, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Error deleting blocked date');
        }

        setBlockedDates(prevBlockedDates => 
          prevBlockedDates.filter(date => `blocked-${date.id}` !== info.event.id)
        );

        const calendarApi = calendarRef.current.getApi();
        calendarApi.getEventById(info.event.id)?.remove();
      } catch (error) {
        console.error('Error deleting blocked date:', error);
        setError(error.message);
      }
    } else {
      const confirmed = window.confirm(`Are you sure you want to delete the event '${info.event.title}'?`);
      if (confirmed) {
        const updatedEvents = events.filter(event => event.id !== info.event.id);
        setEvents(updatedEvents);

        const calendarApi = calendarRef.current.getApi();
        calendarApi.getEventById(info.event.id)?.remove();
      }
    }
  };

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };

  const filteredEvents = selectedEmployee === 'All'
    ? events
    : events.filter(event => event.resourceId === selectedEmployee);

  const handlePlaceholderClick = async (dateStr) => {
    const existingBlockedDate = blockedDates.find(blockedDate => isSameDay(new Date(dateStr), new Date(blockedDate.date)));
    if (existingBlockedDate) {
      const confirmed = window.confirm(`Are you sure you want to delete the blocked date for ${dateStr}?`);
      if (confirmed) {
        await handleEventDelete({ event: { id: `blocked-${existingBlockedDate.id}` } });
      }
    } else {
      await handleBlockedDateAdd(dateStr);
    }
  };

  const getPlaceholderEvents = (days) => {
    return days
      .filter(day => !blockedDates.some(blocked => isSameDay(new Date(day.toISOString().split('T')[0]), new Date(blocked.date))))
      .map(day => ({
        id: `open-${day.toISOString().split('T')[0]}`,
        title: t('open'),
        start: day.toISOString().split('T')[0],
        end: day.toISOString().split('T')[0],
        allDay: true,
        backgroundColor: 'lightgreen',
        borderColor: 'green',
        textColor: 'black',
        editable: false,
        classNames: ['open-date'],
        extendedProps: {
          handleClick: () => handlePlaceholderClick(day.toISOString().split('T')[0])
        }
      }));
  };

  const allEvents = [
    ...filteredEvents,
    ...blockedDates.map(date => ({
      id: `blocked-${date.id}`,
      title: `${t('blocked')}: ${date.reason || 'N/A'}`,
      start: date.date,
      end: date.date,
      allDay: true,
      backgroundColor: 'pink',
      borderColor: 'red',
      textColor: 'black',
      editable: false,
      classNames: ['blocked-date'],
    })),
    ...getPlaceholderEvents(dateColumns)
  ];

  useEffect(() => {
    const updateEvents = () => {
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        allEvents.forEach(event => {
          const existingEvent = calendarApi.getEventById(event.id);
          if (existingEvent) {
            existingEvent.remove(); // Remove the old event
          }
          calendarApi.addEvent(event); // Add the updated/new event
        });
      }
    };

    setTimeout(updateEvents, 0);
  }, [allEvents]);

  const eventContent = (arg) => (
    <div onClick={arg.event.extendedProps.handleClick ? arg.event.extendedProps.handleClick : undefined}>
      <strong>{arg.event.title}</strong>
      <div>{arg.event.extendedProps.subtitle}</div>
    </div>
  );

  const eventDidMount = (info) => {
    const eventEl = info.el;
    if (info.event.id.startsWith('blocked-')) {
      eventEl.classList.add('blocked-date');
    } else if (info.event.id.startsWith('open-')) {
      eventEl.classList.add('open-date');
      eventEl.style.cursor = 'pointer'; // Indicate that it is clickable
    }
  };

  const datesSet = (info) => {
    const calendarApi = info.view.calendar;
    const blockedDatesSet = new Set(blockedDates.map(date => date.date.split('T')[0]));

    document.querySelectorAll('.fc-timegrid-slot').forEach(slotEl => {
      const slotDateStr = slotEl.getAttribute('data-date');
      if (blockedDatesSet.has(slotDateStr)) {
        slotEl.classList.add('fc-timegrid-slot-blocked');
      } else {
        slotEl.classList.remove('fc-timegrid-slot-blocked');
      }
    });

    if (calendarApi.view.type === 'timeGridWeek') {
      const start = calendarApi.view.currentStart;
      const end = calendarApi.view.currentEnd;
      const days = eachDayOfInterval({ start, end });
      setDateColumns(days);
    } else {
      setDateColumns([]);
    }
  };

  const handleSave = async (updatedAppointment) => {
    setAppointmentDetails(updatedAppointment);
    setModalEvent(null);
    

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      
      const response = await fetch(`${apiBaseUrl}/api/business/appointments/${updatedAppointment.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: updatedAppointment.status,
          date: updatedAppointment.date,
          time: updatedAppointment.time,
          service: updatedAppointment.service,
          service_type: updatedAppointment.service_type,
          price: updatedAppointment.price,
        }),
      });

      if (response.ok) {
        const updatedData = await response.json();
        console.log('Appointment updated:', updatedData);

        setEvents(prevEvents =>
          prevEvents.map(event =>
            event.id === updatedAppointment.id ? { ...event, ...updatedAppointment } : event
          )
        );
      } else {
        console.error('Failed to update appointment');
        alert('Failed to update appointment');
      }
    } catch (error) {
      console.error('Error updating appointment:', error);
      alert('Error updating appointment');
    }
  };

  const handleToday = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    calendarApi.changeView('timeGridDay');
  };

  const handleWeek = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView('timeGridWeek');
    setViewType('timeGridWeek');
  };

  const handleMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView('dayGridMonth');
    setViewType('dayGridMonth');
  };

  const handleViewDidMount = (info) => {
    const { view } = info;
    if (view.type === 'timeGridWeek') {
      const start = startOfWeek(view.currentStart, { weekStartsOn: 0 });
      const end = endOfWeek(view.currentEnd, { weekStartsOn: 0 });
      const days = eachDayOfInterval({ start, end });

      console.log('Start Date:', start);
      console.log('End Date:', end);
      console.log('Days:', days);

      setDateColumns(days);
    } else {
      setDateColumns([]);
    }
  };
 // Function to replace 'all-day' text
 // Function to replace 'all-day' text
 const replaceAllDayText = useCallback(() => {
  const allDayTextElements = document.querySelectorAll('.fc-timegrid-axis-cushion');
  
  allDayTextElements.forEach(el => {
    if (el.innerText.includes('all-day')) {
      el.innerText = t('allday'); // Use translation function
    }
  });
}, [t]);

useEffect(() => {
  const applyTranslation = () => {
    requestAnimationFrame(() => {
      replaceAllDayText();
    });
  };

  // Apply translation on initial render
  applyTranslation();

  // Observe calendar DOM for changes
  const calendarEl = document.querySelector('.fc');
  if (calendarEl) {
    const observer = new MutationObserver(applyTranslation);
    observer.observe(calendarEl, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }

  // Apply translations on language change
  const handleLanguageChange = () => {
    applyTranslation();
  };

  i18n.on('languageChanged', handleLanguageChange);

  return () => {
    i18n.off('languageChanged', handleLanguageChange);
  };
}, [replaceAllDayText, i18n]);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;


  return (
    <div className="booking-calendar-container">
      <CustomHeader
        currentDate={new Date()}
        onPrev={() => calendarRef.current.getApi().prev()}
        onNext={() => calendarRef.current.getApi().next()}
        onToday={handleToday}
        onWeek={handleWeek}
        onMonth={handleMonth}
        viewType={viewType}
        dateColumns={dateColumns}
        employees={employees}
        selectedEmployee={selectedEmployee}
        handleEmployeeChange={handleEmployeeChange}
      />
      <div className="booking-calendar">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          headerToolbar={false}
          events={allEvents}
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          slotDuration="00:15:00"
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          select={handleEventAdd}
          eventClick={handleEventClick}
          nowIndicator={true}
          height="100%"
          contentHeight="auto"
          eventContent={eventContent}
          eventDidMount={eventDidMount}
          datesSet={datesSet}
          viewDidMount={handleViewDidMount}
        />
      </div>
      {modalEvent && (
        <AppointmentModal
          open={!!modalEvent}
          onClose={() => setModalEvent(null)}
          appointment={appointmentDetails}
          onSave={handleSave}
        />
      )}
    </div>
  );
}

export default BookingCalendar;
