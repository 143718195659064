import React from 'react';
import BookingCalendar from '../components/BookingCalendar'; 
import BookingCalendarMobile from '../components/BookingCalendarMobile';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const Calendar = () => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'transparent',
        padding: '0px 10px',
        marginTop: isMobile ? '0px' : '-100px', // Conditionally set marginTop
      }}
    >
      {isMobile ? <BookingCalendarMobile /> : <BookingCalendar />}
    </div>
  );
}

export default Calendar;
