import React, { useEffect, useState } from 'react';
import logo from '../logo.webp'; 
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import esFlag from '../icons/es-flag.png';
import ukFlag from '../icons/uk-flag.png';
import { useAuth } from '../context/AuthContext';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();

  // Extract language from URL
  const pathParts = location.pathname.split('/');
  const currentLanguage = pathParts[1] || 'en';
  const [language, setLanguage] = useState(currentLanguage);
  const [translations, setTranslations] = useState({});

  // Load the appropriate translation file based on the language
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${language}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [language]);

  // Update language state when URL changes
  useEffect(() => {
    setLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleLogin = () => {
    const { host, pathname } = window.location;
    const subdomain = 'business';

    // Extract current language from pathname
  const pathParts = pathname.split('/');
  const lang = pathParts[1] || 'en'; // Defaults to 'en' if not set
    
    // Check if we are on the subdomain
    if (host.startsWith(`${subdomain}.`)) {
      // Replace with main domain (without subdomain) and then navigate
      const mainDomainUrl = `${window.location.protocol}//${host.replace(`${subdomain}.`, '')}/${lang}/login`;
      window.location.href = mainDomainUrl;
    } else {
      navigate(`/${lang}/login`);
    }
  };

  const handleLogout = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${apiBaseUrl}/api/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        logout();
        navigate(`/${language}/`);
      } else {
        const errorText = await response.text();
        console.error('Logout failed:', errorText);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleBookingsClick = () => {
    const { host, pathname } = window.location;
    const subdomain = 'business';

      // Extract current language from pathname
  const pathParts = pathname.split('/');
  const lang = pathParts[1] || 'en'; // Defaults to 'en' if not set
  
    // Ensure we return to the main domain if coming from the subdomain
    if (host.startsWith(`${subdomain}.`)) {
      const mainDomainUrl = `${window.location.protocol}//${host.replace(`${subdomain}.`, '')}/${lang}/customerportal`;
      window.location.href = mainDomainUrl;
    } else {
      if (isAuthenticated) {
        navigate(`/${lang}/customerportal`);
      } else {
        navigate(`/${lang}/login`, { state: { from: `/${lang}/customerportal` } });
      }
    }
  };

  const handleBusinessConnect = () => {
    const { host, pathname } = window.location;
    const subdomain = 'business';

     // Extract current language from pathname
  const pathParts = pathname.split('/');
  const lang = pathParts[1] || 'en'; // Defaults to 'en' if not set

  // Check if the host starts with 'www.' and replace it
  const newHost = host.startsWith('www.') ? host.replace('www.', '') : host;
  
    if (!host.startsWith(`${subdomain}.`)) {
      const businessUrl = `${window.location.protocol}//${subdomain}.${newHost}/${lang}`;
      window.location.href = businessUrl;
    } else {
      window.location.reload();
    }
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);

    const searchParams = new URLSearchParams(window.location.search);
    const pathWithoutLang = window.location.pathname.replace(/^\/(en|es)/, '');
    navigate(`/${selectedLanguage}${pathWithoutLang}${searchParams ? `?${searchParams.toString()}` : ''}`);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', borderBottom: '1px solid #ddd' }}>
      <Link to={`/${language}`}>
      <img src={logo} alt="Logo" style={{ height: 20, objectFit: 'contain' }} />
      </Link>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ p: 1, borderBottom: 'none' }}>
          <Box sx={{ border: '1px solid #dfe9f1', borderRadius: '15px', maxHeight: '27px', display: 'flex', backgroundColor: '#fff6' }}>
            <FormControl fullWidth sx={{ justifyContent: 'center' }}>
              <Select
                value={language}
                onChange={handleLanguageChange}
                label={translations.language_label || "Language"}
                sx={{ 
                  fontSize: '14px',
                  fontFamily: 'Montserrat, sans-serif',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#dfe9f1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#dfe9f1',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#dfe9f1',
                    },
                  },
                  '& .MuiSelect-icon': {
                    display: 'none',
                  },
                  '& .MuiOutlinedInput-input': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  }
                }}
              >
                <MenuItem value="en">
                  <img src={ukFlag} alt="English" style={{ width: 17, height: 17, marginRight: 8 }} />
                  {translations.language_english || "English"}
                </MenuItem>
                <MenuItem value="es">
                  <img src={esFlag} alt="Spanish" style={{ width: 17, height: 17, marginRight: 8 }} />
                  {translations.language_spanish || "Spanish"}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Button
          color="inherit"
          startIcon={<CalendarTodayOutlinedIcon />}
          disableRipple
          onClick={handleBookingsClick}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            '&:hover': { backgroundColor: 'transparent' },
            '&:focus': { backgroundColor: 'transparent' },
            fontSize: '11px',
            color: 'gray',
            textTransform: 'none',
          }}
        >
          {translations.your_bookings || "Your Bookings"}
        </Button>
        <Button
          color="inherit"
          startIcon={<AddBusinessOutlinedIcon />}
          disableRipple
          onClick={handleBusinessConnect}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            '&:hover': { backgroundColor: 'transparent' },
            '&:focus': { backgroundColor: 'transparent' },
            fontSize: '11px',
            color: 'gray',
            textTransform: 'none',
          }}
        >
          {translations.connect_business || "Connect Business"}
        </Button>
        {isAuthenticated ? (
          <Button
            color="inherit"
            onClick={handleLogout}
            disableRipple
            sx={{
              '&:hover': { backgroundColor: 'transparent' },
              '&:focus': { backgroundColor: 'transparent' },
              fontSize: '11px',
              color: 'gray',
              textTransform: 'none',
            }}
          >
            {translations.logout || "Logout"}
          </Button>
        ) : (
          <Button
            color="inherit"
            startIcon={<LoginOutlinedIcon />}
            onClick={handleLogin}
            disableRipple
            sx={{
              '&:hover': { backgroundColor: 'transparent' },
              '&:focus': { backgroundColor: 'transparent' },
              fontSize: '11px',
              color: 'gray',
              textTransform: 'none',
            }}
          >
            {translations.login || "Login"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Navbar;
