import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Divider } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom'; 

const SetUserPassword = ({ translations = {} }) => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage(translations.passwordMismatch || 'Passwords do not match');
            return;
        }

        try {
            setLoading(true);
            const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.post(`${apiBaseUrl}/api/set-password`, { token, password });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(translations.failedToSetPassword || 'Failed to set password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ 
            padding: 3, 
            borderRadius: 2, 
            boxShadow: 2, 
            backgroundColor: '#fff', 
            maxWidth: 400,
            margin: '20px auto' 
        }}>
            <Typography 
                variant="h6" 
                gutterBottom 
                sx={{ 
                    fontFamily: 'Montserrat', 
                    fontWeight: 600, 
                    textAlign: 'center', 
                    fontSize: '1.25rem' 
                }}>
                {translations.setPassword || 'Set Your Password'}
            </Typography>

            <Typography 
                variant="body2" 
                sx={{ 
                    fontFamily: 'Montserrat', 
                    fontWeight: 400, 
                    textAlign: 'center', 
                    mb: 1 
                }}>
                {translations.makeItEasier || 'To make your next visit easier, save your information by choosing a password.'}
            </Typography>

            <Divider sx={{ my: 1 }} />

            <form onSubmit={handleSubmit}>
                <TextField
                    label={translations.newPassword || "New Password"}
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                />
                <TextField
                    label={translations.confirmPassword || "Confirm Password"}
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                />

                {message && (
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: message.includes('success') ? 'green' : 'red', 
                            textAlign: 'center', 
                            display: 'block',
                            mt: 1 
                        }}>
                        {message}
                    </Typography>
                )}

                <Box sx={{ textAlign: 'center', mt: 1.5 }}>
                    <Button 
                        type="submit" 
                        variant="contained"
                        disabled={loading} 
                        sx={{ 
                            minWidth: 100, 
                            boxShadow: 'none',
                            backgroundColor: '#2c3e50', 
                            '&:hover': { 
                                backgroundColor: '#34495e',
                                boxShadow: 'none'
                            },
                            '&:focus': {
                                backgroundColor: '#2c3e50',
                                boxShadow: 'none'
                            }
                        }}>
                        {loading ? translations.loading || 'Loading...' : translations.submit || 'Submit'}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default SetUserPassword;
