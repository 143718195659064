import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Grid, Box, Alert } from '@mui/material';

const loadTranslations = async (language) => {
  try {
    const response = await import(`../customer-locales/${language}.json`);
    return response;
  } catch (error) {
    console.error('Failed to load translations:', error);
    const defaultResponse = await import(`../customer-locales/en.json`);
    return defaultResponse;
  }
};

function CustomerSettings() {
  const { isAuthenticated } = useAuth();
  const { lang = 'en' } = useParams();

  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    email: '',
    phoneNumber: '',
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [passwordChangeMessage, setPasswordChangeMessage] = useState(null);  // <-- New state for messages
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadTranslationsData = async () => {
      const translationsData = await loadTranslations(lang);
      setTranslations(translationsData);
    };

    const fetchUserData = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${apiBaseUrl}/api/user`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setFormData({
            firstName: data.name || '',
            surname: data.surname || '',
            email: data.email || '',
            phoneNumber: data.phone_number || '',
          });
          setLoading(false);
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.error(error);
        setError('Error loading user data');
        setLoading(false);
      }
    };

    loadTranslationsData();
    fetchUserData();
  }, [lang]);

  // Handle input change for the form fields
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle input change for password fields
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSaveDetails = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiBaseUrl}/api/user`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.firstName,
          surname: formData.surname,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User data updated successfully:', data);
      } else {
        throw new Error('Failed to update user data');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleChangePassword = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiBaseUrl}/api/update-password`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(passwordData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update password');
      }

      const data = await response.json();
      console.log('Password updated successfully:', data);

      // Set success message
      setPasswordChangeMessage({ type: 'success', text: 'Password changed successfully!' });

      // Optionally, clear the form fields
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    } catch (error) {
      console.error('Error updating password:', error.message);
      // Set error message
      setPasswordChangeMessage({ type: 'error', text: error.message });
    }
  };

  if (!isAuthenticated) {
    return <Container>{translations.loginPrompt || 'Please log in to view your account settings.'}</Container>;
  }

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return <Container>{error}</Container>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {translations.accountSettings || 'Account Settings'}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {translations.personalInformation || 'Personal Information'}
          </Typography>
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              label={translations.firstName || 'First Name'}
              name="firstName"
              value={formData.firstName}
              onChange={handleFormChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label={translations.surname || 'Surname'}
              name="surname"
              value={formData.surname}
              onChange={handleFormChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label={translations.email || 'Email'}
              name="email"
              type="email"
              value={formData.email}
              onChange={handleFormChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label={translations.phoneNumber || 'Phone Number'}
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleFormChange}
              margin="normal"
            />
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleSaveDetails}>
                {translations.savePersonalDetails || 'Save Personal Details'}
              </Button>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {translations.changePassword || 'Change Password'}
          </Typography>
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              label={translations.currentPassword || 'Current Password'}
              name="currentPassword"
              type="password"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label={translations.newPassword || 'New Password'}
              name="newPassword"
              type="password"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label={translations.confirmNewPassword || 'Confirm New Password'}
              name="confirmNewPassword"
              type="password"
              value={passwordData.confirmNewPassword}
              onChange={handlePasswordChange}
              margin="normal"
            />
            <Box mt={2}>
              <Button variant="contained" color="secondary" onClick={handleChangePassword}>
                {translations.changePassword || 'Change Password'}
              </Button>
            </Box>
          </form>

          {/* Conditionally render success or error message */}
          {passwordChangeMessage && (
            <Box mt={2}>
              <Alert severity={passwordChangeMessage.type}>
                {passwordChangeMessage.text}
              </Alert>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default CustomerSettings;
