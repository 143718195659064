import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import Cookies from 'js-cookie';
import './CookieConsent.css'; // Add CSS for styling the banner

const CookieConsent = () => {
  const [consent, setConsent] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setShowBanner(true);
    } else {
      setConsent(true);
    }
  }, []);

  const handleAccept = () => {
    setConsent(true);
    setShowBanner(false);
    localStorage.setItem('cookieConsent', 'true');
    Cookies.set('cookieConsent', 'true', { expires: 365 }); // Set a cookie for 1 year
    enableCookies(); // Trigger any analytics scripts only after consent
  };

  const enableCookies = () => {
    // Load and enable cookies like Google Analytics, Facebook Pixel, etc.
    // Example for Google Analytics
    // window.gtag('consent', 'update', {
    //   analytics_storage: 'granted',
    // });
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-consent-banner">
      <p>
        We use cookies to improve your experience. By clicking "Accept", you agree to our use of cookies.
        <Link to="/cookies" className="cookie-consent-link"> Read More</Link>
      </p>
      <button onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookieConsent;
