import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#1a2a3e',
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: '#1a2a3e',
            outline: 'none',
          },
        },
      },
    },
  },
});

const ServiceList = ({ businessIdentifier, companyName }) => {
  const { lang } = useParams();
  const [services, setServices] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState({});
  const [error, setError] = useState(null);
  const [actualBusinessUserId, setActualBusinessUserId] = useState(null); // Store the actual business ID
  const [translations, setTranslations] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    async function fetchBusinessUserId(slug) {

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await fetch(`${apiBaseUrl}/api/company_info/${slug}`);
        if (!response.ok) throw new Error('Failed to fetch business user ID');
        const data = await response.json();
        return data.id; // Assuming the endpoint returns the ID
      } catch (error) {
        console.error('Error fetching business user ID:', error);
        setError('Failed to fetch business information.');
        return null;
      }
    }

    async function fetchServices(id) {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await fetch(`${apiBaseUrl}/api/business-services/${id}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        console.log('Fetched Services:', data);

        if (data.length > 0) {
          setServices(data);

          const initialExpandedPanels = {};
          if (data.length > 0) {
            initialExpandedPanels[data[0].category] = true;
          }
          setExpandedPanels(initialExpandedPanels);
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching services:', error);
      }
    }

    async function initialize() {
      let resolvedBusinessUserId = businessIdentifier;

      // If the identifier is not a number, assume it's a slug and fetch the ID
      if (isNaN(businessIdentifier)) {
        resolvedBusinessUserId = await fetchBusinessUserId(businessIdentifier);
      }

      if (resolvedBusinessUserId) {
        setActualBusinessUserId(resolvedBusinessUserId); // Store the resolved business ID
        fetchServices(resolvedBusinessUserId);
      }
    }

    initialize();
  }, [businessIdentifier]);

  const handleBookClick = (service, serviceCategory) => () => {
    if (!actualBusinessUserId) return; // Ensure the ID is resolved before navigating


    navigate(`/${lang}/book`, {
      state: {
        service,
        businessuserUserId: actualBusinessUserId, // Pass the actual business ID here
        serviceCategory: {
          category_es: serviceCategory.category_es,
          category: serviceCategory.category,
        },
        companyName,
        currency: service.currency
      }
    });
  };

  const handleChange = (category) => (event, isExpanded) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [category]: isExpanded,
    }));
  };

  const currencySymbols = {
    EUR: '€',
    USD: '$',
    GBP: '£'
  };

  const { ourServices, book, duration, minutes, price } = translations;

    // Function to clean up the price string
    const cleanPrice = (price) => {
      // Remove 'from: ' prefix if present
      return price.startsWith('from: ') ? price.substring(6) : price;
    };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" gutterBottom>
      {ourServices || 'Our Services'}
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {services.map((serviceCategory, index) => (
        <Accordion
          key={index}
          expanded={expandedPanels[serviceCategory.category] || false}
          onChange={handleChange(serviceCategory.category)}
          sx={{
            boxShadow: 'none',
            '&:before': { display: 'none' },
            my: 1,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              bgcolor: '#cedae6',
              color: '#2c3e50',
              minHeight: '48px',
              height: '48px',
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
              '&.Mui-expanded': {
                minHeight: '48px',
              },
              '& .MuiIconButton-root': {
                padding: 0,
              },
            }}
          >
            <Typography variant="h6">
            {lang === 'es' ? serviceCategory.category_es : serviceCategory.category}
              </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            {serviceCategory.items.map((service, idx) => (
              <Grid
                key={idx}
                container
                spacing={2}
                alignItems="center"
                sx={{
                  mb: 2,
                  borderRadius: 0,
                  bgcolor: '#white',
                }}
              >
                <Grid item xs={8} sm={10} md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ fontSize: '15px' }}>{service.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                  {service.duration} {duration || 'minutes'}, {cleanPrice(service.price)} {currencySymbols[service.currency]}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={4}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disableRipple
                      sx={{
                        borderRadius: '10px',
                        color: '#fff',
                        backgroundColor: '#2c3e50',
                        boxShadow: 'none',
                        '&:hover': {
                          boxShadow: 'none',
                          backgroundColor: '#1a2a3e',
                        },
                        '&:focus': {
                          boxShadow: 'none',
                          backgroundColor: '#1a2a3e',
                          outline: 'none',
                        },
                      }}
                      onClick={handleBookClick(service, serviceCategory)}
                    >
                      {book || 'Book'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </ThemeProvider>
  );
};

export default ServiceList;
