import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Box, Button, Autocomplete, Stack, Modal, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

const SearchBarMobile = ({ open, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState(location.pathname.split('/')[1] || 'en');
  
  const [searchParams, setSearchParams] = useState({
    services: '',
    city: '',
    date: null,
  });

  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceMap, setServiceMap] = useState({});
  const [reverseServiceMap, setReverseServiceMap] = useState({});

  useEffect(() => {
    // Update the language based on the URL when the location changes
    const newLanguage = location.pathname.split('/')[1] || 'en';
    if (newLanguage !== language) {
      setLanguage(newLanguage);
    }
  }, [location.pathname, language]);

  useEffect(() => {
    const fetchServiceCategories = async () => {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await axios.get(`${apiBaseUrl}/api/servicecategories`);
        const services = response.data;
        
        // Create service options and maps
        const serviceNames = services.map(item => {
          if (language === 'es') {
            return item.ES_Name;
          } else {
            return item.name;
          }
        });
  
        setServiceOptions(serviceNames);
  
        const map = services.reduce((map, item) => {
          map[item.name] = item.id;
          if (item.ES_Name) {
            map[item.ES_Name] = item.id;
          }
          return map;
        }, {});

        setServiceMap(map);

        const reverseMap = services.reduce((map, item) => {
          map[item.id] = language === 'es' ? item.name : item.ES_Name;
          return map;
        }, {});

        setReverseServiceMap(reverseMap);
      } catch (error) {
        console.error('Error fetching service categories:', error);
      }
    };

    fetchServiceCategories();
  }, [language]);

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${language}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Error loading translations:', error);
        setTranslations((await import(`../visitor-locales/en.json`)));
      }
    };

    loadTranslations();
  }, [language]);

  const handleInputChange = (e, newValue, field) => {
    setSearchParams(prevState => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const handleDateChange = (date) => {
    setSearchParams(prevState => ({
      ...prevState,
      date: date ? format(date, 'yyyy-MM-dd') : '', // Format date to 'yyyy-MM-dd'
    }));
  };

  const handleSearch = useCallback(async () => {
    const { services, city, date } = searchParams;
    const displayCity = city || '';
    const displayService = services || '';

    // Convert the selected service name to English before sending to the server
    const categoryName = language === 'es'
      ? reverseServiceMap[serviceMap[displayService]] || displayService
      : displayService;

    console.log(`Category Name to be sent: ${categoryName}`);

    if (categoryName || displayCity || date) {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/api/search`, {
          params: {
            categoryName: categoryName,
            city: displayCity,
            date,
          },
        });

        let cityCoordinates = null;
        if (displayCity) {
          const cityResponse = await axios.get(`https://nominatim.openstreetmap.org/search?city=${displayCity}&format=json&limit=1`);
          if (cityResponse.data && cityResponse.data.length > 0) {
            const { lat, lon } = cityResponse.data[0];
            cityCoordinates = { lat: parseFloat(lat), lng: parseFloat(lon) };

            console.log(`City: ${displayCity}, Latitude: ${lat}, Longitude: ${lon}`);
          }
        }

        navigate(`/${language}/search-results`, { state: { results: response.data, searchParams: { displayCity, categoryName, date, cityCoordinates } } });
        onClose();
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }
  }, [navigate, searchParams, language, serviceMap, reverseServiceMap, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ p: 3, backgroundColor: 'white', borderRadius: '8px', maxWidth: '400px', width: '90%', m: 2 }}>
        
        <Box sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <Autocomplete
              freeSolo
              options={serviceOptions}
              value={searchParams.services}
              onChange={(event, newValue) => handleInputChange(event, newValue, 'services')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={translations.services || "Services"}
                  sx={{
                    height: '40px',
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    '& .MuiOutlinedInput-root': {
                      height: '100%',
                      borderRadius: '20px',
                      '& input': {
                        height: '100%',
                        boxSizing: 'border-box',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '& input:-webkit-autofill': {
                        backgroundColor: 'white !important',
                        color: 'black !important',
                        '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
                        boxShadow: '0 0 0px 1000px white inset !important',
                        '-webkit-text-fill-color': 'black !important',
                      },
                      '& input:-moz-autofill': {
                        backgroundColor: 'white !important',
                        color: 'black !important',
                        boxShadow: '0 0 0px 1000px white inset !important',
                      },
                    },
                  }}
                />
              )}
            />
            <TextField
              variant="outlined"
              placeholder={translations.city || "City"}
              name="city"
              value={searchParams.city}
              onChange={(e) => handleInputChange(e, e.target.value, 'city')}
              sx={{
                height: '40px',
                backgroundColor: 'white',
                borderRadius: '20px',
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                  borderRadius: '20px',
                  '& input': {
                    height: '100%',
                    padding: '10px 14px',
                    boxSizing: 'border-box',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '& input:-webkit-autofill': {
                    backgroundColor: 'white !important',
                    color: 'black !important',
                    '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
                    boxShadow: '0 0 0px 1000px white inset !important',
                    '-webkit-text-fill-color': 'black !important',
                  },
                  '& input:-moz-autofill': {
                    backgroundColor: 'white !important',
                    color: 'black !important',
                    boxShadow: '0 0 0px 1000px white inset !important',
                  },
                },
              }}
            />
            <DatePicker
              selected={searchParams.date ? new Date(searchParams.date) : null}
              onChange={handleDateChange}
              autoComplete="off"
              autoCorrect="off"
              dateFormat="yyyy-MM-dd"
              placeholderText={translations.date || "Date"}
              customInput={
                <TextField
                  variant="outlined"
                  sx={{
                    height: '40px',
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    '& .MuiOutlinedInput-root': {
                      height: '100%',
                      borderRadius: '20px',
                      '& input': {
                        height: '100%',
                        padding: '10px 14px',
                        boxSizing: 'border-box',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '& input:-webkit-autofill': {
                        backgroundColor: 'white !important',
                        color: 'black !important',
                        '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
                        boxShadow: '0 0 0px 1000px white inset !important',
                        '-webkit-text-fill-color': 'black !important',
                      },
                      '& input:-moz-autofill': {
                        backgroundColor: 'white !important',
                        color: 'black !important',
                        boxShadow: '0 0 0px 1000px white inset !important',
                      },
                    },
                  }}
                />
              }
            />
            <Button variant="contained" onClick={handleSearch}
            sx={{
              width: '100%',
              height: '40px',
              borderRadius: '20px',
              boxShadow: 'none',
              backgroundColor: '#2c3e50',
              '&:hover': {
                backgroundColor: '#2c3e50',
                boxShadow: 'none',
              },
              '&:focus': {
                backgroundColor: '#2c3e50',
              },
            }}
          >
            {translations.search || "Search"}
          </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default SearchBarMobile;
