import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { useAuth } from '../../context/AuthContext';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const Invoices = () => {
    const { user, isAuthenticated } = useAuth();
    const [billingInfo, setBillingInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation(); 

    useEffect(() => {
        if (!isAuthenticated || !user || !user.user_id) return;

        const fetchBillingInfo = async () => {
            
            try {
                const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
                const response = await fetch(`${apiBaseUrl}/api/billing-info/${user.user_id}`);
                if (response.ok) {
                    const data = await response.json();
                    setBillingInfo(data);
                } else {
                    console.error('Failed to fetch billing info');
                }
            } catch (error) {
                console.error('Error fetching billing info:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBillingInfo();
    }, [isAuthenticated, user]);

    if (!isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ padding: 0, minHeight: '100vh', backgroundColor: 'transparent' }}>
                <Typography variant="h4" align="center" sx={{ marginTop: 4, color: '#2c3e50', fontWeight: 600 }}>
                {t('login_prompt')}
                </Typography>
            </Container>
        );
    }

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ padding: 0, width: '100%', minHeight: '100vh', backgroundColor: 'transparent' }}>
                <Typography variant="h6" align="center" sx={{ marginTop: 4, color: '#2c3e50' }}>
                {t('loadinginvoices')}
                </Typography>
            </Container>
        );
    }

    const columns = [
        { field: 'number', headerName: t('invoice_number'), width: 180 },
        { field: 'date', headerName: t('date'), width: 150 },
        {
            field: 'actions',
            headerName: t('actions'),
            width: 150,
            headerAlign: 'right', // Align header text to the right
            align: 'right', // Align cell content to the right
            renderCell: (params) => (
                <Button
                    startIcon={<DownloadIcon />}
                    variant="contained"
                    sx={{ 
                        backgroundColor: '#2c3e50', 
                        '&:hover': { backgroundColor: '#1a242f' }, 
                        borderRadius: 2, 
                        padding: '4px 8px', // Smaller padding
                        fontSize: '0.75rem', // Smaller font size
                        minWidth: 'auto' // Auto width based on content
                    }}
                    href={params.row.invoice_pdf}
                    target="_blank"
                >
                    {t('download')}
                </Button>
            ),
            flex: 1, // Allow actions to take up remaining space
        },
    ];

    const rows = billingInfo.invoices ? billingInfo.invoices.map(invoice => ({
        id: invoice.id,
        number: invoice.number,
        date: new Date(invoice.created * 1000).toLocaleDateString(),
        invoice_pdf: invoice.invoice_pdf,
    })) : [];

    return (
        <Container maxWidth="lg" sx={{ padding: 0, minHeight: '100vh', backgroundColor: 'transparent' }}>
            <Box sx={{ padding: 4, borderRadius: 2, backgroundColor: 'transparent' }}>
                <Grid container spacing={1}>
                    <Grid item>
                        <ReceiptOutlinedIcon sx={{ color: '#2c3e50', marginRight: 1 }} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h5" sx={{ color: '#2c3e50', fontWeight: 500 }}>
                        {t('invoicestitle')}
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#7f8c8d' }}>
                        {t('invoicesdescription')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: 2, height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        sx={{ border: 'none' }}
                    />
                </Box>
            </Box>
        </Container>
    );
};

export default Invoices;
