import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

function TermsOfCondition() {
  const { lang } = useParams(); // Extract lang from the URL
  const [translations, setTranslations] = useState({});

  // Load translations based on lang param
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Failed to load translations:', error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  return (
    <Box
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        padding: '20px',
        maxWidth: '800px',
        margin: 'auto',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsTitle || 'Terms and Conditions'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsIntroduction || 'Welcome to our website. These Terms and Conditions govern your use of our site and services.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsAcceptance || 'Acceptance of Terms'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsAcceptanceDescription || 'By accessing and using our website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our site.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsUsage || 'Usage of the Website'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsUsageDescription || 'You may use our website only for lawful purposes and in accordance with these Terms. You agree not to use the site in any way that could damage, disable, or impair its functionality.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsIntellectualProperty || 'Intellectual Property Rights'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsIntellectualPropertyDescription || 'All content and materials on the website, including text, graphics, logos, and images, are the property of the site owner or its licensors and are protected by intellectual property laws.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsLimitationOfLiability || 'Limitation of Liability'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsLimitationOfLiabilityDescription || 'To the fullest extent permitted by law, we shall not be liable for any damages resulting from your use of our website or services. This includes, but is not limited to, direct, indirect, incidental, and consequential damages.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsGoverningLaw || 'Governing Law'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsGoverningLawDescription || 'These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which we operate, without regard to its conflict of law principles.'}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        {translations.termsContactUs || 'Contact Us'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {translations.termsContactUsDescription || 'If you have any questions about these Terms and Conditions, please contact us at [contact information].'}
      </Typography>
    </Box>
  );
}

export default TermsOfCondition;
