import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  MenuItem,
  Typography,
  CircularProgress,
  Snackbar,
  IconButton,
  Divider,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../context/AuthContext'; // Adjust path as needed

const Container = styled(Grid)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
});

const FormPaper = styled(Paper)({
  padding: '20px',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#FFFFFF',
  maxWidth: '500px',
  width: '100%',
});

const Title = styled(Typography)({
  fontSize: '20px',
  fontWeight: '600',
  color: '#333333',
  marginBottom: '10px',
});

const Subtitle = styled(Typography)({
  fontSize: '14px',
  fontWeight: '400',
  color: '#666666',
  marginBottom: '10px',
});

const StyledButton = styled(Button)({
  width: '100%',
  padding: '10px 16px',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: '500',
  borderRadius: '8px',
  backgroundColor: '#283747', // Maintains original button color
});

const StyledTextField = styled(TextField)({
  marginBottom: '16px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

const DividerStyled = styled(Divider)({
  margin: '16px 0',
});

const AddApnt = () => {
  const { isAuthenticated, user } = useAuth(); // Access AuthContext
  const [authLoading, setAuthLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    service: '',
    date: new Date(),
    time: new Date(),
    service_type: '',
    price: '',
    service_time: '',
    employee: '',
  });

  const [services, setServices] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [filteredServiceTypes, setFilteredServiceTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const checkAuthStatus = async () => {
      setAuthLoading(true);
      // Simulate async authentication check (replace with actual logic)
      await new Promise(resolve => setTimeout(resolve, 100));
      setAuthLoading(false);
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (authLoading) return; // Prevent fetching if authentication is still loading

    if (!isAuthenticated || !user) {
      setSnackbarMessage('Please log in to schedule an appointment.');
      setSnackbarOpen(true);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const userId = user.user_id;
        console.log('Fetching data for userId:', userId);

        const appointmentResponse = await axios.get(`${baseURL}/api/appointmentData/${userId}`);
        setServices(appointmentResponse.data.services || []);
        setServiceTypes(Array.isArray(appointmentResponse.data.serviceTypes) ? appointmentResponse.data.serviceTypes : []);

        const employeeResponse = await axios.get(`${baseURL}/api/employees/${userId}`);
        setEmployees(employeeResponse.data || []);

      } catch (error) {
        console.error('Error fetching appointment data:', error.response?.data || error.message || error);
        setSnackbarMessage(`Failed to fetch data: ${error.response?.data?.message || error.message || 'Unknown error'}`);
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, user, authLoading]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };

      if (name === 'service') {
        const selectedCategoryId = parseInt(value, 10);
        const selectedService = services.find((service) => service.category_id === selectedCategoryId);
        if (selectedService) {
          const filteredTypes = Array.isArray(serviceTypes) ? serviceTypes.filter((type) => type.category_id === selectedService.category_id) : [];

          setFilteredServiceTypes(filteredTypes);
          updatedValues.service_type = ''; // Reset service type
          updatedValues.price = '';
          updatedValues.service_time = '';
        }
      }

      if (name === 'service_type') {
        const selectedServiceTypeId = parseInt(value, 10);
        const selectedServiceType = filteredServiceTypes.find((type) => type.id === selectedServiceTypeId);
        if (selectedServiceType) {
          updatedValues.price = selectedServiceType.price;
          updatedValues.service_time = selectedServiceType.duration;
        }
      }

      return updatedValues;
    });
  };

  const handleDateChange = (date) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      date: date,
    }));
  };

  const handleTimeChange = (time) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      time: time,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = 'Name is required';
    if (!formValues.service) newErrors.service = 'Service is required';
    if (!formValues.service_type) newErrors.service_type = 'Service Type is required';
    if (!formValues.employee) newErrors.employee = 'Employee is required';
    if (!formValues.date || !formValues.time) newErrors.dateTime = 'Date and Time are required';
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      // Find the selected service and service type names
      const selectedService = services.find((service) => service.category_id === parseInt(formValues.service, 10));
      const selectedServiceType = filteredServiceTypes.find((type) => type.id === parseInt(formValues.service_type, 10));

      // Prepare data for submission
      const appointmentData = {
        businessusers_users_id: user.user_id,
        customer_id: null, // Set to null explicitly if no client ID
        appointment_date: formValues.date.toISOString().split('T')[0], // Format date as yyyy-mm-dd
        appointment_time: formValues.time.toISOString().split('T')[1].substring(0, 5), // Format time as HH:mm
        name: formValues.name || null, // Set to null if not provided
        phoneNumber: formValues.phoneNumber || null, // Set to null if not provided
        email: formValues.email || null, // Set to null if not provided
        service: selectedService ? selectedService.name : '', // Use the name of the service
        service_type: selectedServiceType ? selectedServiceType.name : '', // Use the name of the service type
        price: formValues.price,
        service_time: formValues.service_time,
        employee_id: formValues.employee,
      };

      // Post the appointment data
      const response = await axios.post(`${baseURL}/api/appointments`, appointmentData, {
        withCredentials: true, // Include credentials in the request
      });
      
      // Handle response
      if (response.status === 201) {
        setSnackbarMessage('Appointment added successfully!');
        setSnackbarOpen(true);
        setFormValues({
          name: '',
          phoneNumber: '',
          email: '',
          service: '',
          date: new Date(),
          time: new Date(),
          service_type: '',
          price: '',
          service_time: '',
          employee: '',
        });
      }
    } catch (error) {
      console.error('Error creating appointment:', error.response?.data || error.message || error);
      setSnackbarMessage(`Failed to add appointment: ${error.response?.data?.message || error.message || 'Unknown error'}`);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container container>
      <Grid item>
        <FormPaper elevation={3}>
          <Title>Schedule an Appointment</Title>
          <Subtitle>Fill out the form below to book your appointment.</Subtitle>
          {authLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
              <CircularProgress color="primary" />
            </div>
          ) : loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {/* Form Fields */}
              <StyledTextField
                fullWidth
                name="name"
                label="Full Name"
                variant="outlined"
                value={formValues.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                required
              />
              <StyledTextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                value={formValues.phoneNumber}
                onChange={handleChange}
                helperText="Optional"
              />
              <StyledTextField
                fullWidth
                name="email"
                label="Email Address"
                type="email"
                variant="outlined"
                value={formValues.email}
                onChange={handleChange}
                helperText="Optional"
              />
              <StyledTextField
                select
                fullWidth
                name="service"
                label="Select Service"
                variant="outlined"
                value={formValues.service || ''}
                onChange={handleChange}
                error={!!errors.service}
                helperText={errors.service}
                required
              >
                {services.map((service) => (
                  <MenuItem key={service.category_id} value={service.category_id}>
                    {service.name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                select
                fullWidth
                name="service_type"
                label="Service Type"
                variant="outlined"
                value={formValues.service_type || ''}
                onChange={handleChange}
                error={!!errors.service_type}
                helperText={errors.service_type}
                required
              >
                {filteredServiceTypes.length > 0 ? (
    filteredServiceTypes.map((serviceType) => (
      <MenuItem key={serviceType.id} value={serviceType.id}>
        {serviceType.name}
      </MenuItem>
    ))
  ) : (
    <MenuItem disabled>No service types available</MenuItem>
  )}
              </StyledTextField>
              <StyledTextField
                select
                fullWidth
                name="employee"
                label="Select Employee"
                variant="outlined"
                value={formValues.employee || ''}
                onChange={handleChange}
                error={!!errors.employee}
                helperText={errors.employee}
                required
              >
               {employees.length > 0 ? (
    employees.map((employee) => (
      <MenuItem key={employee.id} value={employee.id}>
        {employee.name}
      </MenuItem>
    ))
  ) : (
    <MenuItem disabled>No employees available</MenuItem>
  )}
              </StyledTextField>
              <DividerStyled />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <DatePicker
                    selected={formValues.date}
                    onChange={handleDateChange}
                    dateFormat="yyyy/MM/dd"
                    customInput={<StyledTextField fullWidth label="Date" variant="outlined" />}
                    required
                    placeholderText="Select Date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    selected={formValues.time}
                    onChange={handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    customInput={<StyledTextField fullWidth label="Time" variant="outlined" />}
                    required
                    placeholderText="Select Time"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={1}>
                <Grid item xs={6}>
                  <StyledTextField
                    fullWidth
                    name="price"
                    label="Price"
                    type="number"
                    variant="outlined"
                    value={formValues.price || ''}
                    InputProps={{ readOnly: true }}
                    helperText="Auto-calculated"
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField
                    fullWidth
                    name="service_time"
                    label="Service Time (minutes)"
                    type="number"
                    variant="outlined"
                    value={formValues.service_time || ''}
                    InputProps={{ readOnly: true }}
                    helperText="Auto-calculated"
                  />
                </Grid>
              </Grid>
              <StyledButton
                type="submit"
                variant="contained"
              >
                Schedule Appointment
              </StyledButton>
            </form>
          )}
        </FormPaper>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Grid>
    </Container>
  );
};

export default AddApnt;
