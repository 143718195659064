import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CompanyInfo from '../components/CompanyInfo';
import EmployeeList from '../components/EmployeeList';
import PhotoSection from '../components/PhotoSection';
import OpeningHours from '../components/OpeningHours';
import PhotoModal from '../components/PhotoModal';
import { fetchCompanyInfo, updateCompanyInfo, createOrUpdateCompanyProfile } from '../services/companyService';
import { fetchEmployees, addEmployee, updateEmployee, deleteEmployee } from '../services/employeeService';
import { uploadPhotos, fetchCompanyPhotos, updatePhotoOrder } from '../services/photoservice'; 
import { useAuth } from '../../context/AuthContext';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next'; // Ensure correct import

const Profile = () => {
  const { t } = useTranslation(); // Use the translation hook
  const { user, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [companyData, setCompanyData] = useState({
    id: '',
    name: '',
    address: '',
    description: '',
    instagram: '',
    phone: '',
    latitude: null,
    longitude: null,
    openingHours: {
      Monday: { open: false, startTime: '', endTime: '' },
      Tuesday: { open: false, startTime: '', endTime: '' },
      Wednesday: { open: false, startTime: '', endTime: '' },
      Thursday: { open: false, startTime: '', endTime: '' },
      Friday: { open: false, startTime: '', endTime: '' },
      Saturday: { open: false, startTime: '', endTime: '' },
      Sunday: { open: false, startTime: '', endTime: '' },
    },
  });
  const [employees, setEmployees] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated || !user || !user.user_id) return;

    const fetchData = async () => {
      try {
        const companyData = await fetchCompanyInfo(user.user_id);

        // Initialize openingHours with open property
        const formattedOpeningHours = Object.keys(companyData.openingHours).reduce((acc, day) => {
          const hours = companyData.openingHours[day];
          acc[day] = {
            open: hours.startTime !== null && hours.endTime !== null,
            startTime: hours.startTime || '',
            endTime: hours.endTime || '',
          };
          return acc;
        }, {});

        setCompanyData({
          id: companyData.id,
          name: companyData.name,
          address: companyData.address,
          description: companyData.description,
          instagram: companyData.instagram,
          phone: companyData.phone,
          country: companyData.country,
          city: companyData.city,
          latitude: companyData.latitude || null,
          longitude: companyData.longitude || null,
          openingHours: formattedOpeningHours,
        });

        const employeeData = await fetchEmployees(user.user_id);
        setEmployees(employeeData);

        // Fetch photos
        const photoData = await fetchCompanyPhotos(user.user_id);
        setPhotos(photoData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, user]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = async () => {
    const updatedData = {
      name: companyData.name,
      address: companyData.address,
      description: companyData.description,
      instagram: companyData.instagram,
      phone: companyData.phone,
      country: companyData.country,
      city: companyData.city,
      latitude: companyData.latitude,
      longitude: companyData.longitude,
      openingHours: {
        Monday: {
          startTime: companyData.openingHours.Monday.startTime || null,
          endTime: companyData.openingHours.Monday.endTime || null
        },
        Tuesday: {
          startTime: companyData.openingHours.Tuesday.startTime || null,
          endTime: companyData.openingHours.Tuesday.endTime || null
        },
        Wednesday: {
          startTime: companyData.openingHours.Wednesday.startTime || null,
          endTime: companyData.openingHours.Wednesday.endTime || null
        },
        Thursday: {
          startTime: companyData.openingHours.Thursday.startTime || null,
          endTime: companyData.openingHours.Thursday.endTime || null
        },
        Friday: {
          startTime: companyData.openingHours.Friday.startTime || null,
          endTime: companyData.openingHours.Friday.endTime || null
        },
        Saturday: {
          startTime: companyData.openingHours.Saturday.startTime || null,
          endTime: companyData.openingHours.Saturday.endTime || null
        },
        Sunday: {
          startTime: companyData.openingHours.Sunday.startTime || null,
          endTime: companyData.openingHours.Sunday.endTime || null
        },
      }
    };
    console.log('Updated Data:', updatedData); // Log the data to verify

    try {
        await createOrUpdateCompanyProfile(updatedData); // POST
      setEditing(false);
    } catch (error) {
      console.error('Failed to save company data:', error.response ? error.response.data : error.message);
    }
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  const handleInputChange = (field) => (e) => {
    setCompanyData({ ...companyData, [field]: e.target.value });
  };

  const handleCoordinatesChange = useCallback(
    debounce(([latitude, longitude]) => {
      if (latitude !== null && longitude !== null) {
        setCompanyData(prevData => ({ ...prevData, latitude, longitude }));
      }
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleAddEmployee = () => {
    setEmployees([...employees, { name: '', id: null, avatar: '' }]);
  };

  const handleDeleteEmployee = async (index) => {
    const employeeToDelete = employees[index];
    try {
      if (employeeToDelete.id) {
        await deleteEmployee(employeeToDelete.id);
      }
      setEmployees(employees.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Error deleting employee:', error.response ? error.response.data : error.message);
    }
  };

  const handleEmployeeNameChange = (index) => (e) => {
    const newEmployees = [...employees];
    newEmployees[index].name = e.target.value;
    setEmployees(newEmployees);
  };

  const handleEmployeeSave = async (index) => {
    const employee = employees[index];

    if (!employee.name) {
      return;
    }

    try {
      if (employee.id) {
        await updateEmployee(employee.id, { name: employee.name });
      } else {
        const createdEmployee = await addEmployee(user.user_id, { name: employee.name });
        setEmployees(employees.map((emp, i) => (i === index ? { ...emp, id: createdEmployee.id } : emp)));
      }
    } catch (error) {
      console.error('Error saving employee:', error.response ? error.response.data : error.message);
    }
  };

  const handleEmployeeAvatarChange = (index) => (e) => {
    const newEmployees = [...employees];
    newEmployees[index].avatar = URL.createObjectURL(e.target.files[0]);
    setEmployees(newEmployees);
  };

  const handleViewPhotos = () => {
    setModalOpen(true);
  };

  const handleUploadPhotos = async (files) => {
    const fileArray = Array.from(files);
    const userId = user.user_id; // Assuming you have the user ID from context or props
  
    try {
      // Fetch existing photos
    const existingPhotos = await fetchCompanyPhotos(userId);
    
    // Check the number of existing photos
    const MAX_PHOTOS = 6;
    if (existingPhotos.length >= MAX_PHOTOS) {
      setSnackbarMessage('Photo limit reached. Cannot upload more photos.');
      setSnackbarOpen(true);
      return;
    }

      //Upload New Photos
      const newPhotos = await uploadPhotos(fileArray, userId);
      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    } catch (error) {
      console.error('Error uploading photos:', error.message);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDeletePhoto = (index) => {
    setPhotos(photos.filter((_, i) => i !== index));
  };

  const handleReorderPhotos = async (newPhotoOrder) => {
    try {
      // Assuming you have a service function to update photo order
      await updatePhotoOrder(newPhotoOrder); 
      setPhotos(newPhotoOrder);
    } catch (error) {
      console.error('Error updating photo order:', error.message);
    }
  };

  const updateOpeningHours = (newOpeningHours) => {
    setCompanyData({ ...companyData, openingHours: newOpeningHours });
  };

  const handleSaveAndClose = async () => {
    if (isSaving) return;
    setIsSaving(true);
    console.log('Saving changes...');

    try {
      console.log('Calling onReorderPhotos with:', photos);
      await handleReorderPhotos(photos);
      console.log('Save successful, closing modal');
      handleCloseModal();
    } catch (error) {
      console.error('Error while saving reorder:', error);
    } finally {
      console.log('Resetting saving state');
      setIsSaving(false);
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        {editing ? (
          <div>
            <Button
              variant="contained"
              onClick={handleSaveClick}
              disableRipple
              sx={{
                marginRight: 2,
                backgroundColor: '#2c3e50',
                '&:hover': {
                  backgroundColor: '#2c3e50',
                  opacity: 1,
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {t('save')}
            </Button>
            <Button
              variant="contained"
              onClick={handleCancelClick}
              disableRipple
              sx={{
                backgroundColor: '#2c3e50',
                '&:hover': {
                  backgroundColor: '#2c3e50',
                  opacity: 1,
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {t('cancel')}
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            onClick={handleEditClick}
            disableRipple
            sx={{
              backgroundColor: '#2c3e50',
              '&:hover': {
                backgroundColor: '#2c3e50',
                opacity: 1,
              },
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            {t('edit')}
          </Button>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <CompanyInfo
            companyData={companyData}
            editing={editing}
            onInputChange={handleInputChange}
            onCoordinatesChange={handleCoordinatesChange}
          />
          <hr style={{ margin: '20px 0' }} />
          <EmployeeList
            employees={employees}
            editing={editing}
            onAddEmployee={handleAddEmployee}
            onDeleteEmployee={handleDeleteEmployee}
            onNameChange={handleEmployeeNameChange}
            onSaveEmployee={handleEmployeeSave}
            onAvatarChange={handleEmployeeAvatarChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PhotoSection
            photos={photos}
            editing={editing}
            onViewPhotos={handleViewPhotos}
            onUploadPhotos={handleUploadPhotos}
          />
          <OpeningHours
            openingHours={companyData.openingHours}
            editing={editing}
            onUpdateOpeningHours={updateOpeningHours}
          />
        </Grid>
      </Grid>
      <PhotoModal
        photos={photos}
        modalOpen={modalOpen}
        onCloseModal={handleCloseModal}
        onDeletePhoto={handleDeletePhoto}
        onReorderPhotos={handleReorderPhotos} 
        onSaveAndClose={handleSaveAndClose}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Profile;
