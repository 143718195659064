import React, { useState, useEffect } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  ListItemSecondaryAction,
  Container,
  Button,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next'; // Ensure correct import
import { deletePhoto } from '../services/photoservice'; 

const PhotoModal = ({ photos, modalOpen, onCloseModal, onReorderPhotos, onSaveReorder, handleSaveAndClose }) => {
  const [localPhotos, setLocalPhotos] = useState(photos);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation(); // Use the translation hook

  useEffect(() => {
    setLocalPhotos(photos);
  }, [photos]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedPhotos = Array.from(localPhotos);
    const [movedPhoto] = reorderedPhotos.splice(result.source.index, 1);
    reorderedPhotos.splice(result.destination.index, 0, movedPhoto);
    setLocalPhotos(reorderedPhotos);
  };

  const handleLocalSaveAndClose = async () => {
    if (isSaving) return;

    setIsSaving(true);
    console.log('Saving changes...');

    try {
      if (onReorderPhotos) {
        console.log('Calling onReorderPhotos with:', localPhotos);
        await onReorderPhotos(localPhotos);
      }
      if (onSaveReorder) {
        console.log('Calling onSaveReorder');
        await onSaveReorder();
      }
      console.log('Save successful, closing modal');
      onCloseModal();
    } catch (error) {
      console.error('Error while saving reorder:', error);
    } finally {
      console.log('Resetting saving state');
      setIsSaving(false);
    }
  };

  const handleModalClose = () => {
    console.log('Modal is closing...');
    onCloseModal();
  };

  const handleDeletePhoto = async (index) => {
    try {
      const photoUrl = localPhotos[index];
      const filename = photoUrl.split('/').pop(); // Extract filename from URL

      await deletePhoto(filename);

      // Update local state to remove the deleted photo
      const updatedPhotos = localPhotos.filter((_, i) => i !== index);
      setLocalPhotos(updatedPhotos);

      console.log('Photo deleted successfully.');
    } catch (error) {
      console.error('Error deleting photo:', error.message);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}  // Use the local handler for logging
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen}>
        <Container
          style={{
            outline: 'none',
            backgroundColor: 'white',
            padding: 20,
            maxWidth: 800,
            margin: 'auto',
            marginTop: '10vh',
            overflowY: 'auto',
            maxHeight: '80vh',
            position: 'relative',
            borderRadius: 8,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" gutterBottom >
            {t('allphotos')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLocalSaveAndClose}
              disabled={isSaving}
              sx={{
                backgroundColor: '#2c3e50',
                    '&:hover': {
                      backgroundColor: '#2c3e50',
                      opacity: 1,
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                
            >
              {isSaving ? 'Saving...' : `${t('save')}`}
            </Button>
          </Box>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="photos">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {localPhotos.length > 0 ? (
                    localPhotos.map((photoUrl, index) => (
                      <Draggable key={photoUrl} draggableId={photoUrl} index={index}>
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ListItemText>
                              <img 
                                src={photoUrl} 
                                alt={`Photo ${index}`} 
                                style={{ width: 150, height: 'auto', borderRadius: 4, marginRight: 10 }} 
                                onError={(e) => { e.target.src = 'https://placehold.co/150x150'; }} 
                              />
                            </ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePhoto(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Typography variant="body1">No photos available.</Typography>
                  )}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Container>
      </Fade>
    </Modal>
  );
};

export default PhotoModal;
