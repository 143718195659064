import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, CircularProgress } from '@mui/material';
import ConfirmedBooking from '../components/ConfirmedBooking';
import SetUserPassword from '../components/SetUserPassword';

const Confirmation = () => {
    const { lang, token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [translations, setTranslations] = useState({});
    const [bookingDetails, setBookingDetails] = useState(null);
    const [channel, setChannel] = useState(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        const loadTranslations = async () => {
            try {
                const response = await import(`../visitor-locales/${lang}.json`);
                setTranslations(response);
            } catch (error) {
                console.error("Failed to load translations:", error);
                const defaultResponse = await import(`../visitor-locales/en.json`);
                setTranslations(defaultResponse);
            }
        };
        loadTranslations();
    }, [lang]);

    useEffect(() => {
        const bc = new BroadcastChannel('booking_verification_channel');
        setChannel(bc);

        bc.onmessage = (event) => {
            if (event.data && event.data.token) {
                navigate(`/${lang}/confirmation/${event.data.token}`);
            }
        };

        return () => {
            bc.close();
        };
    }, [lang, navigate]);

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
                const response = await axios.get(`${apiBaseUrl}/api/${lang}/confirmation/${token}`);
                const bookingDetails = response.data.bookingDetails;
                const message = response.data.message;
                console.log('API response:', { bookingDetails, message });

                // Define an array of messages that should stop polling
            const completedMessages = [
                "Your booking is now completed",
                "Your booking is already completed"
            ];

            if (completedMessages.includes(message)) {
                    clearInterval(intervalRef.current);
                    setMessage(message);
                    console.log('Setting bookingDetails:', bookingDetails);
                    setBookingDetails(bookingDetails);
                } else if (message === "Token is invalid or has already been processed") {
                    setMessage('Your booking has already been processed or is invalid.');
                    setBookingDetails(bookingDetails || null);
                } else {
                    setMessage(message || 'Your booking is still in process.');
                    setBookingDetails(bookingDetails);
                }

                setLoading(false);

                if (channel) {
                    channel.postMessage({ token });
                }
            } catch (error) {
                console.error('Error during email verification:', error);
                setError('Email verification failed.');
                setLoading(false);
            }
        };

        if (token && token !== 'undefined') {
            console.log('Starting interval for fetchBookingDetails');
            intervalRef.current = setInterval(fetchBookingDetails, 5000);
            return () => {
                console.log('Clearing interval');
                clearInterval(intervalRef.current);
            };
        } else {
            setMessage('Click the link in your email to verify your appointment.');
            setLoading(false);
        }
    }, [lang, token, channel]);

    if (error) {
        return (
            <Box sx={{ padding: 3, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    {translations.confirmation || 'Confirmation'}
                </Typography>
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (loading) {
        return (
            <Box sx={{ padding: 3, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    {translations.confirmation || 'Confirmation'}
                </Typography>
                <CircularProgress />
                <Typography variant="body1">
                    Loading...
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            padding: { xs: 2, sm: 3 },
            textAlign: 'center',
            maxWidth: '500px',
            margin: '0 auto'
        }}>
            <Typography variant="h4" gutterBottom>
                {translations.confirmation || 'Confirmation'}
            </Typography>
            <Typography variant="body1">
                {message}
            </Typography>
            {bookingDetails && (
                <Box sx={{ flexDirection: 'column', gap: 3 }}>
                    <ConfirmedBooking bookingDetails={bookingDetails} translations={translations} />
                    <SetUserPassword translations={translations} />
                </Box>
            )}
        </Box>
    );
};

export default Confirmation;
