import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, CardActionArea, Chip, Divider } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import WomanImg from '../photos/woman.jpg';
import MapContainer from './MapContainer'; // Assuming you have this component


// Utility functions
const getDayName = (dateString, lang) => {
  const date = new Date(dateString);
  const options = { weekday: 'long' };
  return new Intl.DateTimeFormat(lang, options).format(date);
};

const isToday = (dateString) => {
  const today = new Date();
  const date = new Date(dateString);
  return today.toDateString() === date.toDateString();
};

const getNextAvailableTime = (availableSlots, currentTime) => {
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  for (const slot of availableSlots) {
    const [hour, minute] = slot.split(':').map(Number);
    const slotTimeInMinutes = hour * 60 + minute;

    if (slotTimeInMinutes > currentTimeInMinutes) {
      return slot;
    }
  }
  return 'N/A';
};

const fetchPhotos = async (businessUserId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/photos?userId=${businessUserId}`);
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    return data.photos;
  } catch (error) {
    console.error('Error fetching photos:', error);
    return [];
  }
};

const Ads = ({ setMapMarkers, onAdHover, onAdLeave, searchParams, setResultCount, cityCoordinates, setCityCoordinates }) => {
  const [adsData, setAdsData] = useState([]);
  const [allTimeslotsNA, setAllTimeslotsNA] = useState(false);
  const [showOtherDates, setShowOtherDates] = useState(false);
  const [isCitySearch, setIsCitySearch] = useState(false);
  const [translations, setTranslations] = useState({});
  const { lang = 'en' } = useParams(); // Extract the language parameter from URL

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    const fetchAdsData = async () => {
      try {
        const hasDate = !!searchParams?.date;
        setIsCitySearch(!hasDate);

        const params = new URLSearchParams({
          categoryName: searchParams?.displayService || '',
          city: searchParams?.displayCity || '',
          date: searchParams?.date || '',
        }).toString();

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/search?${params}`);
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        const searchDate = searchParams.date;
        const currentTime = new Date();

      

        // Fetch photos for each ad
        const adsWithPhotos = await Promise.all(data.map(async (ad) => {
          const photos = await fetchPhotos(ad.businessUserId); // Fetch photos based on businessUserId
          console.log("Fetched photos for ad:", ad.id, photos);
          const averageRating = Number(ad.average_rating);
          const reviewCount = ad.review_count;
          const displayRating = reviewCount > 0
            ? `${isNaN(averageRating) ? '0.0' : averageRating.toFixed(1)} (${reviewCount} ${translations.reviews || 'reviews'})`
            : translations.no_reviews || 'No reviews yet';

          let nextAvailableTime;
          if (isToday(ad.slotsDate)) {
            nextAvailableTime = getNextAvailableTime(ad.available_slots, currentTime);
          } else {
            nextAvailableTime = ad.available_slots.length > 0 ? ad.available_slots[0] : '';
          }

          const nextAvailableDate = ad.slotsDate || '';
          const formattedDate = nextAvailableDate !== ''
            ? (isToday(nextAvailableDate) ? translations.today || 'Today' : getDayName(nextAvailableDate, lang))
            : translations.not_available || 'Not Available';


 

            return {
              id: ad.id,
              slug: ad.slug,
              title: ad.title,
              address: ad.address,
              city: ad.city,
              nextAvailableTime: nextAvailableTime,
              nextAvailableDate: formattedDate,
              rating: displayRating,
              image: photos[0] || WomanImg,
              logo: photos[0] || WomanImg,
              longitude: ad.longitude,
              latitude: ad.latitude,
              searchDate: searchDate,
              slotsDate: ad.slotsDate
            };
          }));
          

        const validMarkers = adsWithPhotos
          .filter(ad => ad.latitude && ad.longitude)
          .map(ad => ({
            id: ad.id,
            title: ad.title,
            position: [ad.latitude, ad.longitude],
            image: ad.image,
            description: ad.description,
            rating: ad.rating,
          }));

        setAdsData(adsWithPhotos);
        setMapMarkers(validMarkers);
        setResultCount(adsWithPhotos.length);

        const allNA = adsWithPhotos.every(ad => ad.nextAvailableTime === 'N/A');
        setAllTimeslotsNA(allNA);

        const hasOtherDates = adsWithPhotos.some(ad => ad.slotsDate !== searchDate);
        setShowOtherDates(hasOtherDates);

        // Ensure the map always centers on cityCoordinates if no markers are found
        if (validMarkers.length === 0 && searchParams.displayCity) {
          setMapMarkers([]);

          if (!cityCoordinates || (cityCoordinates.lat !== searchParams.cityCoordinates.lat || cityCoordinates.lng !== searchParams.cityCoordinates.lng)) {
            setCityCoordinates(searchParams.cityCoordinates || { lat: 0, lng: 0 }); // Use default if cityCoordinates is null
          }
        }

      } catch (error) {
        console.error('Error fetching ads data:', error);
      }
    };

     

    fetchAdsData();
  }, [searchParams, setMapMarkers, setResultCount, cityCoordinates, setCityCoordinates, translations, lang]);



  return (
    <Box sx={{ p: 2 }}>
      {adsData.length === 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
          {translations.no_results || 'No results found for your search.'}
          </Typography>
          {cityCoordinates && cityCoordinates.lat && cityCoordinates.lng && (
            <MapContainer
              center={cityCoordinates}
              zoom={12}
              markers={[]} // No ads data to display
            />
          )}
        </Box>
      ) : isCitySearch ? (
        adsData.map(ad => (
          <Card
            key={ad.id}
            sx={{ display: 'block', width: '100%', borderRadius: 4, boxShadow: '0px 0px 4px 1px #e4e4e4', bgcolor: 'white', marginBottom: '20px'  }}
            onMouseEnter={() => onAdHover(ad.id)}
            onMouseLeave={onAdLeave}
          >
            <CardActionArea
              component={Link}
              to={`/${lang}/places/${ad.slug}`} 
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-start'}}
            >
              <Box 
              sx={{
                display: 'flex',
                width: '140px', // Set the width to match the image width
                height: '140px', // Set the height to match the width for a square aspect ratio
                p: 2,
                alignItems: 'center', // Center align to keep image in the middle
                justifyContent: 'center', // Center content horizontally
                overflow: 'hidden'
              }}
            >
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%', // Make image fill the container width
                    height: '100%', // Make image fill the container height
                    objectFit: 'cover', // Adjust object-fit if needed (contain, cover, fill, etc.)
                    objectPosition: 'center',
                    borderRadius: 2,
                    mb: 1
                  }}
                  image={ad.image}
                  alt={ad.title}
                />
              </Box>
              <CardContent sx={{ flex: '1 1 0', p: 3, maxWidth: '600px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h5" component="div" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                    {ad.title}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  <strong>{translations.address || 'Address: '}</strong>{ad.address}, {ad.city}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  <strong>{translations.next_available_time || 'Next available time: '}</strong>
                  <Chip
                    label={ad.nextAvailableTime}
                    variant="outlined"
                    sx={{ 
                      ml: 0.5,
                      mr: 1,
                      height: '20px',
                      width: '60px',
                      borderColor: 'green',
                      color: 'green'
                    }}
                  />
                  {ad.nextAvailableDate}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  <strong>{translations.rating || 'Rating: '}</strong>{ad.rating}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                  {ad.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      ) : showOtherDates ? (
        <>
          <Typography variant="h6" sx={{ mb: 1, fontSize: '18px', fontFamily: 'Montserrat, sans-serif' }}>
          {translations.no_results_other_dates || 'No results found for your selected date. <br /> However, we have availability on the following day:'}
            <Divider sx={{ my: 1, mt: 4 }} />
          </Typography>
          {adsData.map(ad => ad.slotsDate !== ad.searchDate && (
            <Card
              key={ad.id}
              sx={{ display: 'block', width: '100%', borderRadius: 4, boxShadow: '0px 0px 4px 1px #e4e4e4', bgcolor: 'white', marginBottom: '20px' }}
              onMouseEnter={() => onAdHover(ad.id)}
              onMouseLeave={onAdLeave}
            >
              <CardActionArea
                component={Link}
                to={`/${lang}/places/${ad.slug}`} 
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-start' }}
              >
                <Box 
              sx={{
                display: 'flex',
                width: '140px', // Set the width to match the image width
                height: '140px', // Set the height to match the width for a square aspect ratio
                p: 2,
                alignItems: 'center', // Center align to keep image in the middle
                justifyContent: 'center', // Center content horizontally
                overflow: 'hidden'
              }}
            >
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%', // Make image fill the container width
                    height: '100%', // Make image fill the container height
                    objectFit: 'cover', // Adjust object-fit if needed (contain, cover, fill, etc.)
                    objectPosition: 'center',
                    borderRadius: 2,
                    mb: 1
                  }}
                  image={ad.image}
                  alt={ad.title}
                />
              </Box>
                <CardContent sx={{ flex: '1 1 0', p: 3, maxWidth: '600px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="h5" component="div" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                      {ad.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                    <strong>{translations.address || 'Address: '}</strong>{ad.address}, {ad.city}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                    <strong>{translations.next_available_time || 'Next available time: '}</strong>
                    <Chip
                      label={ad.nextAvailableTime}
                      variant="outlined"
                      sx={{ 
                        ml: 0.5,
                        mr: 1,
                        height: '20px',
                        width: '60px',
                        borderColor: 'green',
                        color: 'green'
                      }}
                    />
                    {ad.nextAvailableDate}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                    <strong>{translations.rating || 'Rating: '}</strong>{ad.rating}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                    {ad.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </>
      ) : (
        adsData.map(ad => (
          <Card
            key={ad.id}
            sx={{ display: 'block', width: '100%', borderRadius: 4, boxShadow: '0px 0px 4px 1px #e4e4e4', bgcolor: 'white', marginBottom: '20px' }}
            onMouseEnter={() => onAdHover(ad.id)}
            onMouseLeave={onAdLeave}
          >
            <CardActionArea
              component={Link}
              to={`/${lang}/places/${ad.slug}`} 
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-start' }}
            >
              <Box 
              sx={{
                display: 'flex',
                width: '140px', // Set the width to match the image width
                height: '140px', // Set the height to match the width for a square aspect ratio
                p: 2,
                alignItems: 'center', // Center align to keep image in the middle
                justifyContent: 'center', // Center content horizontally
                overflow: 'hidden'
              }}
            >
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%', // Make image fill the container width
                    height: '100%', // Make image fill the container height
                    objectFit: 'cover', // Adjust object-fit if needed (contain, cover, fill, etc.)
                    objectPosition: 'center',
                    borderRadius: 2,
                    mb: 1
                  }}
                  image={ad.image}
                  alt={ad.title}
                />
              </Box>
              <CardContent sx={{ flex: '1 1 0', p: 3, maxWidth: '600px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h5" component="div" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                    {ad.title}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  <strong>{translations.address || 'Address: '}</strong>{ad.address}, {ad.city}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  <strong>{translations.next_available_time || 'Next available time: '}</strong>
                  <Chip
                    label={ad.nextAvailableTime}
                    variant="outlined"
                    sx={{ 
                      ml: 0.5,
                      mr: 1,
                      height: '20px',
                      width: '60px',
                      borderColor: 'green',
                      color: 'green'
                    }}
                  />
                  {ad.nextAvailableDate}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontFamily: 'Montserrat, sans-serif' }}>
                  <strong>{translations.rating || 'Rating: '}</strong>{ad.rating}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                  {ad.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      )}
    </Box>
  );
};

export default Ads;
