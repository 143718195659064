/**
 * Uploads photos to the server.
 * @param {FileList} files - List of files to upload.
 * @param {string} userId - User ID to associate with the photos.
 * @returns {Promise<string[]>} - Array of photo URLs.
 */
export const uploadPhotos = async (files, userId) => {
  const fileArray = Array.from(files);
  try {
  const baseURL = process.env.REACT_APP_API_BASE_URL; 
    const newPhotos = await Promise.all(
      fileArray.map(async (file) => {
        const formData = new FormData();
        formData.append('photo', file);
        formData.append('userId', userId); // Ensure userId is appended correctly

        // Ensure the URL is consistent with your API base URL
        const response = await fetch(`${baseURL}/api/upload`, { 
          method: 'POST',
          body: formData,
          credentials: 'include' // Include cookies for session if needed
        });

        if (!response.ok) {
          throw new Error(`Upload failed with status ${response.status}`);
        }

        const result = await response.json();
        return result.photoUrl;
      })
    );

    return newPhotos;
  } catch (error) {
    console.error('Error uploading photos:', error.message);
    throw error;
  }
};

/**
 * Fetches photos for a specific user.
 * @param {string} userId - User ID to fetch photos for.
 * @returns {Promise<string[]>} - Array of photo URLs.
 */
export const fetchCompanyPhotos = async (userId) => {
  try {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${baseURL}/api/photos?userId=${userId}`, {
      method: 'GET',
      credentials: 'include', // Include cookies for session if needed
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}`);
    }

    const result = await response.json();
    return result.photos; // Adjust based on your API response structure
  } catch (error) {
    console.error('Error fetching photos:', error.message);
    throw error;
  }
};


/**
 * Updates the order of photos on the server.
 * @param {string[]} photoOrder - Array of photo URLs in the new order.
 * @returns {Promise<void>}
 */
 export const updatePhotoOrder = async (photoOrder) => {
    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseURL}/api/photos/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ order: photoOrder }),
        credentials: 'include' // Include cookies for session if needed
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update photo order with status ${response.status}`);
      }
  
      // Optionally handle response if needed
      return await response.json(); 
    } catch (error) {
      console.error('Error updating photo order:', error.message);
      throw error;
    }
  };

  /**
 * Deletes a photo from the server.
 * @param {string} filename - The name of the photo file to delete.
 * @returns {Promise<void>}
 */
export const deletePhoto = async (filename) => {
  try {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${baseURL}/api/delete/${filename}`, {
      method: 'DELETE',
      credentials: 'include', // Include cookies for session if needed
    });

    if (!response.ok) {
      throw new Error(`Failed to delete photo with status ${response.status}`);
    }

    // Optionally handle response if needed
  } catch (error) {
    console.error('Error deleting photo:', error.message);
    throw error;
  }
};