import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Divider } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Ensure correct import
import i18next from 'i18next';

// Utility function to get time remaining
const getTimeRemaining = (appointmentDateTime) => {
  const now = new Date();
  const appointmentDate = new Date(appointmentDateTime);

  const localNow = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
  const localAppointmentDate = new Date(appointmentDate.toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));

  const diff = localAppointmentDate - localNow;

  if (diff <= 0) {
    return "Past Appointment";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60)) / (1000 * 60));

  const timeString = localAppointmentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const appointmentDateOnly = localAppointmentDate.toDateString();
  const nowDateOnly = localNow.toDateString();

  if (appointmentDateOnly === nowDateOnly) {
    return `Today, ${timeString}`;
  }

  const tomorrowDateOnly = new Date(nowDateOnly);
  tomorrowDateOnly.setDate(tomorrowDateOnly.getDate() + 1);

  if (appointmentDateOnly === tomorrowDateOnly.toDateString()) {
    return `Tomorrow, ${timeString}`;
  }

  if (days > 1) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  return `${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''}`;
};

const AppointmentsList = ({ businessusersUserId }) => {
  const { t } = useTranslation(); // Use the translation hook
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!businessusersUserId) {
        console.error('User ID is missing.');
        setLoading(false); 
        return;
      }
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await axios.get(`${baseURL}/api/appointmentlist`, {
          params: { businessusersUserId },
          withCredentials: true // Add this line to include credentials with the request
        });
        if (Array.isArray(response.data)) {
          setAppointments(response.data);
        } else {
          console.error('Expected response data to be an array, but received:', response.data);
          setError('Unexpected data format.');
        }
        setLoading(false); 
      } catch (error) {
        console.error('Error fetching appointments:', error);
        setError('Failed to load appointments. Please try again later.');
        setLoading(false); 
      }
    };

    fetchAppointments();
  }, [businessusersUserId]);

  if (loading) {
    return <Typography variant="body1" sx={{ textAlign: 'center', fontFamily: 'Montserrat' }}>Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="body1" sx={{ textAlign: 'center', color: 'red', fontFamily: 'Montserrat' }}>{error}</Typography>;
  }

  const upcomingAppointments = appointments.filter((appointment) => {
    const appointmentDateTime = `${appointment.date}T${appointment.time}`;
    const now = new Date();
    const localNow = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
    const localAppointmentDate = new Date(new Date(appointmentDateTime).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
    return localAppointmentDate > localNow;
  });

  return (
    <Box sx={{ padding: 0 }}>
      <Paper sx={{ padding: 0, marginBottom: 2, backgroundColor: 'transparent', boxShadow: 'none', width: { xs: '100%' } }}>
        <Grid container spacing={2} sx={{ width: { xs: '100%' }}}>
          <Grid item xs={3}>
            <Typography variant="h6" sx={{ 
              fontSize: { xs: '10px', sm: '14px', md: '15px' }, 
              fontWeight: 'bold', 
              fontFamily: 'Montserrat', 
              textAlign: 'center' 
            }}>{t('name')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" sx={{ 
              fontSize: { xs: '10px', sm: '14px', md: '15px' }, 
              fontWeight: 'bold', 
              fontFamily: 'Montserrat', 
              textAlign: 'center' 
            }}>{t('price')}</Typography>
          </Grid>
          <Grid item xs={3} >
            <Typography variant="h6" sx={{ 
              fontSize: { xs: '10px', sm: '14px', md: '15px' }, 
              fontWeight: 'bold', 
              fontFamily: 'Montserrat', 
              textAlign: 'center' 
            }}>{t('time')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" sx={{ 
              fontSize: { xs: '10px', sm: '14px', md: '15px' }, 
              fontWeight: 'bold', 
              fontFamily: 'Montserrat', 
              textAlign: 'center' 
            }}>{t('service')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" sx={{ 
              fontSize: { xs: '10px', sm: '14px', md: '15px' }, 
              fontWeight: 'bold', 
              fontFamily: 'Montserrat', 
              textAlign: 'center' 
            }}>{t('employee')}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
      </Paper>

      {upcomingAppointments.length > 0 ? (
        upcomingAppointments.map((appointment, index) => {
          const appointmentDateTime = `${appointment.date}T${appointment.time}`;
          return (
            <Paper key={index} elevation={0} sx={{ padding: 2, marginBottom: 2, borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 0%), 0px 3px 3px 0px rgb(0 0 0 / 0%), 0px 1px 4px 1px rgba(0, 0, 0, 0.12)' }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontSize: { xs: '10px', sm: '14px', md: '15px' }, textAlign: 'center' }}>{appointment.Name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontSize: { xs: '10px', sm: '14px', md: '15px' }, textAlign: 'center' }}>{`$${appointment.price}`}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontSize: { xs: '10px', sm: '14px', md: '15px' }, textAlign: 'center' }}>{getTimeRemaining(appointmentDateTime)}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontSize: { xs: '10px', sm: '14px', md: '15px' }, textAlign: 'center' }}>{appointment.service}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontSize: { xs: '10px', sm: '14px', md: '15px' }, textAlign: 'center' }}>{appointment.employee_name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          );
        })
      ) : (
        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', fontFamily: 'Montserrat', fontSize: { xs: '12px', sm: '14px', md: '15px' } }}>
          No upcoming appointments
        </Typography>
      )}
    </Box>
  );
};

export default AppointmentsList;