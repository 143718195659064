import React, { useState } from 'react';
import { Chip, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchBarMobile from './SearchBarMobile'; // Import the SearchBarMobile component

const NavBarChipMobile = ({ isMapVisible, onToggleMapVisibility }) => {
  const [open, setOpen] = useState(false);

  const handleOpenSearch = () => {
    setOpen(true);
  };

  const handleCloseSearch = () => {
    setOpen(false);
  };

  return (
    <Stack spacing={1} sx={{ paddingTop: '10px', width: '100%', position: 'relative' }}>
      <Chip
        variant="outlined"
        label="Search..."
        icon={<SearchIcon sx={{ color: '#bdbdbd' }} />}
        style={{
          borderRadius: '25px',
          width: '100%',
          display: 'flex',
          height: '40px',
          justifyContent: 'flex-start',
          padding: '10px',
          boxShadow: 'none',
        }}
        sx={{
          '& .MuiChip-label': {
            paddingLeft: '8px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#bdbdbd',
          },
        }}
        onClick={handleOpenSearch} // Open search bar on click
      />
     
      
      {/* Pass open state and handleCloseSearch to SearchBarMobile */}
      <SearchBarMobile open={open} onClose={handleCloseSearch} />
    </Stack>
  );
};

export default NavBarChipMobile;
