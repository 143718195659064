import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Container, Typography, Box, Paper, CircularProgress } from '@mui/material';

function LoadingConfirmation() {
  const navigate = useNavigate();
  const { lang, token } = useParams();
  const location = useLocation();
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  // Load translations based on language
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  // Check query parameters to see if we should show the message
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');
    if (from === 'register') {
      setShowMessage(true);
    }
  }, [location.search]);

  // Verify token
  useEffect(() => {
    const verifyToken = async () => {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await fetch(`${apiBaseUrl}/api/${lang}/verify-business-email/${token}`, {
          method: 'GET',
        });
  
        console.log('Response status:', response.status);
  
        if (response.ok) {
          const data = await response.json();
          console.log('Verification successful:', data);
          navigate('/login');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Verification failed.');
          console.error('Verification failed:', errorData);
        }
      } catch (error) {
        setError('An error occurred while verifying your email.');
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (token) {
      verifyToken();
    } else {
      setError('No token provided.');
      setLoading(false);
    }
  }, [navigate, lang, token]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          padding: 2,
          boxSizing: 'border-box',
        }}
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ marginLeft: 2 }}>
          {translations.verifying || 'Verifying your email...'}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
        boxSizing: 'border-box',
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
          overflowX: 'visible',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            width: '100%',
            maxWidth: '600px',
            backgroundColor: '#ffffff73',
          }}
        >
          {showMessage ? (
            <>
              <Typography component="h1" variant="h5">
                {translations.emailSent || 'We sent you an email to verify your account.'}
              </Typography>
              <CircularProgress sx={{ mt: 2 }} />
            </>
          ) : (
            <Typography component="h1" variant="h5">
              {error ? error : translations.complete || 'Verification complete!'}
            </Typography>
          )}
        </Paper>
      </Container>
    </Box>
  );
}

export default LoadingConfirmation;
