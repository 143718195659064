import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; 
import { DataGrid } from '@mui/x-data-grid';
import { Box, Container, CircularProgress, TextField, Chip, Button, Divider } from '@mui/material';
import { fetchEmployees } from '../services/employeeService'; 
import { DatePicker } from '@mui/lab';
import AppointmentModal from '../components/AppointmentModal';  
import { useTranslation } from 'react-i18next'; // Ensure correct import



// Utility functions
const getFormattedDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

const formatTime = (time) => {
  const timeParts = time.split(':');
  if (timeParts.length >= 2) {
    const [hours, minutes] = timeParts;
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }
  return 'Invalid Time';
};



const Appointments = () => {
  const { t } = useTranslation(); // Use the translation hook
  const { user, loading: authLoading } = useAuth(); // Assume `useAuth` provides `loading` state
  const userId = user?.user_id; 

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState([]);
  const [employees, setEmployees] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState('All');
  const [selectedDate, setSelectedDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const columnsTemplate = [
    { field: 'id', headerName: 'Id#', headerClassName: 'custom-header' },
    { field: 'name', headerName: t('name'), headerClassName: 'custom-header' },
    { field: 'phoneNumber', headerName: t('phone'), headerClassName: 'custom-header' },
    { field: 'email', headerName: t('email'), headerClassName: 'custom-header' },
    { field: 'service', headerName: t('servicebooked'), headerClassName: 'custom-header' },
    { field: 'employee_name', headerName: t('employee'), headerClassName: 'custom-header' },
    { field: 'service_type', headerName: t('servicetype'), headerClassName: 'custom-header' },
    { field: 'price', headerName: t('price'), headerClassName: 'custom-header' },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: 'custom-header',
      renderCell: (params) => {
        let chipProps = {};
        if (params.value === 'Canceled') {
          chipProps = { label: t('canceled'), style: { borderColor: '#f44336', color: '#f44336', borderRadius: '10px' }, variant: 'outlined' };
        } else if (params.value === 'Booked') {
          chipProps = { label: t('booked'), style: { borderColor: '#2196f3', color: '#2196f3', borderRadius: '10px' }, variant: 'outlined' };
        } else if (params.value === 'Completed') {
          chipProps = { label: 'Completed', style: { borderColor: '#4caf50', color: '#4caf50', borderRadius: '10px' }, variant: 'outlined' };
        } else {
          return params.value;
        }
        return <Chip {...chipProps} />;
      },
    },
    {
      field: 'date',
      headerName: t('date'),
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const date = new Date(params.value);
        return <>{getFormattedDate(date)}</>;
      },
    },
    {
      field: 'time',
      headerName: t('time'),
      headerClassName: 'custom-header',
      renderCell: (params) => {
        return <>{formatTime(params.value)}</>;
      },
    },
  ];

  useEffect(() => {
    const fetchAppointmentsAndEmployees = async () => {
      if (!userId) {
        console.error('userId is not defined');
        return;  // Early return if `userId` is undefined
      }
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  
      try {
        // Fetch appointments
        const response = await fetch(`${apiBaseUrl}/api/business/appointments`, {
          method: 'GET',
          credentials: 'include',
        });
  
        if (!response.ok) {
          throw new Error(`Error fetching appointments: ${response.statusText}`);
        }
  
        const data = await response.json();
  
        // Log the data to check its format
        console.log('Appointments data:', data);
  
        let appointmentsData = [];
  
        // Check if `data` is an array, a single object, or an unexpected format
        if (Array.isArray(data)) {
          appointmentsData = data;
        } else if (data && typeof data === 'object' && !Array.isArray(data)) {
          // If the object itself is a single appointment, wrap it in an array
          appointmentsData = [data];
        } else {
          console.error('Unexpected data format:', data);
          throw new Error('Appointments data is neither an array nor a single object');
        }
  
        // Fetch employees
        const employeeList = await fetchEmployees(userId);
  
        let employeeMap = {}; // Define employeeMap here
  
        if (Array.isArray(employeeList)) {
          employeeMap = employeeList.reduce((map, employee) => {
            if (employee && employee.id && employee.name) {
              map[employee.id] = employee.name;
            }
            return map;
          }, {});
        } else if (employeeList && employeeList.id && employeeList.name) {
          // Handle single employee object
          employeeMap[employeeList.id] = employeeList.name;
        } else {
          console.error('Unexpected employeeList format:', employeeList);
        }
  
        setEmployees(employeeMap);
  
        const formattedRows = appointmentsData.map((item) => ({
          id: item.id,
          name: item.name || 'N/A',
          phoneNumber: item.phoneNumber || 'N/A',
          email: item.email || 'N/A',
          service: item.service || 'N/A',
          employee_name: employeeMap[item.employee_id] || 'Unknown',
          service_type: item.service_type || 'N/A',
          status: item.status || 'Unknown',
          date: item.date ? new Date(item.date) : new Date(), // Ensure date is properly parsed
          time: item.time || '00:00:00',
          price: item.price != null ? item.price : 0, // Ensure price is handled
        }));
  
        setAppointments(appointmentsData);
        setRows(formattedRows);
      } catch (error) {
        console.error('Error fetching appointments or employees:', error);
      } finally {
        setLoading(false);
      }
    };
  
    // Only fetch appointments if `userId` is defined and `authLoading` is false
    if (!authLoading && userId) {
      fetchAppointmentsAndEmployees();
    }
  }, [authLoading, userId]);
  
  
  
  
  
  

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const employeeOptions = [t('all'), ...new Set(Object.values(employees))];

  const filteredAppointments = rows
    .filter((appointment) => {
      const searchLower = searchValue.toLowerCase();
      return Object.values(appointment).some((value) =>
        String(value).toLowerCase().includes(searchLower)
      );
    })
    .filter((appointment) => {
      return selectedEmployee === 'All' || appointment.employee_name === selectedEmployee;
    })
    .filter((appointment) => {
      if (selectedDate) {
        const selectedDateStr = getFormattedDate(selectedDate);
        return appointment.date.toISOString().split('T')[0] === selectedDateStr;
      }
      return true;
    });

  const handleRowClick = (params) => {
    setSelectedAppointment(params.row);
    setModalOpen(true);
  };
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const handleSaveAppointment = async (updatedAppointment) => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/business/appointments/${updatedAppointment.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: updatedAppointment.status,
          date: updatedAppointment.date,
          time: updatedAppointment.time,
          service: updatedAppointment.service,
          service_type: updatedAppointment.service_type,
          price: updatedAppointment.price,
        }),
      });
  
      if (response.ok) {
        const updatedData = await response.json();
        console.log('Appointment updated:', updatedData);
  
        // Update state with new data
        const updatedRows = rows.map((row) =>
          row.id === updatedAppointment.id
            ? { ...row, ...updatedAppointment } // Spread updatedAppointment to ensure all fields are updated
            : row
        );
        setRows(updatedRows);
        setAppointments(updatedRows);
        setModalOpen(false);
      } else {
        console.error('Failed to update appointment');
        alert('Failed to update appointment');
      }
    } catch (error) {
      console.error('Error updating appointment:', error);
      alert('Error updating appointment');
    }
  };

  // Handle loading states
  if (authLoading || loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <TextField
          label={t('search')} 
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearchChange}
        />
        <div className="employee-selector">
          {employeeOptions.map(employee => (
            <Button
              key={employee}
              className={selectedEmployee === employee ? 'selected' : ''}
              onClick={() => setSelectedEmployee(employee)}
              style={{ textTransform: 'none' }}
              disableRipple
              disableFocusRipple
            >
              {employee}
            </Button>
          ))}
        </div>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '10px', gap: '20px', marginBottom: '10px' }}>
        <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          renderInput={(params) => <TextField {...params} size="small" sx={{ svg: { color: '#d3d3d3' }, borderRadius: '10px' }} />}
        />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={filteredAppointments}
            columns={columnsTemplate}
            pageSize={10}
            getRowId={(row) => row.id}
            onRowClick={handleRowClick}
          />
        </div>
      )}
      <AppointmentModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        appointment={selectedAppointment}
        onSave={handleSaveAppointment}
      />
    </Container>
  );
};

export default Appointments;
