import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Container, Paper, InputAdornment } from '@mui/material';
import backgroundImage from '../photos/vibrant.jpg';
import LockIcon from '@mui/icons-material/Lock';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token, lang } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`http://localhost:8081/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setTimeout(() => navigate(`/${lang}/login`), 2000); // Redirect to login with language parameter
      } else {
        setMessage(data.message || 'Failed to reset password');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        fontFamily: 'Montserrat, Arial, sans-serif',
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
          overflowX: 'visible',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '90vh',
            overflowY: 'auto',
            boxSizing: 'border-box',
            width: '100%',
            backgroundColor: '#ffffff73',
            fontFamily: 'Montserrat, Arial, sans-serif',
          }}
        >
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, color: '#2c3d50', textAlign: 'center' }}>
            Enter your new password and confirm it to reset your password.
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: '#ffffff73',
              p: 2,
              borderRadius: '20px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label="New Password"
              name="newPassword"
              type="password"
              autoComplete="new-password"
              autoFocus
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#2c3d50',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#adadad',
                  },
                  '&:hover fieldset': {
                    borderColor: '#2c3d50',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c3d50',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#2c3d50',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#2c3d50',
                },
                mb: 2,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#2c3d50',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#adadad',
                  },
                  '&:hover fieldset': {
                    borderColor: '#2c3d50',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c3d50',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#2c3d50',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#2c3d50',
                },
                mb: 2,
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#2c3d50',
                '&:hover': {
                  backgroundColor: '#2c3d50',
                },
                fontFamily: 'Montserrat, Arial, sans-serif',
              }}
              disableRipple
            >
              Reset Password
            </Button>
            {message && (
              <Typography color="textSecondary" mt={2}>
                {message}
              </Typography>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default ResetPassword;
