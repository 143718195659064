import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { format, addDays, startOfMonth, endOfMonth, eachDayOfInterval, startOfWeek, endOfWeek, eachWeekOfInterval } from 'date-fns';
import { useTranslation } from 'react-i18next'; // Ensure correct import
import { es } from 'date-fns/locale'; // Import the Spanish locale

import i18next from 'i18next';

const CustomHeader = ({ 
  onPrev, 
  onNext, 
  onToday, 
  onWeek, 
  onMonth, 
  dateColumns,
  viewType,
  setViewType,
  selectedEmployee, // Add this prop
  handleEmployeeChange, // Add this prop
  employees // Add this prop
}) => {
  const [displayDateColumns, setDisplayDateColumns] = useState([]);
  const [dateRange, setDateRange] = useState('');
  const [isTodayView, setIsTodayView] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date()); // Track the current date

  const { t, i18n } = useTranslation(); // Use the translation hook
  const currentLanguage = i18n.language;
  const locale = currentLanguage === 'es' ? es : undefined;

  useEffect(() => {
    if (isTodayView) {
      // Handle "Today" view separately
      let formatString = 'MMMM d, yyyy'; // Default format for today view

      if (viewType === 'timeGridDay') {
        formatString = 'eeee'; // Full day name for day view
      } else if (viewType === 'dayGridMonth') {
        formatString = 'MMMM d, yyyy'; // Day number for month view
      }

      setDisplayDateColumns([currentDate]);
      setDateRange(format(currentDate, formatString, { locale })); // Adjust format based on viewType
      return;
    }

    const today = currentDate;
    let newDisplayDateColumns = [];
    let newDateRange = '';

    if (viewType === 'timeGridDay') {
      newDisplayDateColumns = [today];
      newDateRange = format(today, 'MMMM d, yyyy', { locale }); // Display full weekday name
    } else if (viewType === 'timeGridWeek') {
      const startDate = dateColumns.length > 0 ? dateColumns[0] : startOfWeek(today, { weekStartsOn: 0 });
      const endDate = dateColumns.length > 0 ? dateColumns[dateColumns.length - 1] : endOfWeek(today, { weekStartsOn: 0 });
      newDisplayDateColumns = dateColumns.length > 0 ? dateColumns.slice(0, 7) : eachDayOfInterval({ start: startDate, end: endDate });
      newDateRange = formatDateRange(startDate, endDate);
    } else if (viewType === 'dayGridMonth') {
      const start = startOfMonth(today);
      const end = endOfMonth(today);
      const weeks = eachWeekOfInterval({ start, end });
      newDisplayDateColumns = weeks.flatMap(week => eachDayOfInterval({
        start: startOfWeek(week[0], { weekStartsOn: 0 }),
        end: endOfWeek(week[6], { weekStartsOn: 0 })
      }));
      newDateRange = format(start, 'MMMM yyyy', { locale }); // Display month and year
    }

    setDisplayDateColumns(newDisplayDateColumns);
    setDateRange(newDateRange);
  }, [viewType, dateColumns, isTodayView, currentDate, locale]);

  const formatDateRange = (start, end) => {
    if (!start || !end) return '';
    return `${format(start, 'MMM d', { locale })} - ${format(end, 'MMM d', { locale })}`;
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
    setIsTodayView(true); // Enable "Today" view

    let formatString = 'MMMM d, yyyy'; // Default format for today view
    if (viewType === 'timeGridDay') {
      formatString = 'eeee'; // Full day name for day view
    } else if (viewType === 'dayGridMonth') {
      formatString = 'd'; // Day number for month view
    }
 
    setDisplayDateColumns([today]);
    setDateRange(format(today, formatString, { locale })); // Adjust format based on viewType
    if (onToday) {
      onToday(); // Notify parent component if needed
    }
  };

  const handleNextClick = () => {
    if (isTodayView) {
      const nextDate = addDays(currentDate, 1);
      setCurrentDate(nextDate);
      setDisplayDateColumns([nextDate]);

      let formatString = 'MMMM d, yyyy'; // Default format for today view
      if (viewType === 'timeGridDay') {
        formatString = 'eeee'; // Full day name for day view
      } else if (viewType === 'dayGridMonth') {
        formatString = 'd'; // Day number for month view
      }

      setDateRange(format(nextDate, formatString, { locale })); // Update to correct format
    } else if (onNext) {
      onNext();
    }
  };

  const handlePrevClick = () => {
    if (isTodayView) {
      const prevDate = addDays(currentDate, -1);
      setCurrentDate(prevDate);
      setDisplayDateColumns([prevDate]);

      let formatString = 'MMMM d, yyyy'; // Default format for today view
      if (viewType === 'timeGridDay') {
        formatString = 'eeee'; // Full day name for day view
      } else if (viewType === 'dayGridMonth') {
        formatString = 'd'; // Day number for month view
      }

      setDateRange(format(prevDate, formatString, { locale })); // Update to correct format
    } else if (onPrev) {
      onPrev();
    }
  };

  const handleWeekClick = () => {
    setIsTodayView(false);
    if (onWeek) {
      onWeek();
    }
  };

  const handleMonthClick = () => {
    setIsTodayView(false);
    if (onMonth) {
      onMonth();
    }
  };

  const daysOfWeek = [
    'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'
  ]
 
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.paper',
        zIndex: 1200,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}
    >
      {/* First Row: Navigation and Date Range */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          mb: 1
        }}
      >
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>{t('selectemployee')}</InputLabel>
          <Select
            value={selectedEmployee}
            onChange={handleEmployeeChange}
            label="Select Employee"
          >
            <MenuItem value="All">{t('all')}</MenuItem>
            {employees.map(emp => (
              <MenuItem key={emp.id} value={emp.id}>{emp.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 1, backgroundColor: '#2c3e50', color: 'white', borderColor: 'transparent' }}
          onClick={handlePrevClick}
          startIcon={<ArrowBack />}
        >
          {t('back')}
        </Button>
        <Typography variant="h6" align="center" sx={{ flex: 1 }}>
          {dateRange}
        </Typography>
        <Button
          variant="outlined"
          sx={{ ml: 1, backgroundColor: '#2c3e50', color: 'white', borderColor: 'transparent' }}
          onClick={handleNextClick}
          endIcon={<ArrowForward />}
        >
          {t('next')}
        </Button>
      </Box>
      
      {/* Second Row: View Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 1,
        }}
      >
        <Button
          variant="outlined"
          sx={{ mx: 1, backgroundColor: '#2c3e50', color: 'white', borderColor: 'transparent' }}
          onClick={handleTodayClick}
        >
          {t('today')}
        </Button>
        <Button
          variant="outlined"
          sx={{ mx: 1, backgroundColor: '#2c3e50', color: 'white', borderColor: 'transparent' }}
          onClick={handleWeekClick}
        >
          {t('week')}
        </Button>
        <Button
          variant="outlined"
          sx={{ mx: 1, backgroundColor: '#2c3e50', color: 'white', borderColor: 'transparent' }}
          onClick={handleMonthClick}
        >
          {t('month')}
        </Button>
      </Box>
      
      {/* Third Row: Date Columns */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
        {/* Render day names only if in the month view and not in today view */}
        {viewType === 'dayGridMonth' && !isTodayView && (
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
            {daysOfWeek.map((day, index) => (
              <Typography 
                key={index} 
                variant="body2" 
                align="center" 
                sx={{ flex: 1, fontWeight: 'bold', textAlign: 'center' }}
              >
                {t(`days.${day}`)}
              </Typography>
            ))}
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', paddingLeft: '56px' }}>
          {displayDateColumns.map((day, index) => {
            const isValidDate = day instanceof Date && !isNaN(day);
            let displayText = 'Invalid Date';
            const capitalizeFirstLetter = (string) => {
              return string.charAt(0).toUpperCase() + string.slice(1);
            };
            

            if (isValidDate) {
              // Ensure displayText is set correctly based on viewType
              if (isTodayView) {
                let formatString = 'eeee'; // Default format for today view
                if (viewType === 'timeGridDay') {
                  formatString = 'eeee'; // Full day name for day view
                } else if (viewType === 'dayGridMonth') {
                  formatString = 'eeee'; // Day number for month view
                }
                displayText = format(day, formatString, { locale });
              } else {
                if (viewType === 'timeGridDay') {
                  displayText = format(day, 'eeee'); // Full day name for day view
                } else if (viewType === 'dayGridMonth') {
                  displayText = format(day, 'd'); // Day number for month view
                } else if (viewType === 'timeGridWeek') {
                  displayText = format(day, 'eee d/M', { locale }); // Full weekday name for week view
                  displayText = capitalizeFirstLetter(displayText); // Capitalize first letter
                }
              }
            }

            return (
                <Typography 
                key={index} 
                variant="body2" 
                align="center" 
                sx={{ 
                  flex: 1, 
                  textAlign: 'center', fontWeight: 'bold', fontSize: '16px',
                  ...(viewType === 'timeGridWeek' && { 
                    paddingLeft: '0px',
                    paddingRight: '0px'
                  })
                }}
              >
                {displayText}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomHeader;
