import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { GeoSearchControl, OpenStreetMapProvider as GeoProvider } from 'leaflet-geosearch';
import L from 'leaflet';

const defaultIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const CenterMapOnMarker = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position && position[0] !== null && position[1] !== null) {
      map.setView(position, map.getZoom());
    }
  }, [position, map]);

  return null;
};

function ProfileCoordinates({ initialPosition = [51.505, -0.09], address = '', onCoordinatesChange, editing }) {
  const [position, setPosition] = useState(initialPosition);
  const [searchAddress, setSearchAddress] = useState(address);
  const [provider] = useState(new GeoProvider());

  // Fetch coordinates based on address if not editing
  useEffect(() => {
    if (!editing && address !== searchAddress) {
      setSearchAddress(address);
    }
  }, [address, searchAddress, editing]);

  // Fetch address coordinates if address changes and not in editing mode
  useEffect(() => {
    if (!editing && searchAddress) {
      const searchAddressInMap = async () => {
        try {
          const results = await provider.search({ query: searchAddress });
          if (results && results.length > 0) {
            const { x, y } = results[0];
            if (typeof y === 'number' && typeof x === 'number') {
              setPosition([y, x]);
            } else {
              console.error('Address not found');
            }
          } else {
            console.error('No results found for address');
          }
        } catch (error) {
          console.error('Error searching address:', error);
        }
      };

      searchAddressInMap();
    }
  }, [searchAddress, provider, editing]);

  // Update parent with current position
  useEffect(() => {
    if (onCoordinatesChange) {
      onCoordinatesChange(position);
    }
  }, [position, onCoordinatesChange]);

  const handleMarkerDrag = (e) => {
    const { lat, lng } = e.target.getLatLng();
    if (lat !== undefined && lng !== undefined) {
      setPosition([lat, lng]);
    }
  };

  return (
    <div>
      <MapContainer
        center={position}
        zoom={13}
        style={{ height: '500px', width: '100%' }}
        whenCreated={(mapInstance) => {
          new GeoSearchControl({
            provider: new GeoProvider(),
            style: 'bar',
            autoClose: true,
            searchLabel: 'Enter address',
            showMarker: false, // Disable marker for search results
          }).addTo(mapInstance);
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {position && position[0] !== null && position[1] !== null && (
          <Marker
            position={position}
            icon={defaultIcon}
            draggable
            eventHandlers={{
              dragend: handleMarkerDrag,
            }}
          >
            <Popup>
              <span>
                Coordinates: {position[0] ? position[0].toFixed(5) : 'N/A'}, {position[1] ? position[1].toFixed(5) : 'N/A'}
              </span>
            </Popup>
          </Marker>
        )}

        <CenterMapOnMarker position={position} />
      </MapContainer>
    </div>
  );
}

export default ProfileCoordinates;
