// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import BusinessLayout from './Layout/BusinessLayout';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import ScrollToTop from './components/scrollToTop'; // Import ScrollToTop
import './App.css';  // Import the global CSS file

const App = () => {
  return (
    <Router>
      <AuthProvider>
      <ScrollToTop />
        <Routes>
          <Route path="/*" element={<MainLayout />} /> {/* Main layout routes */}
          <Route
            path="/business/*"
            element={
              <PrivateRoute requiredUserType="Business" element={<BusinessLayout />} />
            }
          /> {/* Protected business routes */}
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
