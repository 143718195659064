import React, { useEffect } from 'react';
import { MapContainer as LeafletMapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box, CardMedia, Typography } from '@mui/material';

// Fix for default Marker icon issue with Leaflet and React-Leaflet
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Marker icon settings
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

// Component to update the map view
const MapViewUpdater = ({ activeMarkerId, markers }) => {
  const map = useMap();

  useEffect(() => {
    if (activeMarkerId) {
      const marker = markers.find(m => m.id === activeMarkerId);
      if (marker) {
        map.setView(marker.position, map.getZoom(), { animate: true });

        // Open the popup for the active marker
        map.eachLayer(layer => {
          if (layer instanceof L.Marker) {
            if (layer.options.id === activeMarkerId) {
              layer.openPopup();
            } else {
              layer.closePopup();
            }
          }
        });
      }
    }
  }, [activeMarkerId, markers, map]);

  return null;
};

const MapContainerMobile = ({ center, markers, activeMarkerId }) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <LeafletMapContainer center={center} zoom={10} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map(marker => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={DefaultIcon}
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              }
            }}
            id={marker.id} // Ensure the marker has the correct ID
          >
            <Popup>
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                <CardMedia
                  component="img"
                  sx={{ borderRadius: 2, width: 100, height: 100 }}
                  image={marker.image}
                  alt={marker.title}
                />
                <Typography variant="h6" component="div" sx={{ mt: 1, fontFamily: 'Montserrat, sans-serif', fontWeight: '600', fontSize: '15px' }}>
                  {marker.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontFamily: 'Montserrat, sans-serif', margin: '0 0 5px 0!important' }}>
                  {marker.rating}
                </Typography>
              </Box>
            </Popup>
          </Marker>
        ))}
        <MapViewUpdater activeMarkerId={activeMarkerId} markers={markers} />
      </LeafletMapContainer>
    </div>
  );
};

export default MapContainerMobile;
