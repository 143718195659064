import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Paper, InputAdornment, Link } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import backgroundImage from '../photos/vibrant.jpg';

function RegisterBusiness() {
  const navigate = useNavigate();
  const { lang } = useParams(); // Language parameter from the URL
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError(translations.passwordMismatch || 'Passwords do not match.');
      return;
    }

    const formData = { email, password, phoneNumber, country, city, address, lang };
   // Use environment variable for API base URL
   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
 
    try {
      const response = await fetch(`${apiBaseUrl}/api/registerbusiness`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Registration successful:', data);
          // Check the URL being navigated to
    const newUrl = `/${lang}/loading-confirmation?from=register`;
    console.log(`Navigating to: ${newUrl}`);
    navigate(newUrl, { replace: true }); 

      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError(translations.errorUnexpected || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
        boxSizing: 'border-box',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        fontFamily: 'Montserrat, Arial, sans-serif',
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
          overflowX: 'visible',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            width: '100%',
            maxWidth: '600px',
            backgroundColor: '#ffffff73',
            fontFamily: 'Montserrat, Arial, sans-serif',
            boxSizing: 'border-box',
            overflow: 'hidden',
          }}
        >
          <Typography component="h1" variant="h5">
            {translations.registerBusinessTitle || 'Register Business'}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: '#ffffff73',
              p: 2,
              borderRadius: '20px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            {error && (
              <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={translations.email || 'Email'}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label={translations.phoneNumber || 'Phone Number'}
              name="phoneNumber"
              autoComplete="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="country"
              label={translations.country || 'Country'}
              name="country"
              autoComplete="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PublicIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="city"
              label={translations.city || 'City'}
              name="city"
              autoComplete="address-level2"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LocationCityIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="address"
              label={translations.address || 'Address'}
              name="address"
              autoComplete="street-address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HomeIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={translations.password || 'Password'}
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={translations.confirmPassword || 'Confirm Password'}
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              sx={textFieldStyle}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#2c3d50',
                '&:hover': {
                  backgroundColor: '#1a2533',
                },
              }}
            >
              {translations.register || 'Register'}
            </Button>
            <Link href={`/${lang}/login`} variant="body2" sx={{ color: '#2c3d50' }}>
              {translations.loginLink || 'Already have an account? Sign in'}
            </Link>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

// Style object to reduce repetition in TextField components
const textFieldStyle = {
  '& .MuiInputBase-root': {
    color: '#2c3d50',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#adadad',
    },
    '&:hover fieldset': {
      borderColor: '#2c3d50',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2c3d50',
    },
  },
  '& .MuiFormLabel-root': {
    color: '#2c3d50',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#2c3d50',
  },
  mb: 2,
};

export default RegisterBusiness;
