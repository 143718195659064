import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Container, Paper, Tabs, Tab, InputAdornment } from '@mui/material';
import backgroundImage from '../photos/vibrant.jpg';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom';

const CustomTab = (props) => (
  <Tab
    {...props}
    disableRipple
    sx={{
      minWidth: 120,
      '&.Mui-selected': {
        color: 'black',
        fontWeight: 'bold',
        p: 2,
        backgroundColor: '#ffffff73',
        borderRadius: '10px 10px 0px 0px',
      },
    }}
  />
);

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [userType, setUserType] = useState('Client');
  const [tabIndex, setTabIndex] = useState(0);

  const location = useLocation();
  const language = location.pathname.includes('/es/') ? 'ES' : 'EN'; // Determine language from URL

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setUserType(newValue === 0 ? 'Client' : 'Business');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      const response = await fetch(`${apiBaseUrl}/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, userType, language }), // Include language in request
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('A password reset link has been sent to your email.');
      } else {
        setMessage(data.message || 'Failed to send password reset email.');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        fontFamily: 'Montserrat, Arial, sans-serif',
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
          overflowX: 'visible',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '90vh',
            overflowY: 'auto',
            boxSizing: 'border-box',
            width: '100%',
            backgroundColor: '#ffffff73',
            fontFamily: 'Montserrat, Arial, sans-serif',
          }}
        >
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, color: '#2c3d50', textAlign: 'center' }}>
            Enter your email and select your account type to receive a password reset link.
          </Typography>

          {/* Tabs for selecting Client/Business */}
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              width: '100%',
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              '.MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            <CustomTab label="Client" />
            <CustomTab label="Business" />
          </Tabs>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: '#ffffff73',
              p: 2,
              borderRadius: tabIndex === 0 ? '0px 20px 10px 10px' : '20px 0px 10px 10px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#2c3d50',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#adadad',
                  },
                  '&:hover fieldset': {
                    borderColor: '#2c3d50',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c3d50',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#2c3d50',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#2c3d50',
                },
                mb: 2,
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#2c3d50',
                '&:hover': {
                  backgroundColor: '#2c3d50',
                },
                fontFamily: 'Montserrat, Arial, sans-serif',
              }}
              disableRipple
            >
              Send Reset Link
            </Button>
            {message && (
              <Typography color="textSecondary" mt={2}>
                {message}
              </Typography>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default ForgotPassword;
