import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import Navbar from '../pages/Navbar';
import NavbarSearch from '../components/NavbarSearch';
import Home from '../pages/Home';
import SearchResults from '../pages/SearchResults';
import Places from '../pages/Places';
import Login from '../pages/Login';
import Footer from '../pages/Footer';
import BookService from '../pages/BookService';
import Checkout from '../pages/Checkout';
import CustomerPortal from '../Customer/pages/CustomerPortal';
import RegisterBusiness from '../pages/RegisterBusiness';
import Confirmation from '../pages/Confirmation'; 
import LoadingConfirmation from '../pages/LoadingConfirmation';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import PrivateRoute from '../components/PrivateRoute';
import styles from './MainLayout.module.css'; 
import NotFound from '../pages/NotFound';
import CookieConsent from '../components/CookieConsent';
import Cookies from '../pages/Cookies';
import TermsOfCondition from '../pages/TermsOfCondition';



import { Box, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280
    },
  },
});

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams();

  console.log('Current language from URL:', lang); // Debug log

  // Ensure lang is either "en" or "es" before passing it to Footer
  const language = ['en', 'es'].includes(lang) ? lang : 'en';

  useEffect(() => {
    // Skip redirection for paths starting with /api or any other paths you want to exclude
    if (location.pathname.startsWith('/api') || location.pathname.startsWith('/uploads') || location.pathname.startsWith('/webhook')) {
      return;
    }
    const pathParts = location.pathname.split('/');
    const langInPath = pathParts[1];

    if (!['en', 'es'].includes(langInPath)) {

      // Extract search params from URL if present
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.toString();
    
      // Redirect to default language if not valid
      const userPreferredLanguage = 'en'; // Default or preferred language
      navigate(`/${userPreferredLanguage}${location.pathname}`, { replace: true });
    }
  }, [location.pathname, location.search, navigate]);

  const isSearchResultsPage = location.pathname.includes('/search-results');

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {isSearchResultsPage ? <NavbarSearch /> : <Navbar />}
        <Box component="main" sx={{ flex: 1, display: 'flex', flexDirection: 'column', ...(isSearchResultsPage && { paddingTop: 20 }) }}>
          <Routes>
            <Route path="/:lang" element={<Home />} />
            <Route path="/:lang/search-results" element={<SearchResults />} />
            <Route path="/:lang/places/:slug" element={<Places />} />
            <Route path="/:lang/book" element={<BookService />} />
            <Route path="/:lang/checkout" element={<Checkout />} />
            <Route path="/:lang/login" element={<Login />} />
            <Route path="/:lang/register" element={<RegisterBusiness />} />
            <Route path="/:lang/customerportal" element={<PrivateRoute element={<CustomerPortal />} requiredUserType="Client" />} />
            <Route path="/:lang/confirmation/:token" element={<Confirmation />} />
            <Route path="/:lang/confirmation" element={<Confirmation />} />
            <Route path="/:lang/loading-confirmation" element={<LoadingConfirmation />} />
            <Route path="/:lang/loading-confirmation/:token" element={<LoadingConfirmation />} />
            <Route path="/:lang/forgot-password" element={<ForgotPassword />} />
            <Route path="/:lang/reset-password/:token" element={<ResetPassword />} />
            <Route path="/:lang/cookies" element={<Cookies />} />
            <Route path="/:lang/termsofcondition" element={<TermsOfCondition />} />
            <Route path="/" element={<Navigate to="/en" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
        <CookieConsent />
        <Footer lang={language} />
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
