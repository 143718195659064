import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import defaultImage from '../photos/shopsalon.jpg'; // Default placeholder image

const CheckoutSummary = () => {
  const [translations, setTranslations] = useState({});
  const [photoUrl, setPhotoUrl] = useState(defaultImage); // Default to placeholder image
  const [employeeMap, setEmployeeMap] = useState({}); // State to map employee IDs to names
  const { lang } = useParams(); // Get the lang parameter from the URL

  // Load translations when the component mounts
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };
    loadTranslations();
  }, [lang]);

  // Retrieve booking details from session storage
  const bookingDetails = JSON.parse(sessionStorage.getItem('bookingDetails'));

  // Fetch company image URL based on businessusers_users_id
  useEffect(() => {
    const fetchCompanyImage = async () => {
      if (bookingDetails?.businessuserUserId) {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await fetch(`${apiBaseUrl}/api/photos?userId=${bookingDetails.businessuserUserId}`);
          if (!response.ok) throw new Error('Network response was not ok');
          const photoData = await response.json();
          console.log('Fetched photo data:', photoData); // Log the fetched data
          const photos = photoData.photos || [];
          setPhotoUrl(photos[0] || defaultImage); // Use the first photo or fallback to default image
        } catch (error) {
          console.error('Error fetching company image:', error);
          setPhotoUrl(defaultImage); // Fallback to default image in case of error
        }
      } else {
        console.error('No businessusers_users_id found in booking details.');
      }
    };
    fetchCompanyImage();
  }, [bookingDetails?.businessuserUserId]);

  // Fetch employees for the company and create a mapping
  useEffect(() => {
    const fetchEmployees = async () => {
      if (bookingDetails?.businessuserUserId) {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await fetch(`${apiBaseUrl}/api/employees/${bookingDetails.businessuserUserId}`);
          if (!response.ok) throw new Error('Failed to fetch employees');
          const employees = await response.json();
          const employeeMap = employees.reduce((map, employee) => {
            map[employee.id] = employee.name; // Create a map of employee ID to name
            return map;
          }, {});
          setEmployeeMap(employeeMap);
        } catch (error) {
          console.error('Error fetching employees:', error);
        }
      }
    };
    fetchEmployees();
  }, [bookingDetails?.businessuserUserId]);

  if (!bookingDetails) {
    return (
      <Box sx={{ padding: 3, borderRadius: 1, boxShadow: 2, backgroundColor: '#fff' }}>
        <Typography variant="body1" color="error" sx={{ fontFamily: 'Montserrat' }}>
          {translations.noBookingDetailsFound || 'No booking details found.'}
        </Typography>
      </Box>
    );
  }

  // Extract details from bookingDetails
  const {
    time: bookingTime,
    date: bookingDate,
    service,
    specialist,
    price: rawPrice,
    duration,
    serviceCategory,
    companyName,
    currency
  } = bookingDetails;

  // Function to extract numerical value from price string
  const extractPrice = (priceStr) => {
    const match = priceStr.match(/(\d+(\.\d+)?)/); // Matches numbers with optional decimal point
    return match ? match[0] : "0.00";
  };

  // Processed data
  const price = extractPrice(rawPrice);

  // Format the booking date
  const formattedDate = format(parseISO(bookingDate), "EEE, d MMM yyyy"); // Converts date to "Sat, 3 Aug 2024"

  // Determine the specialist's name
  const employeeName =
    specialist === 'Any'
      ? translations.any || 'Any'
      : employeeMap[specialist] || 'John Doe'; // Use the mapped name or default to 'John Doe'
  const categoryName = service || "Service Category"; // Use the service name if available

  // Currency symbols mapping
  const currencySymbols = {
    EUR: '€',
    USD: '$',
    GBP: '£'
  };

  const total = `${currencySymbols[currency] || ''}${price}`;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box
          component="img"
          src={photoUrl} // Use fetched image URL or default image
          alt="Company Logo"
          sx={{ width: 70, height: 70, borderRadius: '15px', mr: 2, objectFit: 'cover', overflow: 'hidden' }}
        />
        <Box>
          <Typography variant="body1" fontWeight="bold" sx={{ fontSize: '18px', fontFamily: 'Montserrat' }}>
            {companyName}
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            {formattedDate}, {bookingTime}
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
            {translations.specialistTranslation || 'Specialist'}: {employeeName}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="body2" gutterBottom sx={{ paddingLeft: 1, fontSize: '18px', color: '#506172', fontFamily: 'Montserrat' }}>
          {serviceCategory}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 1, marginTop: '-7px' }}>
          <Typography variant="body2" gutterBottom sx={{ fontSize: '18px', color: '#506172', fontFamily: 'Montserrat' }}>
            {categoryName}
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ fontSize: '18px', color: '#506172', fontFamily: 'Montserrat' }}>
            {price} {currency}
          </Typography>
        </Box>
        <Typography variant="body2" gutterBottom sx={{ paddingLeft: 1, marginTop: '-5px', fontSize: '14px', color: '#506172', fontFamily: 'Montserrat' }}>
          {duration} {translations.min || 'min'}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 1 }}>
        <Typography variant="body1" fontWeight="bold" gutterBottom sx={{ fontSize: '18px', fontFamily: 'Montserrat' }}>
          {translations.total || 'Total'}
        </Typography>
        <Typography variant="body1" fontWeight="bold" gutterBottom sx={{ fontSize: '18px', fontFamily: 'Montserrat', textAlign: 'end' }}>
          {total} <Typography
            component="span"
            sx={{ display: 'block', fontSize: '14px', fontFamily: 'Montserrat' }}
          >
            {translations.payOnSite || 'Pay on site'}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default CheckoutSummary;
