import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';

const StripeProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const loadStripe = async () => {
      const stripe = await import('@stripe/stripe-js');
      setStripePromise(stripe.loadStripe('pk_test_51PvLqHDit9Wj3YzQ47CLFKy2m73mz5hghkA9jhNz8UdvcCcwBc6UDq50Kg812htPO36Ekmh2yuW35CWgOYELTQrP00bwgkRMu4'));
    };
    loadStripe();
  }, []);

  if (!stripePromise) {
    return <div>Loading...</div>; // Optional loading indicator
  }

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeProvider;