import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, InputAdornment, CircularProgress, Typography } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


function InformationBar({ id }) {
  const [slug, setSlug] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); 

  useEffect(() => {
    const fetchProfileStatus = async () => {
      if (!id) return; // If no ID, do nothing
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

      try {
        setLoading(true); // Set loading state true
        const response = await axios.get(`${apiBaseUrl}/api/profile_status/${id}`);
        console.log('API Response:', response.data); 
        const { slug, subscription_status } = response.data;
        
       // Check if data is valid
       if (slug && subscription_status) {
        setSlug(slug);
        setSubscriptionStatus(subscription_status);
        
        
        // Set message based on subscription status
        switch (subscription_status) {
          case 'no_payment':
            setMessage(t('informationBar.missing_payment'));
            break;
          case 'past_due':
            setMessage(t('informationBar.past_due'));
            break;
          default:
            setMessage(''); // No message for other statuses
          }
          } else {
            throw new Error('Invalid data format');
          }
      } catch (error) {
        console.error('Error fetching company info:', error);
      } finally {
        setLoading(false); // Set loading state false
      }
    };

    fetchProfileStatus();
  }, [id]);

  const url = `citasonic.com/places/${slug}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    alert(t("informationBar.url_copied"));
  };

  // Determine the dot color and shadow based on subscription status
  const dotColor = subscriptionStatus === 'no_payment' || !slug ? '#ff0000' : '#03ff03'; // Red if no payment or no slug, green otherwise
  const dotShadow = subscriptionStatus === 'no_payment' || !slug
    ? '0 0 8px rgba(255, 0, 0, 0.8)' 
    : '0 0 8px rgba(0, 255, 0, 0.8)'; // Red or green shadow

  return (
    <Box
      sx={{
        width: 'calc(100% - 240px)',
        height: '60px',
        backgroundColor: 'transparent',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
        position: 'absolute',
        top: 0,
        left: 240,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        // Responsive design
        '@media (max-width: 1024px)': {
          width: 'calc(100% - 200px)', // Adjust for medium screens
          left: '200px',
        },
        '@media (max-width: 899px)': {
          width: '100%', // Full width for small screens
          left: 0,
        },
        '@media (max-width: 499px)': {
          width: 'auto',
        right: 0,
        padding: 0,
        left: 0,
        },
        
      }}
    >
      
      {/* Conditionally render content based on subscription status */}
      {(subscriptionStatus === 'past_due' || subscriptionStatus === 'no_payment') ? (
        <Typography sx={{ color: 'red', fontSize: '12px', width: '300px', textAlign: 'center' }}>
          {message}
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Glowing Dot */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
              width: '8px',  // Small width for the dot
              height: '8px', // Small height for the dot
              borderRadius: '50%',
              backgroundColor: dotColor,
              boxShadow: dotShadow,
              animation: 'glow 1.5s infinite',
              '@keyframes glow': {
                '0%': { boxShadow: `0 0 4px ${dotShadow}` },
                '50%': { boxShadow: `0 0 12px ${dotShadow}` },
                '100%': { boxShadow: `0 0 4px ${dotShadow}` },
              },
            }}
          />

          {/* URL TextField with Copy Icon */}
          {loading ? (
            <CircularProgress sx={{ color: 'white' }} />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <TextField
                value={url}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy} sx={{ color: 'gray' }}>
                        <CopyAll />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: '#e9e9e9',
                  borderRadius: '20px',
                  marginRight: '40px',
                  width: '300px',
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                    marginLeft: '14px',
                    padding: '8px',
                    color: '#333',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid',
                      borderColor: '#fff',
                      borderRadius: '20px',
                    },
                  },
                  '@media (max-width: 499px)': {
                    width: '200px',
                  right: 0,
                  padding: 0,
                  left: 0,
                  margin: 'auto',
                  },
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default InformationBar;
