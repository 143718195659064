import React, { useState, useEffect } from 'react';
import { Typography, TextField, Grid, Paper, MenuItem, Select } from '@mui/material';
import ProfileCoordinates from './ProfileCoordinates';
import { useTranslation } from 'react-i18next'; // Ensure correct import

const CompanyInfo = ({ companyData, editing, onInputChange, onCoordinatesChange }) => {
  const { t } = useTranslation(); // Use the translation hook
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState(companyData.country || ''); // Default to Spain
  const [description, setDescription] = useState(companyData.description || '');
  const [charCount, setCharCount] = useState(description.length);

  useEffect(() => {
    const lat = Number(companyData.latitude) || 0;
    const lng = Number(companyData.longitude) || 0;

    if (!isNaN(lat) && !isNaN(lng)) {
      setLatitude(lat.toFixed(5));
      setLongitude(lng.toFixed(5));
    }
  }, [companyData.latitude, companyData.longitude]);

  useEffect(() => {
    setCity(companyData.city || '');
    setCountry(companyData.country || '');
    setDescription(companyData.description || '');
    setCharCount(companyData.description?.length || 0);
  }, [companyData.city, companyData.country, companyData.description]);

  const handleLatitudeChange = (e) => {
    const newLat = parseFloat(e.target.value);
    if (!isNaN(newLat)) {
      setLatitude(newLat.toFixed(5));
      onCoordinatesChange([newLat, parseFloat(longitude)]);
    }
  };

  const handleLongitudeChange = (e) => {
    const newLng = parseFloat(e.target.value);
    if (!isNaN(newLng)) {
      setLongitude(newLng.toFixed(5));
      onCoordinatesChange([parseFloat(latitude), newLng]);
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    onInputChange('city')({ target: { value: e.target.value } }); // Update the parent component state
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    onInputChange('country')({ target: { value: e.target.value } }); // Update the parent component state
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= 450) {
      setDescription(newDescription);
      setCharCount(newDescription.length);
      onInputChange('description')({ target: { value: newDescription } });
    }
  };

  return (
    <div>
      {/* Company Name Field */}
      <Typography variant="h4" gutterBottom>
        {editing ? (
          <TextField
            label={t('companyname')}
            value={companyData.name}
            onChange={onInputChange('name')}
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          companyData.name
        )}
      </Typography>

      {/* Country Field */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <Select
            label={t('country')}
            value={country}
            onChange={handleCountryChange}
            fullWidth
            size="small"
            displayEmpty
            inputProps={{ 'aria-label': 'Country' }}
          >
            <MenuItem value="Spain">Spain</MenuItem>
            <MenuItem value="United Kingdom">United Kingdom</MenuItem>
            <MenuItem value="United States">United States</MenuItem>
          </Select>
        ) : (
          `${t('country')}: ${country}`
        )}
      </Typography>

      {/* City Field */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <TextField
            label={t('city')}
            value={city}
            onChange={handleCityChange}
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          `${t('city')}: ${city}`
        )}
      </Typography>

      {/* Address Field */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <TextField
            label={t('companyadress')}
            value={companyData.address}
            onChange={onInputChange('address')}
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          companyData.address
        )}
      </Typography>

      {/* Description Field */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <div>
            <TextField
              label={t('companydescription')}
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              multiline
              rows={4}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            <Typography
              variant="caption"
              color={charCount > 450 ? 'red' : 'textSecondary'}
              style={{ float: 'right', marginTop: '4px' }}
            >
              {charCount}/450 left
            </Typography>
          </div>
        ) : (
          companyData.description
        )}
      </Typography>

      {/* Instagram Field */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <TextField
            label="Instagram"
            value={companyData.instagram}
            onChange={onInputChange('instagram')}
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          `Instagram: ${companyData.instagram}`
        )}
      </Typography>

      {/* Phone Field */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <TextField
            label={t('phone')}
            value={companyData.phone}
            onChange={onInputChange('phone')}
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          `${t('phone')}: ${companyData.phone}`
        )}
      </Typography>

      {/* Latitude and Longitude Fields */}
      <Typography variant="body1" gutterBottom>
        {editing ? (
          <div>
            <TextField
              label="Latitude"
              value={latitude}
              onChange={handleLatitudeChange}
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              style={{ marginBottom: '1rem' }}
            />
            <TextField
              label="Longitude"
              value={longitude}
              onChange={handleLongitudeChange}
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              style={{ marginBottom: '1rem' }}
            />
          </div>
        ) : (
          `Coordinates: Latitude ${latitude}, Longitude ${longitude}`
        )}
      </Typography>

      {/* Add ProfileCoordinates component here */}
      {editing && (
        <Grid container spacing={2}>
        {/* Left side: ProfileCoordinates */}
        <Grid item xs={12} md={6}>
          <Paper style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '1rem', height: '500px' }}>
            <ProfileCoordinates
              initialPosition={companyData.latitude && companyData.longitude 
                ? [Number(companyData.latitude) || 51.505, Number(companyData.longitude) || -0.09] 
                : [51.505, -0.09]}
              address={`${companyData.city ? companyData.city + ', ' : ''}${companyData.address}`}
              onCoordinatesChange={(position) => {
                const [newLat, newLng] = position;
                setLatitude(newLat.toFixed(5));
                setLongitude(newLng.toFixed(5));
                onCoordinatesChange(position);
              }}
            />
          </Paper>
        </Grid>
  
        {/* Right side: Typography */}
        <Grid item xs={12} md={6} container alignItems="top">
          <Typography variant="body1" gutterBottom sx={{ width: "300px", marginTop: "10px"}}>
          {t('CorrectLocationMap')}
          </Typography>
        </Grid>
      </Grid>
      )}
    </div>
  );
};

export default CompanyInfo;
