import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const location = useLocation();

  // Extract language from URL
  const pathParts = location.pathname.split('/');
  const currentLanguage = pathParts[1] || 'en';
  const [language, setLanguage] = useState(currentLanguage);
  const [translations, setTranslations] = useState({});

  // Load the appropriate translation file based on the language
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${language}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [language]);

  // Update language when URL changes
  useEffect(() => {
    setLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2 className="logo-text">CitaSonic</h2>
          <p>{translations.footer_about_company}</p>
        </div>
        <div className="footer-section links">
          <h2>{translations.footer_marketplace}</h2>
          <ul>
          <li>
  <a href="/" onClick={(e) => { e.preventDefault(); window.location.href = "/"; }}>
    {translations.footer_home}
  </a>
</li>
            <li> <a href={`/${language}/customerportal`}>{translations.footer_customerPortal}</a></li>
            <li><a href={`/${language}/login`}>{translations.footer_clientLogin}</a></li>
            <li><a href="#">{translations.footer_articles}</a></li>
          </ul>
        </div>
        <div className="footer-section links">
          <h2>{translations.footer_business}</h2>
          <ul>
          <li>
  <a href={`https://business.citasonic.com/${language}`} target="_blank" rel="noopener noreferrer">
    {translations.footer_booking_system}
  </a>
</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} citasonic.com {translations.footer_designed_by}
      </div>
      <div className="footer-section links">
          <ul className="bottom-links">
            <li className="bottom-links-text"><a href="#">{translations.footer_privacy_policy}</a></li>
            <li className="bottom-links-text"><Link to={`/${language}/termsofcondition`}>{translations.footer_terms_conditions}</Link></li>
            <li className="bottom-links-text"><Link to={`/${language}/cookies`}>{translations.footer_cookies}</Link></li>
          </ul>
        </div>
    </footer>
  );
};

export default Footer;
