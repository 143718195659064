import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ConfirmedBooking = ({ translations }) => {
    const [bookingDetails, setBookingDetails] = useState(null);
    const [photoUrl, setPhotoUrl] = useState(null);
    const { token, lang } = useParams(); // Get the token and lang from URL parameters

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
                const response = await axios.get(`${apiBaseUrl}/api/${lang}/register/${token}`);
                setBookingDetails(response.data);

                // Fetch the photo URL using the businessusers_users_id
                if (response.data.businessusers_users_id) {
                    const photoResponse = await axios.get(`${apiBaseUrl}/api/photos?userId=${response.data.businessusers_users_id}`);
                    setPhotoUrl(photoResponse.data.photos[0]); // Assuming you want the first photo
                }

            } catch (error) {
                console.error("Failed to fetch booking details:", error);
            }
        };

        fetchBookingDetails();
    }, [token, lang]);

    if (!bookingDetails) {
        return (
            <Box sx={{ padding: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#fff', maxWidth: 400, margin: '0 auto' }}>
                <Typography variant="body1" sx={{ fontFamily: 'Montserrat', textAlign: 'center' }}>
                    {translations.loading || 'Loading...'}
                </Typography>
            </Box>
        );
    }

    // Extract and format booking details for display
    const {
        time: bookingTime = '',
        date: bookingDate = '',
        service: serviceCategory = '',
        service_type: serviceType = '',
        employee_id: specialist = '',
        price: rawPrice = '',
        service_time: duration = '',
        companyName = '',
        currency = ''
    } = bookingDetails || {};

    const extractPrice = (priceStr) => {
        const match = priceStr.match(/(\d+(\.\d+)?)/);
        return match ? match[0] : "0.00";
    };

    const price = extractPrice(rawPrice);
    const formattedDate = bookingDate ? format(parseISO(bookingDate), "EEE, d MMM yyyy") : '';

    const employeeName = specialist || '';
    const categoryName = serviceType || '';

    const currencySymbols = {
        EUR: '€',
        USD: '$',
        GBP: '£'
    };

    const total = `${currencySymbols[currency] || ''}${price}`;

    return (
        <Box sx={{ padding: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#fff', maxWidth: 400, margin: '0 auto' }}>
            <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', textAlign: 'center' }}>
                {translations.bookingConfirmation || 'Booking Confirmation'}
            </Typography>

            <Divider sx={{ my: 2 }} />

            {/* Company Logo  or photo */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box
                    component="img"
                    src={photoUrl || "https://placehold.co/300x200"} // Fallback to placeholder if no photo is available
                    alt="Company saloon image"
                    sx={{ height: 300, width: '100%', objectFit: 'cover', borderRadius: '10px', overflow: 'hidden' }}
                />
            </Box>

            {/* Booking Details */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="body1" fontWeight="bold" sx={{ fontFamily: 'Montserrat', textAlign: 'center' }}>
                    {companyName}
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#506172' }}>
                    {formattedDate} {bookingTime}
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#506172' }}>
                    {translations.specialistTranslation || 'Specialist'}: {employeeName}
                </Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Service Details */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="body1" fontWeight="bold" sx={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#506172' }}>
                    {serviceCategory}
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#506172' }}>
                    {categoryName}
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#506172' }}>
                    {duration} {translations.min || 'min'}
                </Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Price and Payment Info */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="body1" fontWeight="bold" sx={{ fontFamily: 'Montserrat' }}>
                    {translations.total || 'Total'}
                </Typography>
                <Typography variant="body1" fontWeight="bold" sx={{ fontFamily: 'Montserrat', textAlign: 'end' }}>
                    {total}
                </Typography>
            </Box>

            <Typography variant="body2" sx={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#506172' }}>
                {translations.payOnSite || 'Pay on site'}
            </Typography>
        </Box>
    );
};

export default ConfirmedBooking;
