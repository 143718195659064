import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import defaultAvatar from '../photos/defaultavatar.webp';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

const BookingDetails = ({ serviceCategory, service, onDetailsChange, currency, businessuserUserId: propBusinessUserId }) => {
  const { lang, businessuserUserId: paramBusinessUserId } = useParams();
  const [specialist, setSpecialist] = useState('Any');
  const [employees, setEmployees] = useState([]);
  const [translations, setTranslations] = useState({});

  const displayedCategory = lang === 'es' ? serviceCategory.category_es : serviceCategory.category;
  const resolvedBusinessUserId = propBusinessUserId || paramBusinessUserId;

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        
        if (resolvedBusinessUserId) {
          const response = await fetch(`${apiBaseUrl}/api/employees/${resolvedBusinessUserId}`);
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
           // Ensure data is an array
      if (Array.isArray(data)) {
        setEmployees(data);
      } else {
        console.error('Expected array but got:', data);
        setEmployees([]);
      }
    }
  } catch (error) {
    console.error('Error fetching employees:', error);
    setEmployees([]); // Ensure employees is always an array
  }
};

    fetchEmployees();
  }, [resolvedBusinessUserId]);

  useEffect(() => {
    onDetailsChange({ service, specialist });
  }, [employees, specialist, service, onDetailsChange]);

  const handleSpecialistChange = (event) => {
    const selectedSpecialistId = event.target.value;
    console.log('Selected Specialist ID:', selectedSpecialistId); // Log the selected specialist ID
    setSpecialist(selectedSpecialistId);
    onDetailsChange({ service, specialist: selectedSpecialistId });
  };

  const currencySymbols = {
    EUR: '€',
    USD: '$',
    GBP: '£'
  };

  const {
    youHaveChosen = 'You have chosen:',
    noServiceSelected = 'No service selected',
    chooseSpecialist = 'Choose specialist:',
    any = 'Any',
    specialistTranslation = 'Specialist',
    minutes = 'minutes'
  } = translations;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingTop: '40px' }}>
        <Typography variant="h6" style={{ fontSize: '16px', fontWeight: '600' }}>{youHaveChosen}</Typography>
        <Divider style={{ margin: '16px 0' }} />
        {service ? (
          <>
            <Typography variant="body1">{displayedCategory}</Typography>
            <Typography variant="body1">{service.name}</Typography>
            <Typography variant="body2">{service.duration} {minutes}, {service.price} {currencySymbols[currency]}</Typography>
          </>
        ) : (
          <Typography variant="body1">{noServiceSelected}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{chooseSpecialist}</Typography>
        <Divider style={{ margin: '16px 0' }} />
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="specialist"
            name={specialistTranslation}
            value={specialist}
            onChange={handleSpecialistChange}
          >
            <FormControlLabel
              value="Any"
              control={<Radio />}
              label={any}
            />
            {employees.map((employee) => (
              <FormControlLabel
                key={employee.id}
                value={employee.id} // Pass the specialist ID instead of name
                control={<Radio />}
                label={
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                      alt={employee.name}
                      src={employee.avatar || defaultAvatar}
                      style={{ width: 50, height: 50, borderRadius: 15, marginRight: 16 }}
                    />
                    <Box>
                      <Typography variant="body1">{employee.name}</Typography>
                      <Typography variant="body2" color="textSecondary">{employee.role || 'Specialist'}</Typography>
                    </Box>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Divider style={{ margin: '16px 0' }} />
      </Grid>
    </Grid>
  );
};

export default BookingDetails;
