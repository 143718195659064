import React from 'react';
import { Button } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ToggleMapButton = ({ onToggleMapVisibility, isMapVisible }) => {
  return (
    <Button
      variant="outlined"
      endIcon={<ArrowDropDownIcon sx={{ paddingRight: '0px', color: '#616161' }} />}
      startIcon={<MapIcon sx={{ height: '15px', paddingLeft: '0px', color: '#616161' }} />}
      onClick={onToggleMapVisibility}
      sx={{
        
        zIndex: 1000,
        width: 'min-content',
        height: '25px',
        width: '120px',
        fontSize: '10px',
        borderRadius: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxShadow: 'none',
        color: '#616161',
        borderColor: '#bdbdbd',
        '&:hover': { 
          backgroundColor: 'transparent',
          borderColor: '#bdbdbd',
        },
        '&:focus': { 
          backgroundColor: 'transparent',
          borderColor: '#bdbdbd',
        },
      }}
    >
      {isMapVisible ? 'List View' : 'Map View'}
    </Button>
  );
};

export default ToggleMapButton;
