import React, { useState, useEffect, useMemo } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import Ads from '../components/Ads';
import Adsmobile from '../components/Adsmobile';
import MapContainer from '../components/MapContainer';
import MapContainerMobile from '../components/MapContainerMobile';
import { useLocation, useParams } from 'react-router-dom';
import ToggleMapButton from '../components/ToggleMapButton';
import NavbarSearch from '../components/NavbarSearch'; 

const SearchResults = () => {
  const location = useLocation();
  const { searchParams } = location.state || {};
  const { lang = 'en' } = useParams(); // Correct usage of lang
  const [translations, setTranslations] = useState({});
  const [resultCount, setResultCount] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [cityCoordinates, setCityCoordinates] = useState(searchParams?.cityCoordinates || { lat: 0, lng: 0 });
  const [mapCenter, setMapCenter] = useState(searchParams?.cityCoordinates || { lat: 0, lng: 0 });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error('Failed to load translations:', error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };
    loadTranslations();
  }, [lang]); // Ensure lang is included in the dependency array

  useEffect(() => {
    document.body.style.overflow = isMapVisible ? 'hidden' : 'auto';
  }, [isMapVisible]);

  useEffect(() => {
    if (searchParams?.cityCoordinates && (searchParams.cityCoordinates.lat !== cityCoordinates.lat || searchParams.cityCoordinates.lng !== cityCoordinates.lng)) {
      setCityCoordinates(searchParams.cityCoordinates);
      setMapCenter(searchParams.cityCoordinates);
    }
  }, [searchParams?.cityCoordinates]);

  useEffect(() => {
    if (!searchParams) {
      console.warn('Missing searchParams. Search operation skipped.');
      return;
    }
    if (resultCount === 0 && cityCoordinates && (mapCenter.lat !== cityCoordinates.lat || mapCenter.lng !== cityCoordinates.lng)) {
      setMapCenter(cityCoordinates);
    } else if (markers.length > 0 && (mapCenter.lat !== markers[0].position.lat || mapCenter.lng !== markers[0].position.lng)) {
      setMapCenter(markers[0].position);
    }
  }, [resultCount, markers, cityCoordinates]);

  const handleAdHover = (adId) => {
    setActiveMarkerId(adId);
  };

  const handleAdLeave = () => {
    setActiveMarkerId(null);
  };

  const handleToggleMapVisibility = () => {
    console.log('Toggle button clicked');
    setIsMapVisible(prev => {
      // Scroll to the top of the page when toggling visibility
      window.scrollTo(0, 0);
      return !prev;
    });
  };

  const memoizedMapCenter = useMemo(() => mapCenter, [mapCenter]);

  useEffect(() => {
    console.log('Map visibility changed:', isMapVisible);
  }, [isMapVisible]);

  return (
    <>
      <NavbarSearch
        isMapVisible={isMapVisible}
        onToggleMapVisibility={handleToggleMapVisibility} // Correct prop name
      />
      <Container
        maxWidth="xl"
        sx={{
          marginTop: '1px',
          backgroundColor: '#f7f7f7',
          minHeight: '100vh',
          paddingTop: '0px',
          paddingBottom: '20px',
          paddingLeft: '0px',
          paddingRight: '0px!important',
          position: 'relative',
          height: '100vh',
        }}
      >
        <Grid container spacing={0} sx={{ height: '100%' }}>
          {!isMobile ? (
            <>
              <Grid item xs={12} md={6} sx={{ backgroundColor: 'transparent', padding: '10px' }}>
                <Typography variant="h6" sx={{ marginBottom: '50px' }}>
                  <span style={{ fontSize: '1.2rem', fontFamily: 'Montserrat, sans-serif', color: 'black' }}>
                    {translations.search_results?.service_in_city
                      ?.replace('{{service}}', searchParams?.displayService || translations.service || 'Service')
                      .replace('{{city}}', searchParams?.displayCity || translations.city || 'City')}
                  </span>
                  <span style={{ fontSize: '1rem', fontFamily: 'Montserrat, sans-serif', color: 'gray' }}>
                    {' * '}{translations.search_results?.results_count?.replace('{{count}}', resultCount) || `${resultCount} results`}
                  </span>
                </Typography>
                <Ads 
                  setMapMarkers={setMarkers} 
                  onAdHover={handleAdHover}
                  onAdLeave={handleAdLeave}
                  searchParams={searchParams}
                  setResultCount={setResultCount}
                  cityCoordinates={cityCoordinates}
                  setCityCoordinates={setCityCoordinates}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: 0, margin: 0 }}>
                <MapContainer 
                  center={memoizedMapCenter} 
                  markers={markers} 
                  activeMarkerId={activeMarkerId} 
                />
              </Grid>
            </>
          ) : (
            <>
              
              <Grid item xs={12} sx={{ marginTop: '0px' }}>
                {isMapVisible ? (
                  <MapContainerMobile 
                    center={memoizedMapCenter} 
                    markers={markers} 
                    activeMarkerId={activeMarkerId} 
                  />
                ) : (
                  <Adsmobile
                    setMapMarkers={setMarkers} 
                    onAdHover={handleAdHover}
                    onAdLeave={handleAdLeave}
                    searchParams={searchParams}
                    setResultCount={setResultCount}
                    cityCoordinates={cityCoordinates}
                    setCityCoordinates={setCityCoordinates}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SearchResults;
