import React, { useState, useEffect } from 'react';
import { Typography, Grid, Chip, Button, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { format, addDays, startOfWeek, endOfWeek, getISOWeek, isSameDay, isBefore } from 'date-fns';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const TimeCal = ({ businessuserUserId, service, serviceCategory, specialist, companyName, currency }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [error, setError] = useState(null);
  const [translations, setTranslations] = useState({});
  
  const navigate = useNavigate();
  const { lang } = useParams();

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  useEffect(() => {
    const fetchAvailableSlots = async () => {
      const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 }).toISOString().split('T')[0];
      const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 }).toISOString().split('T')[0];
  
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const response = await axios.get(`${apiBaseUrl}/api/available-slots`, {
          params: {
            businessuserUserId,
            startDate: startOfWeekDate,
            endDate: endOfWeekDate,
            serviceId: service ? service.id : null
          }
        });
  
        const formattedData = {};
        const openingHours = response.data.openingHours || {};
  
        for (const [date, slots] of Object.entries(response.data)) {
          if (date === 'openingHours') continue;
          formattedData[date] = [];
          for (const [employeeId, times] of Object.entries(slots)) {
            if (Array.isArray(times)) {
              formattedData[date].push(...times.map(slot => ({ time: format(new Date(`1970-01-01T${slot}`), 'HH:mm'), employeeId })));
            }
          }
        }
  
        const dayOfWeek = currentDate.getDay();
        const openTime = openingHours[dayOfWeekMapping[dayOfWeek] + '_start'] || '00:00';
        const closeTime = openingHours[dayOfWeekMapping[dayOfWeek] + '_end'] || '23:59';
        setTimeSlots(generateTimeSlots(openTime, closeTime));
  
        // Apply filtering based on selected specialist (interpreted as employee ID)
        const filteredData = {};
        for (const [date, slots] of Object.entries(formattedData)) {
          filteredData[date] = slots.filter(slot => specialist === 'Any' || slot.employeeId === specialist);
        }
  
        setAvailableSlots(filteredData);
      } catch (error) {
        console.error('Error fetching available slots:', error);
        setError('Failed to fetch available slots. Please try again later.');
      }
    };
  
    fetchAvailableSlots();
  }, [currentDate, businessuserUserId, service, specialist]);// Ensure specialist is included in the dependencies
  

  const generateTimeSlots = (start, end) => {
    const slots = [];
    let current = new Date(`1970-01-01T${start}`);
    const endTime = new Date(`1970-01-01T${end}`);

    while (current <= endTime) {
      slots.push(format(current, 'HH:mm'));
      current.setMinutes(current.getMinutes() + 30);
    }

    return slots;
  };

  const handlePrev = () => {
    setCurrentDate(prevDate => addDays(prevDate, -7));
  };

  const handleNext = () => {
    setCurrentDate(prevDate => addDays(prevDate, 7));
  };

  const getWeekDays = () => {
    const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
    return [...Array(7)].map((_, index) => addDays(startOfCurrentWeek, index));
  };

  const formatWeekInfo = () => {
    const startOfWeekFormatted = format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'dd MMM');
    const endOfWeekFormatted = format(endOfWeek(currentDate, { weekStartsOn: 1 }), 'dd MMM');
    const weekNumber = getISOWeek(currentDate);
    return {
      dates: `${startOfWeekFormatted} - ${endOfWeekFormatted}`,
      weekNumber: `${translations.week || 'Week'} ${weekNumber}`
    };
  };

  const dayOfWeekMapping = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday'
  };

  const handleSlotClick = (time, day) => {
    const bookingDetails = {
      time,
      date: format(day, 'yyyy-MM-dd'),
      service: service.name,
      specialist: specialist || "John Doe",
      price: service.price,
      duration: service.duration,
      businessuserUserId,
      serviceCategory: serviceCategory?.displayedCategory || 'No category',
      companyName,
      currency
    };
    
    sessionStorage.setItem('bookingDetails', JSON.stringify(bookingDetails));
    navigate(`/${lang}/checkout`);
  };

  return (
    <Box sx={{ p: 0, overflowX: 'auto' }}>
      <Box sx={{ maxWidth: '100%', mb: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Button onClick={handlePrev} startIcon={<ArrowBack />} sx={{
            color: '#2c3e50',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:focus': {
              outline: 'none',
            },
          }}
          disableRipple={true}
          disableFocusRipple={true}
          >
            {translations.back || 'Back'}
          </Button>
          <Typography variant="body1" align="center" sx={{ mb: 1, fontWeight: 600 }}>
            {formatWeekInfo().dates}
          </Typography>
          <Button onClick={handleNext} endIcon={<ArrowForward />} sx={{
            color: '#2c3e50',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:focus': {
              outline: 'none',
            },
          }}
          disableRipple={true}
          disableFocusRipple={true}
          >
            {translations.next || 'Next'}
          </Button>
        </Grid>
        <Typography variant="body1" align="center" sx={{ mb: 1 }}>
          {formatWeekInfo().weekNumber}
        </Typography>
        {error && <Typography color="error" align="center">{error}</Typography>}
      </Box>
      <Grid container spacing={0} sx={{ width: { xs: '100%', sm: '500px' }, overflowX: 'auto' }}>
        <Grid item xs={12} sx={{ backgroundColor: 'white', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <Grid container spacing={0}>
            {getWeekDays().map((day, index) => (
              <Grid item xs key={index} sx={{ borderRight: index < 6 ? '1px solid #ccc' : 'none', textAlign: 'center', padding: '10px 0' }}>
                <Typography variant="body1">
                  <div style={{ fontWeight: isSameDay(day, new Date()) ? 'bold' : 'normal' }}>
                    {isSameDay(day, new Date())
                    ? translations.today || 'Today'
                    : translations[dayOfWeekMapping[day.getDay()]] || format(day, 'EEE')}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>{format(day, 'dd')}</div>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
          <Grid container spacing={0} sx={{ width: { xs: 'auto', sm: '500px' }, overflowX: 'auto' }}>
            <Grid item xs={12} sx={{ overflowX: 'auto' }}>
              <Grid container spacing={0}>
                {getWeekDays().map((day, dayIndex) => {
                  const dayFormatted = format(day, 'yyyy-MM-dd');
                  const timeSlotsForDay = timeSlots
  .map(time => {
    const slotDateTime = new Date(`${dayFormatted}T${time}`);
    if (dayFormatted === format(new Date(), 'yyyy-MM-dd') && isBefore(slotDateTime, new Date())) {
      return null;
    }
    
    // Check if the slot is available for the selected specialist
    const isAvailable = availableSlots[dayFormatted] && availableSlots[dayFormatted].some(slot => slot.time === time);
    return isAvailable ? time : null;
  })
  .filter(time => time !== null);


                  return (
                    <Grid item xs key={dayIndex} sx={{ borderRight: dayIndex < 6 ? '1px solid #ccc' : 'none', textAlign: 'center', padding: '5px 0' }}>
                      <Grid container direction="column" spacing={0} sx={{ alignItems: 'center', minHeight: '400px' }}>
                        {timeSlotsForDay.map((time, timeIndex) => (
                          <Grid item key={timeIndex} sx={{ width: '100%', padding: '2px 0' }}>
                            <Chip
                              label={time}
                              variant="outlined"
                              sx={{
                                width: '90%',
                                borderRadius: '10px',
                                borderColor: 'transparent',
                                backgroundColor: '#2c3e50',
                                color: '#fff',
                                '&:hover': {
                                  backgroundColor: '#39526b!important',
                                },
                                '&:focus': {
                                  backgroundColor: '#2c3e50',
                                },
                                margin: 'auto',
                                '& .MuiChip-label': {
                                  overflow: 'hidden',
                                  fontSize: '12px',
                                  textOverflow: 'ellipsis',
                                  '@media (max-width: 600px)': {
                                    overflow: 'visible',
                                    textOverflow: 'clip',
                                  },
                                },
                              }}
                              onClick={() => handleSlotClick(time, day)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

TimeCal.propTypes = {
  businessuserUserId: PropTypes.string.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    duration: PropTypes.number
  }).isRequired,
  serviceCategory: PropTypes.shape({
    displayedCategory: PropTypes.string
  }),
  specialist: PropTypes.string,
  companyName: PropTypes.string,
  currency: PropTypes.string
};

export default TimeCal;
