import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Paper, Tabs, Tab, InputAdornment, Link } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import backgroundImage from '../photos/vibrant.jpg';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

    

const CustomTab = (props) => (
  <Tab
    {...props}
    disableRipple
    sx={{
      minWidth: 120,
      '&.Mui-selected': {
        color: 'black',
        fontWeight: 'bold',
        p: 2,
        backgroundColor: '#ffffff73',
        borderRadius: '10px 10px 0px 0px',
      },
    }}
  />
);

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const { lang } = useParams(); // Language parameter from the URL
  const [userType, setUserType] = useState('Client');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [tabIndex, setTabIndex] = useState(userType === 'Client' ? 0 : 1);
  const [clientClicked, setClientClicked] = useState(false);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await import(`../visitor-locales/${lang}.json`);
        setTranslations(response);
      } catch (error) {
        console.error("Failed to load translations:", error);
        const defaultResponse = await import(`../visitor-locales/en.json`);
        setTranslations(defaultResponse);
      }
    };

    loadTranslations();
  }, [lang]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setUserType(newValue === 0 ? 'Client' : 'Business');
    setClientClicked(false); // Reset the click state when switching tabs
  };

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
  
    if (userType === 'Business') {
      navigate(`/${lang}/register`);
    } else if (userType === 'Client') {
      setClientClicked(true); // Perform client-specific action
    } else {
      console.warn('Unhandled userType:', userType);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    const formData = { username, password, userType };


    // Use environment variable for API base URL
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    console.log('API Base URL:', apiBaseUrl); 
    
    try {
      const response = await axios.post(`${apiBaseUrl}/api/login`, formData, {
        withCredentials: true, // Ensure cookies are sent
      });

      if (response.status === 200) {
        const data = response.data;
        console.log('Login successful:', data);

        // Update authentication state via AuthContext
        login(userType);

        // Preserve the redirect path passed via state or default to customerportal
        const redirectPath = location.state?.from || `/${lang}/customerportal`;

        if (userType === 'Business') {
          navigate('/business/dashboard');
        } else {
          navigate(redirectPath);
      }
    } else {
      // Handle non-200 status codes
      setError(response.data.message || 'Login failed');
    }
  } catch (error) {
    // Improved error handling with proper response handling
    console.error('An error occurred:', error.response ? error.response.data : error.message);
    setError(error.response ? error.response.data.message || 'An unexpected error occurred. Please try again.' : 'An unexpected error occurred. Please try again.');
  }
};

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
        boxSizing: 'border-box',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        fontFamily: 'Montserrat, Arial, sans-serif',
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          margin: 'auto',
          overflowX: 'visible',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '90vh',
            overflowY: 'auto',
            boxSizing: 'border-box',
            width: '100%',
            backgroundColor: '#ffffff73',
            fontFamily: 'Montserrat, Arial, sans-serif',
          }}
        >
          <Typography component="h1" variant="h5">
          {translations.signInTitle || 'Sign In'}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, color: '#2c3d50', textAlign: 'center' }}>
            {translations.loginInstruction || 'Please select "Client" or "Business" before logging in.'}
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{
              width: '100%',
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              '.MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            <CustomTab label={translations.clientLogin || 'Client Login'} />
            <CustomTab label={translations.businessLogin || 'Business Login'} />
          </Tabs>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: '#ffffff73',
              p: 2,
              borderRadius: tabIndex === 0 ? '0px 20px 10px 10px' : '20px 0px 10px 10px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            {error && (
              <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                {translations.error || error}
              </Typography>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={translations.email || 'Email'}
              name="username"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#2c3d50',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#adadad',
                  },
                  '&:hover fieldset': {
                    borderColor: '#2c3d50',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c3d50',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#2c3d50',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#2c3d50',
                },
                mb: 2,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={translations.password || 'Password'}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#2c3d50',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#adadad',
                  },
                  '&:hover fieldset': {
                    borderColor: '#2c3d50',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c3d50',
                  },
                },
                '& .MuiFormLabel-root': {
                  color: '#2c3d50',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#2c3d50',
                },
                mb: 2,
              }}
            />
             <Box textAlign="right" mt={1}>
              <Link
                component={RouterLink}
                to={`/${lang}/forgot-password`}
                variant="body2"
                sx={{ color: '#2c3d50', textDecoration: 'underline' }}
              >
                {translations.forgotPassword || 'Forgot password?'}
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#2c3d50',
                '&:hover': {
                  backgroundColor: '#2c3d50',
                },
                fontFamily: 'Montserrat, Arial, sans-serif',
              }}
              disableRipple
            >
              {translations.signIn || 'Sign In'}
            </Button>
            <Box textAlign="center">
              {userType === 'Business' || !clientClicked ? (
                <Link
                component={RouterLink} // Use RouterLink for internal navigation
                to={userType === 'Business' ? `/${lang}/register` : "#"}
                  variant="body2"
                  sx={{ color: '#2c3d50', fontFamily: 'Montserrat, Arial, sans-serif' }}
                  onClick={handleLinkClick}
                >
                  {translations.registerLink || "Don't have an account? Register"}
                </Link>
              ) : (
                <Typography variant="body2" sx={{ color: '#2c3d50', fontFamily: 'Montserrat, Arial, sans-serif' }}>
                  {translations.bookAppointment || 'You need to book an appointment to make an account'}
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default Login;
