// src/hooks/useHubSpotChat.js
import { useEffect } from 'react';

const useHubSpotChat = () => {
    useEffect(() => {
        const scriptId = 'hs-script-loader';
        const existingScript = document.getElementById(scriptId);

        if (!existingScript) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = scriptId;
            script.async = true;
            script.defer = true;
            script.src = '//js-na1.hs-scripts.com/47634846.js'; // Your HubSpot account script

            document.body.appendChild(script);
        }

        return () => {
            const scriptToRemove = document.getElementById(scriptId);
            if (scriptToRemove) {
                document.body.removeChild(scriptToRemove);
            }
        };
    }, []);
};

export default useHubSpotChat;
