import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import TimeCal from '../components/TimeCal';
import BookingDetails from '../components/BookingDetails';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function BookService() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { service, businessuserUserId, serviceCategory, companyName, currency } = location.state || {};
  const [specialist, setSpecialist] = useState('');
  const { lang } = useParams();

  const displayedCategory = lang === 'es' ? serviceCategory?.category_es : serviceCategory?.category;

  const handleDetailsChange = (details) => {
    setSpecialist(details.specialist);
  };

  return (
    <Box sx={{ padding: { xs: '20px 0 15px 0', sm: '30px 0 20px 0' }, maxWidth: '100%', margin: '0 auto' }}>
      <Grid container spacing={3}>
        <Grid 
          item 
          xs={12} 
          md={7}
          sx={{ width: '100%', padding: { xs: 0, md: '16px' } }}
        >
          <TimeCal
            businessuserUserId={businessuserUserId} 
            service={service} 
            specialist={specialist} 
            serviceCategory={serviceCategory ? { ...serviceCategory, displayedCategory } : {}}  
            companyName={companyName} 
            currency={currency}
          />
        </Grid>
        <Grid 
          item 
          xs={12} 
          md={3}
          sx={{ width: '100%', padding: { xs: '10px 0 0 50px!important', md: '16px' } }}
        >
          <BookingDetails 
            service={service} 
            businessuserUserId={businessuserUserId} 
            serviceCategory={serviceCategory || {}} 
            onDetailsChange={handleDetailsChange}
            currency={currency}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BookService;
