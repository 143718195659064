import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as needed
import { useTranslation } from 'react-i18next'; // Ensure correct import

// Utility functions to generate full data sets for different periods
const generateWeekDays = (startDate) => {
  const days = [];
  const startOfWeek = new Date(startDate);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Start from Sunday

  for (let i = 0; i < 7; i++) {
    const currentDay = new Date(startOfWeek);
    currentDay.setDate(startOfWeek.getDate() + i);
    const shortDayName = currentDay.toLocaleDateString('en-US', { weekday: 'short' });
    days.push({
      originalDay: shortDayName,
      day: shortDayName,
      date: currentDay.toISOString().split('T')[0],
      appointments_count: 0
    });
  }

  return days;
};

const generateMonthDays = (startDate) => {
  const days = [];
  const date = new Date(startDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  for (let i = 1; i <= daysInMonth; i++) {
    days.push({
      day: i,
      date: `${year}-${String(month + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`,
      appointments_count: 0
    });
  }

  return days;
};

const generateDayHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      hour: `${String(i).padStart(2, '0')}:00`,
      appointments_count: 0
    });
  }
  return hours;
};


// Fetch and transform data from the server
const fetchData = async (period, userId, startDate, endDate) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  try {
    const response = await axios.get(`${baseURL}/api/chartdata`, {
      params: {
        period,
        businessusersUserId: userId, // Ensure userId is valid
        startDate,
        endDate,
      },
      withCredentials: true
    });
    // If the API doesn't return an array, fallback to an empty array
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error fetching data:', error);
    // Return an empty array in case of an error
    return [];
  }
};

// Main Chart component
const Chart = ({ period, startDate, endDate }) => {
  const { t, i18n } = useTranslation(); // Use the translation hook
  const [data, setData] = useState([]);
  const [chartType, setChartType] = useState('line'); // State to toggle chart type
  const { user } = useAuth(); // Get user from AuthContext

  useEffect(() => {
    if (!user) return; // Ensure user is available

    const loadData = async () => {
      console.log('Fetching data with:', { period, startDate, endDate });

      let periodData = [];
      const parsedStartDate = new Date(startDate);
      const parsedEndDate = new Date(endDate);

      if (period === 'Week') {
        periodData = generateWeekDays(parsedStartDate);


      } else if (period === 'Month') {
        periodData = generateMonthDays(parsedStartDate);
      } else if (period === 'Today') {
        periodData = generateDayHours();
      } else if (period === 'Custom') {
        const dayDiff = Math.ceil((parsedEndDate - parsedStartDate) / (1000 * 60 * 60 * 24));
        periodData = Array.from({ length: dayDiff + 1 }, (_, i) => {
          const currentDate = new Date(parsedStartDate);
          currentDate.setDate(currentDate.getDate() + i);
          return {
            day: currentDate.toISOString().split('T')[0],
            date: currentDate.toISOString().split('T')[0],
            appointments_count: 0
          };
        });
      }

      const serverData = await fetchData(period, user.id, startDate, endDate); // Pass user.id


      // Ensure serverData is an array before proceeding
if (!Array.isArray(serverData)) {
  console.error('Expected serverData to be an array, but got:', serverData);
  return;
}

      const appointmentsMap = new Map();
      serverData.forEach(item => {
        const itemDate = new Date(item.date);
        itemDate.setDate(itemDate.getDate() + 1);
        
        const formattedDate = itemDate.toISOString().split('T')[0];
        let key;

        if (period === 'Today') {
          key = item.time.split(':')[0] + ':00';
        } else if (period === 'Month') {
          key = new Date(itemDate).getDate();
        } else if (period === 'Week') {
          key = new Date(itemDate).toLocaleDateString('en-US', { weekday: 'short' });
        } else if (period === 'Custom') {
          key = formattedDate;
        }

        if (key) {
          appointmentsMap.set(key, (appointmentsMap.get(key) || 0) + item.appointments_count);
        }
      });

      const chartData = periodData.map(dayData => {
        let dayLabel = dayData.day || dayData.hour || dayData.date;
        
        if (period === 'Week' && dayData.originalDay) {
          // Translate only if period is 'Week' and originalDay is defined
          dayLabel = t(`days.${dayData.originalDay.toLowerCase()}`) || dayData.originalDay;
        }

        const key = period === 'Today' ? dayData.hour :
        period === 'Month' ? dayData.day :
        period === 'Week' ? dayData.originalDay :
        period === 'Custom' ? dayData.date : '';
        
        return {
          ...dayData,
          appointments_count: appointmentsMap.get(key) || 0,
          day: dayLabel
        };
      });

      console.log('Transformed chart data:', chartData); // Debugging

      setData(chartData);
    };

    loadData();

    const handleLanguageChange = () => {
      loadData(); // Re-fetch data on language change
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange); // Cleanup listener
    };
  }, [period, startDate, endDate, user, i18n.language]);

  let dataKey = '';
  switch (period) {
    case 'Today':
      dataKey = 'hour';
      break;
    case 'Week':
      dataKey = 'day';
      break;
    case 'Month':
      dataKey = 'day';
      break;
    case 'Custom':
      dataKey = 'date';
      break;
    default:
      dataKey = '';
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', marginTop: '-40px' }}>
        <label className="switch">
          <input type="checkbox" checked={chartType === 'bar'} onChange={() => setChartType(chartType === 'line' ? 'bar' : 'line')} />
          <span className="slider round"></span>
        </label>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        {chartType === 'line' ? (
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#2c67f2" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#62cff4" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid 
              strokeDasharray="3 3"
              vertical={false}
              stroke="#ccc"
              strokeWidth={1}
            />
            <XAxis dataKey={dataKey} />
            <YAxis />
            <Tooltip labelFormatter={(value) => ` ${value}`} formatter={(value) => [`${t('appointments')}: ${value}`]} />
            <Legend formatter={() => t('appointments')} />
            <Area 
              type="monotone" 
              dataKey="appointments_count"
              stroke="#3a78d6" 
              strokeWidth={3}
              fillOpacity={1} 
              fill="url(#colorUv)" 
            />
          </AreaChart>
        ) : (
          <BarChart data={data}>
            <CartesianGrid 
              strokeDasharray="3 3"
              vertical={false}
              stroke="#ccc"
              strokeWidth={1}
            />
            <XAxis dataKey={dataKey} />
            <YAxis />
            <Tooltip labelFormatter={(value) => ` ${value}`} formatter={(value) => [`${t('appointments')}: ${value}`]} />
            <Legend formatter={() => t('appointments')} />
            <Bar 
              dataKey="appointments_count" 
              fill="#3a78d6"
            />
          </BarChart>
        )}
      </ResponsiveContainer>
      <style>
        {`
        .switch {
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: .4s;
          border-radius: 34px;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          border-radius: 50%;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: .4s;
        }

        input:checked + .slider {
          background-color: #3a78d6;
        }

        input:checked + .slider:before {
          transform: translateX(26px);
        }

        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
        `}
      </style>
    </div>
  );
};

export default Chart;
