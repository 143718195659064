import React, { useRef, useState, useEffect } from 'react';
import { CardMedia, Button, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PhotoSection = ({ photos, editing, onViewPhotos, onUploadPhotos, onReorderPhotos }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [localPhotos, setLocalPhotos] = useState(photos);

  useEffect(() => {
    setLocalPhotos(photos);
  }, [photos]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleReorderPhotos = (newPhotoOrder) => {
    setLocalPhotos(newPhotoOrder);
  };

  const handleSaveReorder = async () => {
    if (onReorderPhotos) {
      try {
        await onReorderPhotos(localPhotos);
        setModalOpen(false); // Close the modal after saving
      } catch (error) {
        console.error('Error saving reorder:', error);
      }
    } else {
      console.error('onReorderPhotos is not defined');
    }
  };

  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      try {
        await onUploadPhotos(event.target.files);
      } catch (error) {
        setSnackbarMessage(`Error uploading photos: ${error.message}`);
        setSnackbarOpen(true);
      }
      event.target.value = '';
    }
  };

  const firstPhoto = localPhotos.length > 0 ? localPhotos[0] : '';
  
  const photoThumbnails = localPhotos.length > 0 ? (
    <CardMedia
      component="img"
      sx={{ height: 300, width: '100%', objectFit: 'cover', borderRadius: 10 }}
      image={firstPhoto}
      alt="First photo"
    />
  ) : (
    <CardMedia
      component="img"
      sx={{ height: 300, width: '100%', objectFit: 'cover', borderRadius: 10 }}
      image='https://placehold.co/400x300'
      alt="No photo available"
    />
  );

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div style={{ position: 'relative' }}>
        {photoThumbnails}
        {editing && (
          <>
            <Button
              variant="contained"
              onClick={onViewPhotos}
              disableRipple
              sx={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                color: 'white',
                boxShadow: 'none',
                zIndex: 1,
                backgroundColor: '#2c3e50',
                '&:hover': {
                  backgroundColor: '#2c3e50',
                  opacity: 1,
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {t('viewallphotos')}
            </Button>
            <Button
              variant="contained"
              onClick={handleUploadClick}
              disableRipple
              sx={{
                position: 'absolute',
                bottom: 50,
                right: 10,
                color: 'white',
                boxShadow: 'none',
                zIndex: 1,
                backgroundColor: '#2c3e50',
                '&:hover': {
                  backgroundColor: '#2c3e50',
                  opacity: 1,
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {t('uploadphotos')}
            </Button>
            <input
              type="file"
              multiple
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PhotoSection;
