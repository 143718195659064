// services/employeeService.js
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const fetchEmployees = async (companyId) => {
  
  const response = await fetch(`${API_BASE_URL}/api/employees/${companyId}`, {
    method: 'GET',
    credentials: 'include', // Include cookies for session
  });

  if (!response.ok) {
    throw new Error('Error fetching employees');
  }

  return response.json();
};

export const addEmployee = async (companyId, employee) => {
  const response = await fetch(`${API_BASE_URL}/api/employees`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ company_id: companyId, ...employee }),
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Error adding employee');
  }

  return response.json();
};

export const updateEmployee = async (employeeId, updates) => {
  const response = await fetch(`${API_BASE_URL}/employees/${employeeId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Error updating employee');
  }

  return response.json();
};

export const deleteEmployee = async (employeeId) => {
  const response = await fetch(`${API_BASE_URL}/employees/${employeeId}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Error deleting employee');
  }
};
