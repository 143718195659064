import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Button,
  Avatar,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next'; // Ensure correct import


const EmployeeList = ({
  employees,
  editing,
  onAddEmployee,
  onUpdateEmployee,
  onDeleteEmployee,
  onNameChange,
  onSaveEmployee,
  onAvatarChange
  
}) => {
  const { t } = useTranslation(); // Get the t function

  return (

  <div>
    
    <Typography variant="h6" gutterBottom>
    {t('employees')}
    </Typography>
    <List>
      {employees.map((employee, index) => (
        <ListItem key={index}>
          {editing ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label={t('name')}
                value={employee.name || ''}
                onChange={onNameChange(index)}
                size="small"
                style={{ marginRight: 16 }}
              />
              
              <Button
                variant="contained"
                onClick={() => onSaveEmployee(index)}
                disableRipple
                sx={{ marginLeft: 2 }}
              >
                {t('save')}
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={employee.avatar} alt={`Avatar of ${employee.name}`} />
              <ListItemText primary={employee.name} style={{ marginLeft: 16 }} />
            </div>
          )}
          {editing && (
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => onDeleteEmployee(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
    {editing && (
      <Button
        variant="contained"
        onClick={onAddEmployee}
        startIcon={<AddIcon />}
        sx={{
          bgcolor: '#2c3e50',
          height: '30px',
          minWidth: '100px',
          '&:focus': { bgcolor: '#2c3e50' }
        }}
      >
        {t('addemployee')}
      </Button>
    )}
  </div>
  );
};

export default EmployeeList;
