import axios from 'axios';

// Create an Axios instance with default configurations
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL for your API
  withCredentials: true, // Include cookies with requests
});

// Fetch company info for the authenticated user
const fetchCompanyInfo = async (userId) => {
  try {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; 
    console.log('Sending request to fetch company info for user ID:', userId);
    const response = await api.get('/api/profile', {
      params: { userId } // Send user ID as a query parameter if needed
    });
    console.log('Fetched company info:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching company info:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Create a new company profile (POST)
const createCompanyProfile = async (companyData) => {
  try {
    console.log('Sending request to create new company profile with data:', companyData);
    const response = await api.post('/api/profile', companyData);
    console.log('Create response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating company profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const updateCompanyInfo = async (companyData) => {  // No need for companyId here
  try {
    console.log(`Sending request to update company profile`);
    
    // Remove companyId from the URL, as it's not expected by the backend
    const response = await api.put('/api/profile', companyData); 
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating company profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Determine whether to create or update a company profile
const createOrUpdateCompanyProfile = async (companyData) => {
  try {
    if (companyData.id) {
      // If ID exists, update the company profile (PUT)
      return await updateCompanyInfo(companyData.id, companyData);
    } else {
      // If no ID, create a new company profile (POST)
      return await createCompanyProfile(companyData);
    }
  } catch (error) {
    console.error('Error saving company profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export { fetchCompanyInfo, createCompanyProfile, updateCompanyInfo, createOrUpdateCompanyProfile };
