import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../business/components/Sidebar';
import Dashboard from '../business/pages/Dashboard';
import BusinessProfile from '../business/pages/Profile';
import Appointments from '../business/pages/Appointments';
import Calendar from '../business/pages/Calendar';
import Services from '../business/pages/Services';
import AddApnt from '../business/pages/AddApnt';
import Billing from '../business/pages/Billing';
import styles from './BusinessLayout.module.css'; // Import CSS module
import { useMediaQuery, useTheme } from '@mui/material';
import StripeProvider from '../business/StripeProvider/StripeProvider'; 
import NotFound from '../business/pages/NotFound';
import useHubSpotChat from '../business/components/useHubSpotChat';

const BusinessLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useHubSpotChat();
  
  const handleDrawerToggle = (open) => {
    setDrawerOpen(open);
  };


  return (
    <>
      <Sidebar onDrawerToggle={handleDrawerToggle} open={drawerOpen} />
      <div className={styles.container}>
        <main
          style={{
            transition: 'margin-left 0.3s ease',
            marginLeft: isMobile ? (drawerOpen ? 0 : 0) : 240, // Adjust for mobile overlay
            marginRight: isMobile ? 0 : 0,
            padding: '20px',
          }}
        >
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<BusinessProfile />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="services" element={<Services />} />
            <Route path="AddApnt" element={<AddApnt />} />
            <Route path="Billing" element={<StripeProvider><Billing /></StripeProvider>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </div>
    </>
  );
};

export default BusinessLayout;
